import './styles.scss';
import Logo from '../../assets/signtouch-logo.svg';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import gitInfo from '../../generatedGitInfo.json';

const SideBar = ({ children, isAlways }) => {
  const { menuOpen } = useSelector((state) => state.ui);
  const { developmentId } = useParams();

  return (
    <motion.div
      className={
        isAlways ? 'side-bar always' : !menuOpen ? 'side-bar' : 'side-bar close'
      }
    >
      <Link
        to={developmentId ? `/developments/${developmentId}` : '/developments'}
      >
        <img src={Logo} alt='signtouch logo' />
      </Link>

      {children}

      <div style={{ marginTop: 'auto', textAlign: 'center', padding: '8px' }}>
        {developmentId && (
          <div className='back-to-developments-container'>
            <Link to='/developments'>
              <div className='back-to-developments'>
                <p>Back to Developments</p>
              </div>
            </Link>
          </div>
        )}
        <span>Version: {gitInfo.gitCommitHash}</span>
      </div>
    </motion.div>
  );
};

export default SideBar;
