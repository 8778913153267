const findDevelopmentId = (currentDevelopment, developments) => {
  if (currentDevelopment.type === 'signtouch-site') {
    const region = developments.find(
      (development) => development.id == currentDevelopment.parentId,
    );
    return region.parentId;
  } else if (currentDevelopment.type === 'signtouch-region') {
    return currentDevelopment.parentId;
  } else if (currentDevelopment.type === 'signtouch-parent') {
    return currentDevelopment.id;
  }
};

export default findDevelopmentId;
