import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SortableFloor } from './Floor';
import { Box } from '@mui/material';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);  
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((item, index) => ({ ...item, order: index + 1 }));
};

export const SortFloors = ({ floors, handleFloorsChange, onDeleteFloor }) => {
  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    
    const newItems = reorder(
      floors,
      result.source.index,
      result.destination.index
    );
      
    handleFloorsChange(newItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ maxWidth: 500 }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              borderRadius: 2
            }}>
              {floors?.map((floor, index) => (
                <Draggable key={floor.id} draggableId={floor.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <SortableFloor key={floor.id} id={floor.id} label={floor.name} onDelete={onDeleteFloor} />
                    </div>
                  )}
                </Draggable>
              ))}
            </Box>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};