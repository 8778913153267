import './ContentBlock.styles.scss';

export const ContentBlock = ({ html }) => {
  return (
    <div className='preview-content-block'>
      <div
        className='preview-content-block-inner'
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  );
};
