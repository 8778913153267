import { Box, Typography } from '@mui/material';

export const Result = ({ result, onClick }) => {
  const characterCount = 30;

  return (
    <Box 
      sx={{
        p: 1,
        cursor: 'pointer',
        '&:hover': {
          bgcolor: 'grey.200',
        },
        bgcolor: 'background.paper',
        width: 200,
      }}
      onClick={onClick}
    >
      <Typography variant='caption'>
        {result.place_name.length > characterCount
          ? `${result.place_name.slice(0, characterCount)}...` 
          : result.place_name
        }
      </Typography>
    </Box>
  );
};
