const makeCssColor = (colourObject) => {
  // It's not an object so assuming its a string so just passing it back
  if (typeof colourObject !== 'object') return colourObject;

  // Not type has been set so assuming its a colour
  if (!colourObject.hasOwnProperty('type')) colourObject.type = 'colour';

  switch (colourObject.type) {
    // Just return the colour code
    case 'colour':
      if (colourObject.value.hasOwnProperty('hex'))
        return colourObject.value.hex;
      return colourObject.value;

    case 'color':
      if (colourObject.value.hasOwnProperty('hex'))
        return colourObject.value.hex;
      return colourObject.value;

    case 'gradient':
      // This means in the case a colour object is passed in an not a hex code, it recovers without issue.
      let colour1 = colourObject.value.colors[0].hasOwnProperty('hex')
        ? colourObject.value.colors[0].hex
        : colourObject.value.colors[0];
      let colour2 = colourObject.value.colors[1].hasOwnProperty('hex')
        ? colourObject.value.colors[1].hex
        : colourObject.value.colors[1];
      // Check if an angle is found otherwise just use 90
      return colourObject.value.angle
        ? `linear-gradient(${colourObject.value.angle}deg, ${colour1}, ${colour2})`
        : `linear-gradient(90deg, ${colour1}, ${colour2})`;

    default:
      // Return grey in the case that the type isn't found
      return '#333';
  }
};

export default makeCssColor;
