import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ContentState from 'draft-js/lib/ContentState';
import { useSelector } from 'react-redux';
import { Toggle } from '../../../index';

const TextArea = (props) => {
  const { branding } = useSelector((state) => state.properties);
  const [background, setBackground] = useState(false);
  const [focus, setFocus] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (props.initialValue) {
      const content = htmlToDraft(props.initialValue);

      if (content) {
        const contentState = ContentState.createFromBlockArray(
          content.contentBlocks,
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
  }, []);

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);

    if (props.onChange) {
      props.onChange({
        name: props.name,
        value: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      });
    }
  };

  useEffect(() => {
    if (
      draftToHtml(convertToRaw(editorState.getCurrentContent())) === '<p></p>\n'
    )
      return;
  }, [editorState]);

  const makeCssProps = (error, background) => {
    let string = 'form-textarea';

    if (error) {
      string = string + ' error';
    }

    if (background) {
      string =
        string +
        (props.hasOwnProperty('bgType') && props.bgType === 'section'
          ? ' form-textarea-section-bg'
          : ' form-textarea-card-bg');
    }

    return string;
  };

  return (
    <div className={makeCssProps(props.error, background)}>
      <label>{props.label}</label>
      {props.bgType && (
        <div className={'changeBg'}>
          <small>Background</small>
          <Toggle onChange={setBackground} />
        </div>
      )}
      <Editor
        editorState={editorState}
        wrapperClassName={focus ? 'textarea-focus' : 'textarea'}
        editorClassName='textarea-canvas'
        onEditorStateChange={handleEditorStateChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        toolbar={{
          options: ['inline', 'colorPicker', 'remove', 'history', 'list', 'blockType'],
          colorPicker: {
            colors: [
              `${branding.primary}`,
              `${branding.secondary}`,
              `${branding.tertiary}`,
              `${branding.line}`,
              'rgb(97,189,109)',
              'rgb(26,188,156)',
              'rgb(84,172,210)',
              'rgb(44,130,201)',
              'rgb(147,101,184)',
              'rgb(71,85,119)',
              'rgb(204,204,204)',
              'rgb(65,168,95)',
              'rgb(0,168,133)',
              'rgb(61,142,185)',
              'rgb(41,105,176)',
              'rgb(85,57,130)',
              'rgb(40,50,78)',
              'rgb(0,0,0)',
              'rgb(247,218,100)',
              'rgb(251,160,38)',
              'rgb(235,107,86)',
              'rgb(226,80,65)',
              'rgb(163,143,132)',
              'rgb(239,239,239)',
              'rgb(255,255,255)',
              'rgb(250,197,28)',
              'rgb(243,121,52)',
              'rgb(209,72,65)',
              'rgb(184,49,47)',
              'rgb(124,112,107)',
              'rgb(209,213,216)',
            ],
          },
        }}
      />
    </div>
  );
};

export default TextArea;
