import { ApiError, beablooApi } from '../../utils';

const setPlots = () => {
  return async (dispatch, getState) => {
    const { general } = getState();

    const plots = await beablooApi({
      method: 'GET',
      route: `/developments/${general.currentDevelopment.id}/plots`,
    })
      .then((r) => r.data)
      .catch((e) => {
        throw new ApiError('failed to get plots', []);
      });

    console.log(plots);

    dispatch({
      type: 'SET_PLOTS',
      data: plots,
    });
  };
};

const addPlot = (data) => {
  return async (dispatch, getState) => {
    const { plots } = getState();
    const repeated = plots.find((plot) => plot.id === data.id);
    if (repeated) return;

    dispatch({
      type: 'ADD_PLOT',
      data: data,
    });
  };
};

const clearPlots = () => {
  return {
    type: 'CLEAR_PLOTS',
  };
};

const deletePlot = (data) => {
  return async (dispatch, getState) => {
    let { plots } = getState();

    let plot = plots.find((plot) => plot.id === data.id);
    const index = plots.indexOf(plot);
    plots.splice(index, 1);

    dispatch({
      type: 'SET_PLOTS',
      data: plots,
    });
  };
};

const updatePlot = (data) => {
  return async (dispatch, getState) => {
    let { plots } = getState();

    let plot = plots.find((plot) => `${plot.id}` === `${data.id}`);
    const index = plots.indexOf(plot);
    plots[index] = data;

    dispatch({
      type: 'SET_PLOTS',
      data: plots,
    });
  };
};

export { setPlots, addPlot, clearPlots, updatePlot, deletePlot };
