import { useState, useEffect } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  TextInput,
  TextArea,
  MoneyInput,
  SingleImage,
  DropDown,
  NumberInput,
  MultiImage,
  ColorPicker,
  ColorAndGradient,
  LocationSelector,
} from './components';
import { useFormColor, useUpdateFormColor } from '../../contexts/FormProvider';

import '../../styles/_forms.scss';
import { Prompt } from '../Prompt';

const Form = ({
  inputs,
  onChange,
  onSubmit,
  errors = [],
  customSubmit,
  canSubmit,
  saved,
}) => {
  const groupByKey = (list, key) =>
    list.reduce(
      (hash, obj) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {},
    );

  const [state, setState] = useState({});

  // This needs to be the active colorOId
  const [color, setColor] = useState('');

  const [images, setImages] = useState([]);

  const [wasModified, setWasModified] = useState(false);

  // active form color id
  const formColorId = useFormColor();

  // update the active form color id
  const updateFormColorId = useUpdateFormColor();

  useEffect(() => {
    setColor(formColorId);
  }, [formColorId]);

  useEffect(() => {
    if (updateFormColorId) {
      updateFormColorId(color);
    }
  }, [color]);

  useEffect(() => {
    if (onChange) onChange(state);
  }, [state]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setWasModified(false);

    let keys = groupByKey(images, 'input');
    if (onSubmit) {
      onSubmit({ ...state, ...keys });
    }
  };

  const handleInputChange = (data) => {
    setWasModified(true);

    const newState = { ...state };
    newState[data.name] = data.value;
    setState({ ...newState });
  };

  useEffect(() => {
    if (saved) {
      setWasModified(false);
    }
  }, [saved]);

  return (
    <>
      <Prompt
        when={wasModified}
        message='You have unsaved changes. Are you sure you want to leave?'
      />
      <form onSubmit={handleSubmit}>
        <div className='signtouch-form'>
          {inputs &&
            inputs.map((input, index) => {
              switch (input.type) {
                case 'text':
                  return (
                    <TextInput
                      {...input}
                      error={
                        errors.filter((error) => error.param === input.name)
                          .length > 0
                      }
                      onChange={handleInputChange}
                      key={input.name}
                    />
                  );

                case 'number':
                  return (
                    <NumberInput
                      {...input}
                      error={
                        errors.filter((error) => error.param === input.name)
                          .length > 0
                      }
                      onChange={handleInputChange}
                      key={input.name}
                    />
                  );

                case 'textarea':
                  return (
                    <TextArea
                      {...input}
                      error={
                        errors.filter((error) => error.param === input.name)
                          .length > 0
                      }
                      onChange={handleInputChange}
                      key={input.name}
                    />
                  );

                case 'money':
                  return (
                    <MoneyInput
                      {...input}
                      error={
                        errors.filter((error) => error.param === input.name)
                          .length > 0
                      }
                      onChange={handleInputChange}
                      key={input.name}
                    />
                  );

                case 'single-image':
                  return (
                    <SingleImage
                      {...input}
                      error={
                        errors.filter((error) => error.param === input.name)
                          .length > 0
                      }
                      onChange={handleInputChange}
                      key={input.name}
                    />
                  );

                case 'multi-image':
                  return (
                    <div key={input.name}>
                      <MultiImage
                        {...input}
                        error={
                          errors
                            .filter((error) => error.param === input.name)
                            .length > 0
                        }
                        onChange={handleInputChange}
                      />
                      <div className='images ' />
                    </div>
                  );

                case 'select':
                  return (
                    <DropDown
                      {...input}
                      error={
                        errors.filter((error) => error.param === input.name)
                          .length > 0
                      }
                      onChange={handleInputChange}
                      key={input.name}
                    />
                  );

                case 'colour':
                  return (
                    <ColorPicker
                      {...input}
                      error={
                        errors.filter((error) => error.param === input.name)
                          .length > 0
                      }
                      onChange={handleInputChange}
                      notifyOpen={(e) => setColor(e)}
                      openedModal={color}
                      key={input.name}
                    />
                  );

                case 'colourandgradient':
                  return (
                    <ColorAndGradient
                      {...input}
                      error={
                        typeof errors === 'object' &&
                        errors.filter((error) => error.param === input.name)
                          .length > 0
                      }
                      onChange={handleInputChange}
                      notifyOpen={(e) => setColor(e)}
                      openedModal={color}
                      key={input.name}
                    />
                  );

                case 'location':
                  return (
                    <LocationSelector onChange={handleInputChange} {...input} />
                  );

                case 'label':
                  return (
                    <div className='form-input-select' key={input.name}>
                      <label>{input.label}</label>
                    </div>
                  );

                case 'split':
                  return (
                    <h2 className='form-split' key={input.title}>
                      {input.title}
                    </h2>
                  );

                default:
                  return <></>;
              }
            })}

          {onSubmit && (
            <>
              {canSubmit ? (
                <div className='form-submit'>
                  <input
                    type='submit'
                    value={
                      customSubmit && customSubmit.length > 0
                        ? customSubmit
                        : 'submit'
                    }
                  />
                </div>
              ) : (
                <div className='form-submit disabled'>
                  <input
                    type='submit'
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    value={
                      customSubmit && customSubmit.length > 0
                        ? customSubmit
                        : 'submit'
                    }
                  />
                </div>
              )}
            </>
          )}
        </div>
      </form>
    </>
  );
};

export default Form;
