import './styles.scss';
import { PreviewMenuItem } from '..';
import { makeCssColor } from '../../../../utils';
import { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import {
  usePreviewMenu,
  useTogglePreviewMenu,
} from '../../contexts/previewMenu';
import { handleMenuItems, useDimensions } from '../../utils';

const Path = (props) => (
  <motion.path
    fill='transparent'
    strokeWidth='3'
    stroke='hsl(0, 0%, 18%)'
    strokeLinecap='round'
    {...props}
  />
);

const PreviewMenu = ({ modules, branding, name }) => {
  const menuOpen = usePreviewMenu();
  const toggleMenu = useTogglePreviewMenu();

  const [menuItems, setMenuItems] = useState([]);

  const crossRef = useRef(null);
  const { height } = useDimensions(crossRef);

  useEffect(() => {
    const modulesToFilter = handleMenuItems(modules);

    // sort the modules array by weight and
    // remove the items that aren't enabled
    const menuItemsFilters = modulesToFilter
      .filter((module) => module.enabled)
      .sort((a, b) => a.weight - b.weight);

    // set the items to state
    setMenuItems(menuItemsFilters);
  }, [modules]);

  // create aniamtions for menu
  const menuVariants = {
    initial: {
      translateY: '-200%',
    },
    animate: {
      translateY: menuOpen ? '0%' : '-200%',
    },
  };

  return (
    <>
      <div
        className='preview-menu-button'
        onClick={toggleMenu}
        style={{ background: branding.primary }}
      >
        <motion.div
          className='cross'
          animate={menuOpen ? 'open' : 'closed'}
          custom={height}
          ref={crossRef}
        >
          <svg viewBox='-5 -5 30 30'>
            <Path
              variants={{
                closed: { d: 'M 2 5.423 L 20 5.423' },
                open: { d: 'M 3 16.5 L 17 2.5' },
              }}
              stroke={'#fff'}
            />
            <Path
              variants={{
                closed: { d: 'M 2 15.346 L 20 15.346' },
                open: { d: 'M 3 2.5 L 17 16.346' },
              }}
              stroke={'#fff'}
            />
          </svg>
        </motion.div>
        <p className='preview-menu-text'>
          {menuOpen ? 'CLOSE' : 'MENU'}
        </p>
      </div>
      <motion.div
        className='preview-menu'
        variants={menuVariants}
        initial='initial'
        animate='animate'
        transition={{ duration: 0.5, type: 'stiff' }}
      >
        <div
          className='preview-menu-heading'
          style={{
            background: makeCssColor(branding.section),
          }}
        >
          <p>
            Navigate <br />
            {name}
          </p>
        </div>

        {menuItems.map((item) => {
          return <PreviewMenuItem {...item} key={`pm-${item.title}`} />;
        })}
      </motion.div>
    </>
  );
};

export default PreviewMenu;
