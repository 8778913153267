import { Notification, Form, Heading } from '../../components';
import { useParams, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { beablooApi } from '../../utils';
import { useEffect, useState } from 'react';
import { updateOption } from '../../store/actions/options';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { addError } from '../../utils';

const CreateOption = () => {
  const { developmentId, optionId } = useParams();
  const options = useSelector((state) => state.options);
  const [option, setOption] = useState({});
  const [error, setError] = useState({ error: false, message: '' });
  const [saved, setSaved] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [saving, setSaving] = useState(false);

  // const socket = useSocket();
  const dispatch = useDispatch();

  useEffect(() => {
    setOption(options.find((option) => String(option.id) === String(optionId)));

    // eslint-disable-next-line
  }, [option]);

  const handleSubmit = async (data) => {
    const pendingId = addPending('Saving...');

    setSaving(true);

    const payload = {};

    if (data.name) payload.name = data.name;
    if (data.description) payload.description = data.description;
    if (data.thumbnail) payload.thumbnail = data.thumbnail;
    if (data.images) payload.images = data.images;

    await beablooApi({
      method: 'PUT',
      route: `/developments/${developmentId}/options/${optionId}/update`,
      payload: payload,
    }).then((r) => {
      removePending(pendingId);
      if (r.message !== 'success') {
        setSaving(false);
        setError({ error: true, message: r.message });
        addError(r.message);
      } else {
        // plot saved
        setSaving(false); // stop loading animation
        dispatch(updateOption(r.data));
        // dispatch(addOption(r.data))// add the plot to state
        // socket.emit('option:create', { room: developmentId, payload: r.data });  // emit the the new plot to anyone looking at currentDevelopment.id
        setSaved(true); // tell state to redirect
      }
    });
  };

  if (redirect)
    return <Navigate to={`/developments/${developmentId}/optionsOverview`} />;

  return (
    <div className='create-option page-padding'>
      {error.error ? (
        <Notification
          message={error.message}
          removeElement={(e) => setError({ error: false, message: '' })}
        />
      ) : (
        <></>
      )}
      {saved ? (
        <Notification
          success
          message={'Option Saved!'}
          removeElement={() => setRedirect(true)}
        />
      ) : (
        <></>
      )}

      <Heading label={option ? `Edit Option: ${option.name}` : 'Edit Option'} />

      {option.hasOwnProperty('name') ? (
        <Form
          inputs={[
            {
              name: 'name',
              type: 'text',
              label: 'name',
              initialValue: option.name,
            },
            {
              name: 'description',
              type: 'textarea',
              label: 'description',
              initialValue: option.description,
            },
            {
              name: 'thumbnail',
              type: 'single-image',
              label: 'thumbnail',
              initialValue: option.thumbnail,
            },
            {
              name: 'images',
              type: 'single-image',
              label: 'images',
              initialValue: option.images,
            },
          ]}
          canSubmit={!saving}
          onSubmit={handleSubmit}
        />
      ) : null}
    </div>
  );
};

export default CreateOption;
