import { Heading } from '../../components';
import { Form } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError, beablooApi } from '../../utils';
import { addError } from '../../utils';
import { addNotification } from '../../utils';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { useEffect } from 'react';
import { SignTouchPreview } from '../../components';
import { PreviewDesignAdvice } from '../../components/SignTouchPreview/pages';
import './style.scss';
import {
  addDesignAdviceArticle,
  updateDesignAdvice,
} from '../../store/actions/designAdvice';

const CreateDesignAdvice = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { typeId, adviceId, developmentId } = useParams();

  const { articles } = useSelector((state) => state.designAdvice);

  let article = adviceId === 'create' 
    ? {} 
    : articles.find((a) => a.id == adviceId);

  const [isSaving, setSaving] = useState(false);

  const [name, setName] = useState('');
  const [content, setContent] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [images, setImages] = useState('');

  const handleChange = (data) => {
    if (data.hasOwnProperty('name')) setName(data.name);
    if (data.hasOwnProperty('content')) setContent(data.content);
    if (data.hasOwnProperty('thumbnail')) setThumbnail(data.thumbnail);
    if (data.hasOwnProperty('images')) setImages(data.images);
  };

  useEffect(() => {
    // If the adviceId is create then we don't want to find any advice.
    if (adviceId === 'create') return;
    // Set the state to exisitng content
    setName(article.name);
    setContent(article.description);
    setThumbnail(article.thumbnail);
    setImages(article.images);
  }, [adviceId]);

  // We need to handle to submiting
  const handleSubmit = async () => {
    // Stop any more requests being made
    setSaving(true);
    // Add the pending notification and keep its id.
    let pendingId = addPending('Saving...');
    // Check if we're creating or editing
    if (adviceId !== 'create') {
      await updateExisting();
    } else {
      // Run the handle create function
      await handleCreate();
    }

    // Remove the pending as somethings over.
    removePending(pendingId);
    // Allow for more requests to be sent
    setSaving(false);
  };

  return (
    <div className={'page-padding'}>
      <div className={'create-design-advice'}>
        <Heading
          label={
            adviceId === 'create'
              ? 'Create Design Advice'
              : `Edit ${article.name}`
          }
        />
        {adviceId === 'create' || name.length > 0 ? (
          <Form
            inputs={[
              {
                name: 'name',
                type: 'text',
                label: 'Name',
                initialValue: article.name,
              },
              {
                name: 'content',
                type: 'textarea',
                label: 'Content',
                initialValue: article.description,
              },
              {
                name: 'thumbnail',
                type: 'single-image',
                label: 'Thumbnail',
                initialValue: article.thumbnail,
              },
              {
                name: 'images',
                type: 'multi-image',
                label: 'images',
                initialValue: article.images,
              },
            ]}
            onChange={handleChange}
            onSubmit={handleSubmit}
            canSubmit={!isSaving}
          />
        ) : null}
      </div>
      <div className={'floating-right'}>
        <SignTouchPreview>
          <PreviewDesignAdvice
            name={name}
            description={content}
            images={images}
          />
        </SignTouchPreview>
      </div>
    </div>
  );

  // THis function handles creating design advice articles
  // It's a function because otherwise it needs to be higher in the file.
  function handleCreate() {
    return beablooApi({
      method: 'POST',
      route: `/developments/${developmentId}/designAdviceArticles/create`,
      payload: {
        name: name,
        description: content,
        images: images,
        thumbnail: thumbnail,
        type: typeId,
      },
    })
      .then((r) => {
        if (!r.success) {
          throw new ApiError(r.message, r.errors);
        }

        addNotification('Successfully created category');

        return r.data;
      })
      .then((r) => {
        dispatch(addDesignAdviceArticle(r));
        navigate(`/developments/${developmentId}/design-advice`);
      })
      .catch((e) => {
        addError(e.message);
      });
  }

  function updateExisting() {
    let payload = {};

    if (name.length > 0 && articles.name !== name) payload.name = name;
    if (content.length > 0 && articles.description !== content)
      payload.description = content;
    if (images.length > 0 && articles.images !== images)
      payload.images = images;
    if (thumbnail.length > 0 && articles.thumbnail !== thumbnail)
      payload.thumbnail = thumbnail;

    return beablooApi({
      method: 'PUT',
      route: `/developments/${developmentId}/designAdviceArticles/${adviceId}/update`,
      payload: payload,
    })
      .then((r) => {
        if (!r.success) {
          throw new ApiError(r.message, r.errors);
        }

        addNotification('Successfully updated category');
        return r.data;
      })
      .then((r) => {
        navigate(`/developments/${developmentId}/design-advice`);
        dispatch(updateDesignAdvice(r));
      })
      .catch((e) => {
        addError(e.message);
      });
  }
};

export default CreateDesignAdvice;
