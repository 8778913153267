import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import DevelopmentIcon from '../../../../assets/icon-development.svg';
import { beablooApi } from '../../../../utils';
import './styles.scss';

const DevelopmentChildCard = ({ id, name, city, region }) => {
  // const [plots, setPlots] = useState(0);

  useEffect(() => {
    // on mount check how many plots a development has
    // by asking for the plots from the API
    // beablooApi({ method: 'GET', route: `/developments/${ id }/plots` })
    //   .then(r => r.data)
    //   .then(r => setPlots(r.length))
    //   .catch(e => setPlots(0))
  }, []);

  return (
    <div className='child-container'>
      <Link to={`/developments/${id}`}>
        <div className='child'>
          <div
            className='thumbnail'
            style={{ backgroundImage: `url(${DevelopmentIcon})` }}
          />
          <div className='child-heading'>
            <div className='title'>
              <h3>{name}</h3>
              <p className='city'>
                {city}, {region}
              </p>
              {/* <p>{ plots } Plots</p> */}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default DevelopmentChildCard;
