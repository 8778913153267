import React, { useContext, useEffect, useState } from 'react';

const BrandingContext = React.createContext();

// export custom hook to previews properties state
export const usePreviewProperties = () => {
  return useContext(BrandingContext);
};

// export a provider to allow access the the state
// within the children of the Provider
export const PreviewPropertiesProvider = ({ children, properties }) => {
  const [state, setState] = useState(properties);

  useEffect(() => {
    setState(properties);
  }, [properties]);

  return (
    <BrandingContext.Provider value={state}>
      {children}
    </BrandingContext.Provider>
  );
};
