import schema from '../schemas/_registerInterest';

const registerInterestReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_INTERESTED':
      return {
        ...action.data,
      };

    default:
      return {
        ...state,
      };
  }
};

export { registerInterestReducer };
