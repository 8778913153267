import store from '../store';
import { addToast } from '../store/actions/toasts';

const addError = (message) => {
  store.dispatch(
    addToast({
      id: Math.random().toString(16),
      type: 'error',
      message: message
        ? message
        : process.env.NODE_ENV !== 'production'
        ? 'An unhandled error occurred. Please report this bug.'
        : 'An unknown error occurred.',
    }),
  );
};

export default addError;
