import { useEffect } from 'react';
import { PreviewHeader, PreviewFooter, PreviewMenu } from '..';
import './styles.scss';

const PreviewLayout = ({ children, branding, modules, development, name }) => {
  return (
    <div className='preview-layout-container'>
      <div className='preview-layout'>
        <PreviewHeader development={development} branding={branding} />
        <PreviewMenu modules={modules} branding={branding} name={name} />

        <div className='preview-content'>{children}</div>

        <PreviewFooter modules={modules} branding={branding} />
      </div>
    </div>
  );
};

export default PreviewLayout;
