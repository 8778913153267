import schema from '../schemas/_options';

const optionReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_OPTIONS':
      return [...action.data];

    case 'ADD_OPTION':
      return [...state, action.data];

    default:
      return [...state];
  }
};

export default optionReducer;
