import { useEffect } from 'react';
import { usePreviewProperties } from '../../contexts/brandingContext';
import { makeCssColor } from '../../../../utils';
import { QRCodeSVG } from 'qrcode.react';
import './styles.scss';
// I don't know why this is imported link this but I copied it from the frontend - same style and options
// const QRCode = require('qrcode.react');

const PreviewRegisterInterest = ({ content, codeUrl, codeText }) => {
  // ask the preview to give us its properties
  const properties = usePreviewProperties();

  useEffect(() => {}, [content, codeUrl, codeText]);

  return (
    <>
      <style>
        span.highlight {`{ color: ${properties.branding.tertiary}}`}
      </style>
      <div
        className='preview-interest'
        style={{
          background: makeCssColor(properties.branding.section),
        }}
      >
        <div className='container'>
          <div className='flex-grid'>
            <div className='col-2-3'>
              <h1>Register Interest</h1>
              <hr style={{ borderColor: `${properties.branding.line}` }} />
              <p dangerouslySetInnerHTML={{ __html: content }} />
              <hr style={{ borderColor: `${properties.branding.line}` }} />
              <p dangerouslySetInnerHTML={{ __html: codeText }} />
              {codeUrl &&
                <QRCodeSVG value={codeUrl} />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewRegisterInterest;
