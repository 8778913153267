import * as yup from 'yup';
import { isCoordinates, isMediaObject } from '../../utils';

export const developmentMetadataSchema = yup.object({
  name: yup.string().required('Name is required'),
  developmentLocation: yup.mixed().test('is-coordinates', 'Coordinates are required', value => isCoordinates(value)),
  mapImage: yup.mixed()
    .test('is-media-object', 'must be valid media', value => isMediaObject(value))
    .optional(),
  localInformationPoster: yup.array().of(
    yup.mixed()
      .test('is-media-object', 'must be valid media', value => isMediaObject(value))
  ).optional(),
  mapType: yup.string().required('Map type is required'),
  eyesiteSlug: yup.string().optional(),
  promotionsBackgroundImage: yup.mixed()
    .test('is-media-object', value => isMediaObject(value, true))
    .nullable(),
});
