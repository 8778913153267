import store from '../store';
import { addToast } from '../store/actions/toasts';

const addPending = (message) => {
  let rndId = Math.random().toString(16);

  store.dispatch(
    addToast({
      id: rndId,
      type: 'pending',
      message: message,
    }),
  );

  return rndId;
};

export default addPending;
