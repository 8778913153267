import { Form, Heading } from '../../components';
import { useParams, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ApiError, beablooApi } from '../../utils';
import { useState } from 'react';
import { addOption } from '../../store/actions/options';
import { addError } from '../../utils';
import { addNotification } from '../../utils';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import './styles.scss';
const CreateOption = () => {
  const { typeId, developmentId } = useParams();
  const optionTypes = useSelector((state) => state.optionTypes);
  const optionType = optionTypes.find((type) => `${type.id}` === typeId);
  const [errors, setErrors] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);

  // const socket = useSocket();
  const dispatch = useDispatch();

  const handleSubmit = async (data) => {
    setSaving(true);

    let pendingId = addPending('Saving....');

    const payload = {
      name: data.name,
      optionType: typeId,
      description: data.description,
      thumbnail: data.thumbnail,
      images: [data.images],
    };

    await beablooApi({
      method: 'POST',
      route: `/developments/${developmentId}/options/create`,
      payload: payload,
    })
      .then((r) => {
        setSaving(false);
        removePending(pendingId);
        if (!r.success) {
          throw new ApiError(r.message, r.errors);
        }
        return r.data;
      })
      .then((r) => dispatch(addOption(r)))
      .then(() => addNotification('Option has been successfully created.'))
      .then(() => setRedirect(true))
      .then(() => setSaved(true))
      .catch((e) => {
        addError(e.message);
        setErrors(e.errors);
      });
  };

  if (redirect)
    return <Navigate to={`/developments/${developmentId}/optionsOverview`} />;

  return (
    <div className='page-padding'>
      <Heading label={`create an option: ${optionType.name}`} />
      <div className='create-option'>
        <Form
          errors={errors}
          canSubmit={!saving}
          inputs={[
            { name: 'name', type: 'text', label: 'name' },
            { name: 'description', type: 'textarea', label: 'description' },
            { name: 'thumbnail', type: 'single-image', label: 'thumbnail' },
            { name: 'images', type: 'single-image', label: 'images' },
          ]}
          onSubmit={handleSubmit}
          saved={saved}
        />
      </div>
    </div>
  );
};

export default CreateOption;
