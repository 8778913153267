import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatCurrency, makeCssColor } from '../../../../utils';
import Carousel from '../../components/Carousel';
import PlaceholderImage from '../../../../assets/img/placeholder.png';
import { ContentBlock, SideCard } from '../../components';
import './styles.scss';

import FloorPlanImage from '../../../../assets/img/floorplan-button.jpg';
import OptionCenterImage from '../../../../assets/img/property_options_center.jpg';
import DevelopmentLocationImage from '../../../../assets/img/development_location.jpg';

// Preview component for <SigntouchPreview />
// takes in everything from the HouseType Model
// https://signtouch-api-docs.prodigitalsignage.co.uk/models/housetype/

const PreviewIndividualHouseType = ({
  name,
  description,
  about,
  type,
  price,
  features,
  dimensions,
  galleries,
  thumbnail,
  floorplan,
  flyover,
  options,
  images,
  details,
}) => {
  const { branding } = useSelector((state) => state.properties);

  // update whenever anything changes
  useEffect(() => {}, [
    name,
    description,
    about,
    type,
    price,
    features,
    dimensions,
    galleries,
    thumbnail,
    floorplan,
    flyover,
    options,
    images,
    details,
  ]);

  // merge images and thumbnail so that
  useEffect(() => {}, [images, thumbnail]);

  return (
    <div className='preview-individual-house-type'>
      <div
        className='preview-individual-house-type-heading'
        style={{ background: makeCssColor(branding.section) }}
      >
        <h3>
          {name ? name : 'Property Name'}
          <br />
          {details?.bedrooms ? details.bedrooms : 0}
          {` bedroom ${type ? type.toLowerCase() : 'house'}`}
        </h3>
      </div>
      <div className='preview-individual-house-images'>
        {images && images.length > 0 ? (
          <Carousel key={`slider-render-${images.length}`} images={images} />
        ) : (
          <Carousel images={[PlaceholderImage]} />
        )}
      </div>
      <div className='preview-individual-house-split'>
        <div className='preview-individual-house-split-left'>
          <div className='preview-individual-house-price'>
            <p>From {formatCurrency(price?.amount ? price.amount : 0)}</p>
          </div>
          <div className='preview-individual-house-plots'>
            <p style={{ color: branding.primary }}>
              10 Plots currently available
            </p>
          </div>
          {details?.squareFeet && (
            <div className='preview-individual-house-square-feet'>
              <p>{details.squareFeet} sq ft</p>
            </div>
          )}
          <div className='preview-individual-house-description'>
            <ContentBlock
              html={description ? description : '<p>Property caption</p>'}
            />
          </div>
          <span
            className='preview-individual-house-break'
            style={{
              background: branding.primary,
            }}
          />
          <div className='preview-individual-house-about'>
            <ContentBlock
              html={about ? about : '<p>Rich text for about property.</p>'}
            />
          </div>
        </div>
        <div className='preview-individual-house-split-right'>
          <div className='preview-house-side-cards'>
            <SideCard label='Floor Plan' image={FloorPlanImage} />
            <SideCard label='Options Center' image={OptionCenterImage} />
            <SideCard label='Development Location' image={DevelopmentLocationImage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewIndividualHouseType;
