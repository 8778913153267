import './styles.scss';
import { ReactComponent as PromotionIcon } from '../../icons/promotions.svg';
import { ReactComponent as DevelopmentOverviewIcon } from '../../icons/development-overview.svg';

const PreviewFooter = ({ modules, branding }) => {
  return (
    <div className='preview-footer'>
      <div className='preview-footer-grid'>
        <div
          className='preview-footer-card'
          style={{
            backgroundColor: branding.primary,
          }}
        >
          <div className='preview-footer-icon'>
            <DevelopmentOverviewIcon fill={'fff'} />
          </div>
          <p>{modules.developmentOverview.title}</p>
        </div>
        <div
          className='preview-footer-card'
          style={{
            backgroundColor: branding.secondary,
          }}
        >
          <div className='preview-footer-icon'>
            <PromotionIcon fill={'fff'} />
          </div>
          <p>{modules.promotionsAndSalesMethods.title}</p>
        </div>
      </div>
    </div>
  );
};

export default PreviewFooter;
