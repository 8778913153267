// add a toast to the state
// the data argument must be a toast
// object (see reducer)
const addToast = (data) => {
  return {
    type: 'ADD_TOAST',
    data: data,
  };
};

// remove a toast
// the data argument must be the ID of a toast
// if an invalid id is passed there will be no change
// to the state
const removeToast = (data) => {
  return {
    type: 'REMOVE_TOAST',
    data: data,
  };
};

export { addToast, removeToast };
