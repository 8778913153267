import { useSelector } from 'react-redux';
import { Promotion } from '..';
import { Heading } from '../../../components';

const ParentView = () => {
  const promotionsState = useSelector((state) => state.promotions);
  const { currentDevelopment } = useSelector((state) => state.general);

  return (
    <>
      <Heading label={`created by ${currentDevelopment.name}`} />

      <div className={'promotions-list'}>
        {promotionsState.map((promotion) => {
          return <Promotion key={promotion.id} {...promotion} />;
        })}
      </div>
    </>
  );
};

export default ParentView;
