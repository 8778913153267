import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setDevelopments } from '../../store/actions/developments';
import { DevelopmentLayout } from '../../layouts';
import { Heading } from '../../components';
import { DevelopmentGroup } from './components';
import PulseGif from '../../assets/pulse.gif';
import { applicationRemoveLoadError } from '../../store/actions/general';
import './styles.scss';

const FetchingDevelopments = () => {
  return (
    <div className='fetching-developments'>
      <img src={PulseGif} alt='preloading gif' />
      <p>Fetching your developments</p>
    </div>
  );
};

const Developments = (props) => {
  const developments = useSelector((state) => state.developments);
  const dispatch = useDispatch();
  const [parents, setParents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // when page loads fetch development
  const handleMount = async () => {
    await dispatch(setDevelopments());

    // page loading is complete show developments
    setIsLoading(() => {
      return false;
    });
  };

  useEffect(() => {
    dispatch(applicationRemoveLoadError());
  }, []);

  useEffect(() => {
    handleMount();
  }, []);

  useEffect(() => {
    // grab all the parent developments from redux
    const parents = developments
      .filter((development) => development.type === 'signtouch-parent')
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)); // alphabetically sort
    // grab all the region developments from redux
    const regions = developments.filter(
      (development) => development.type === 'signtouch-region',
    );
    // grab all the site developments from redux
    const sites = developments.filter(
      (development) => development.type === 'signtouch-site',
    );
    // add sites and regions to the parents
    const formattedDevelopments = parents.map((parent) => {
      return {
        ...parent,
        regions: [
          ...regions
            .filter((region) => region.parentId === parent.id)
            .map((region) => {
              return {
                ...region,
                sites: [...sites.filter((site) => site.parentId === region.id)],
              };
            }),
        ],
      };
    });

    // pass the updated information to state
    setParents(formattedDevelopments);
  }, [developments]);

  return (
    <DevelopmentLayout>
      <div className='page-padding'>
        <div className='heading-with-button'>
          <Heading label='Your Developments' />
        </div>

        {parents.length < 0 && <p>no developments</p>}

        {isLoading ? (
          <FetchingDevelopments />
        ) : (
          <>
            {parents.map((parent) => {
              return (
                <DevelopmentGroup
                  key={Math.random().toString(16)}
                  {...parent}
                />
              );
            })}
          </>
        )}
      </div>
    </DevelopmentLayout>
  );
};

export default Developments;
