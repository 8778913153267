import { Link, useParams } from 'react-router-dom';
import { Heading, Page, SubHeading } from '../../components';
import { Box } from '@mui/material';
import { ColorPicker, SingleImage, Button } from '../../components/FormV2/components';
import { useState } from 'react';
import { useYupResolver } from '../../hooks';
import { useForm } from 'react-hook-form';
import { basicMapSchema } from './BasicMap.schema';
import { useDispatch, useSelector } from 'react-redux';
import { metadataService } from '../../lib/signtouch';
import addPending from '../../utils/addPending';
import { addError, addNotification } from '../../utils';
import { setDevelopmentMetaData } from '../../store/actions/general';
import removePending from '../../utils/removePending';

export const BasicMapConfigurationPage = () => {
  const { developmentId } = useParams();

  const { currentDevelopment } = useSelector(state => state.general);
  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false);
  const resolver = useYupResolver(basicMapSchema);
  const { control, handleSubmit } = useForm({
    resolver,
  });

  const onSubmit = async data => {
    const { mapImage, mapBackgroundColor } = data;

    const payload = {
      mapImage,
      mapBackgroundColor
    };

    setSaving(true);
    const savingToast = addPending('updating map configuration');

    try {
      await metadataService.updateMetadata(payload);
      await dispatch(setDevelopmentMetaData());
    } catch {
      addError('Error updating map configuration');
    }

    setSaving(false);
    removePending(savingToast);
    addNotification('Map configuration updated successfully');
  };

  return (
    <Page>
      <div className='heading-with-button'>
        <Heading label='Basic Map Configuration' />
        <Link to={`/developments/${developmentId}/multi-floor/floors/create`}>
          <Button label={'create floor'} />
        </Link>
      </div>
      <SubHeading>
        Here you change your site image and adjust the background colour if needed.
      </SubHeading>

      <Box
        sx={{
          maxWidth: '600px', p: 4, backgroundColor: 'white', borderRadius: 2
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <SingleImage
              name='mapImage'
              label='BASIC MAP IMAGE'
              control={control}
              defaultValue={currentDevelopment?.mapImage}
            />

            <ColorPicker
              name='mapBackgroundColor'
              label='BASIC MAP BACKGROUND COLOR'
              control={control}
              defaultValue={currentDevelopment?.mapBackgroundColor}
            />

            <Button type='submit' disabled={saving}>{saving ? 'Saving...' : 'Save'}</Button>
          </Box>
        </form>
      </Box>
  </Page>
  )
};
