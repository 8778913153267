import { Heading } from '../../components';
import { useSelector } from 'react-redux';
import { useState } from 'react';

import './styles.scss';
import { useEffect } from 'react';
import { beablooApi } from '../../utils';
import { useParams } from 'react-router-dom';
import { addError } from '../../utils';
import { useDispatch } from 'react-redux';
import { updateTransportLinks } from '../../store/actions/transportLinks';
import { Form } from '../../components';
import { addNotification } from '../../utils';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { airports } from './airports';

const TransportLinks = () => {
  let dispatch = useDispatch();

  const { developmentId } = useParams();

  // Global redux imports
  const transportLinksState = useSelector((state) => state.transportLinks);
  const [transportLinks, setTransportLinks] = useState(
    transportLinksState.features,
  );

  const [saving, setSaving] = useState(false);

  // Copy link into state to allow them to be edited

  // State for the various transport methods
  const [road, setRoad] = useState(false);
  const [rail, setRail] = useState(false);
  const [air, setAir] = useState(false);
  const [ferry, setFerry] = useState(false);
  const [bus, setBus] = useState(false);

  // Used to keep track of what is being updated so we can tell the user.
  const [currentlyUpdating, setCurrentlyUpdating] = useState(['init']);
  // Errors to be passed into the form
  const [errors, setErrors] = useState([]);

  const [addObject, setAddObject] = useState();

  // This is the state items for the quick add part of the page
  const [expanded, setExpanded] = useState(false);

  // This keeps track of what point is being added/if a new one is needed
  const [activePoint, setActivePointState] = useState();

  // These are the variables used for when a user is making or editing a point
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [location, setLocation] = useState(null);

  // Check if we have the enabled/disabled and set them in state
  useEffect(() => {
    setRoad(checkModule(transportLinksState.road));
    setRail(checkModule(transportLinksState.rail));
    setAir(checkModule(transportLinksState.airport));
    setFerry(checkModule(transportLinksState.ferry));
    setBus(checkModule(transportLinksState.bus));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This is for the quick add
  // We add an item to state called addObject which this checks for and adds
  // into the existing transport links before clearing itself ready for use again
  useEffect(() => {
    if (addObject && addObject.hasOwnProperty('name')) {
      let obj = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: addObject.location,
        },
        properties: {
          title: addObject.name,
          className: addObject.type.toLowerCase(),
          description: addObject.description,
        },
      };

      setTransportLinks([...transportLinks, obj]);
      setAddObject(null);
    }
  }, [addObject, transportLinks]);

  useEffect(() => {
    if (currentlyUpdating.indexOf('init') > -1) {
      setCurrentlyUpdating(
        currentlyUpdating.filter((current) => current !== 'init'),
      );
      return;
    }

    if (transportLinks !== transportLinksState) {
      setCurrentlyUpdating([...currentlyUpdating, 'methods']);
      // Update the api
      beablooApi({
        method: 'PUT',
        route: `/developments/${developmentId}/transportLinks/update`,
        payload: {
          features: transportLinks,
        },
      })
        .then((result) => {
          addNotification('Changes have been saved.');
          setCurrentlyUpdating((current) =>
            current.filter((item) => item !== 'methods'),
          );
        })
        .catch((error) => {
          setCurrentlyUpdating((current) =>
            current.filter((item) => item !== 'methods'),
          );
          addError(error.message);
        });
    }

    // eslint-disable-next-line
  }, [transportLinks]);

  const setActivePoint = (point) => {
    setErrors([]);

    if (!point) {
      setActivePointState(null);
      setName('');
      setLocation([]);
      setType('');
      setDescription('');
      return;
    }

    if (point.hasOwnProperty('properties')) {
      if (point.properties.hasOwnProperty('title')) {
        setName(point.properties.title);
      }

      if (point.properties.hasOwnProperty('className')) {
        setType(point.properties.className);
      }

      if (point.properties.description) {
        setDescription(point.properties.description);
      }

      if (
        point.hasOwnProperty('geometry') &&
        point.geometry.hasOwnProperty('coordinates')
      ) {
        setLocation(point.geometry.coordinates);
      } else {
        setLocation([]);
      }
    }

    setActivePointState(point);
  };

  // Handle a change in the update of method states.
  const updateTransportMethods = () => {
    if (saving) return;

    setSaving(true);

    let pendingId = addPending('Saving...');

    // Add the method to the currentlyUpdating array to add the pending indicator.
    setCurrentlyUpdating([currentlyUpdating, 'types']);
    // Define an object for us to mutate.
    let obj = {};

    // Make an object with the method and the new value
    obj['rail'] = {
      enabled: rail,
    };

    obj['road'] = {
      enabled: road,
    };

    obj['airport'] = {
      enabled: air,
    };

    obj['ferry'] = {
      enabled: ferry,
    };

    obj['bus'] = {
      enabled: bus,
    };

    beablooApi({
      method: 'PUT',
      route: `/developments/${developmentId}/transportLinks/update`,
      payload: obj,
    })
      .then((result) => {
        setSaving(false);
        removePending(pendingId);
        // Remove the item we just updated to remove the indicator
        setCurrentlyUpdating((current) =>
          current.filter((item) => item !== 'types'),
        );

        // Gets the method and updayes the related state, using a function to check their valid
        setRail(checkModule(result.data.rail));
        setRoad(checkModule(result.data.road));
        setAir(checkModule(result.data.airport));
        setFerry(checkModule(result.data.ferry));
        setBus(checkModule(result.data.bus));

        addNotification('Transport methods have been successfully saved.');

        // Update the global state with the new state.
        dispatch(updateTransportLinks(result.data));
      })
      .catch((error) => {
        addError(error.message);
      });
  };

  const handleChange = (e) => {
    if (e.hasOwnProperty('location') && e.location !== undefined) {
      setLocation(e.location);
    }

    if (e.hasOwnProperty('name')) {
      setName(e.name);
    }

    if (e.hasOwnProperty('type')) {
      setType(e.type);
    }

    if (e.hasOwnProperty('description')) {
      setDescription(e.description);
    }
  };

  const handleSave = () => {
    // Checks for errors and make it in the same format (rougly) as the api returns.
    let errors = [];

    if (!name || !name.length > 0) errors.push({ param: 'name' });
    if (!location || !location.length > 0) errors.push({ param: 'location' });
    if (!type || !type.length > 0) errors.push({ param: 'type' });
    if (!description || !description.length > 0)
      errors.push({ param: 'description' });

    if (errors.length > 0) {
      // We don't really have a style for location so adding a message if only location is missing.
      if (
        errors.length === 1 &&
        errors.filter((error) => error.param === 'location')
      ) {
        addError('A Location is needed to add a transport method.');
      } else {
        addError('Please complete all the fields.');
      }

      setErrors(errors);
      return;
    }

    let obj = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: location,
      },
      properties: {
        title: name,
        description: description,
        className: type.toLowerCase(),
      },
    };

    if (
      activePoint &&
      activePoint.hasOwnProperty('properties') &&
      activePoint.geometry.hasOwnProperty('coordinates')
    ) {
      setTransportLinks((links) => {
        return [...links.filter((link) => link !== activePoint), obj];
      });
    } else {
      setTransportLinks([...transportLinks, obj]);
    }

    setActivePoint();
  };

  const deletePoint = (point) => {
    setTransportLinks((links) => {
      return links.filter((link) => link !== point);
    });
  };

  return (
    <div className={'page-padding transport-links'}>
      {activePoint && (
        <div className={'modal'}>
          <div className={'body'}>
            <div className={'header'}>
              <h2>
                {activePoint.hasOwnProperty('properties') &&
                activePoint.properties.hasOwnProperty('title')
                  ? 'Edit: ' + activePoint.properties.title
                  : 'New Point'}
              </h2>
              <p onClick={(e) => setActivePoint(null)}>
                <svg
                  width={'1rem'}
                  version='1.1'
                  viewBox='0 0 25 25'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g transform='translate(3.9661 3.5678)'>
                    <path
                      d='m-2.5783e-4 -0.0014681 17.436 18.214'
                      fill='#5f6368'
                      stroke='#5f6368'
                      strokeLinecap='round'
                      strokeWidth='3.2316'
                    />
                    <path
                      d='m-2.5783e-4 18.212 17.436-18.214'
                      fill='#5f6368'
                      stroke='#5f6368'
                      strokeLinecap='round'
                      strokeWidth='3.2316'
                    />
                  </g>
                </svg>
              </p>
            </div>
            <div className={'form'}>
              <Form
                errors={errors}
                inputs={[
                  {
                    type: 'text',
                    name: 'name',
                    label: 'Name',
                    initialValue: name,
                  },
                  {
                    type: 'select',
                    name: 'type',
                    label: 'Type',
                    options: [{ label: 'Airport', value: 'airport' }],
                    initialValue: type,
                  },
                  {
                    type: 'textarea',
                    name: 'description',
                    initialValue: description,
                    label: 'Description',
                  },
                  {
                    type: 'location',
                    name: 'location',
                    label: 'Location',
                    initialValue: location,
                  },
                ]}
                onChange={handleChange}
              />
              <Form onSubmit={handleSave} />
            </div>
          </div>
        </div>
      )}

      <div className='heading-with-button'>
        <Heading label={'Transport Links'} />
      </div>
      <div className={'transport-plots'}>
        <div className={'table'}>
          <h3>Transport Methods</h3>
          <div className={'method'}>
            <div className={'switch-container'}>
              <input
                readOnly
                type={'checkbox'}
                name={'moduleState'}
                id={`input-road`}
                checked={road}
              />
              <label className={'switch'} onClick={(e) => setRoad(!road)}>
                Enabled
              </label>
            </div>
            <p>Road</p>
          </div>

          <div className={'method'}>
            <div className={'switch-container'}>
              <input
                readOnly
                type={'checkbox'}
                name={'moduleState'}
                id={`input-road`}
                checked={bus}
              />
              <label className={'switch'} onClick={(e) => setBus(!bus)}>
                Enabled
              </label>
            </div>
            <p>Bus</p>
          </div>

          <div className={'method'}>
            <div className={'switch-container'}>
              <input
                readOnly
                type={'checkbox'}
                name={'moduleState'}
                id={`input-road`}
                checked={rail}
              />
              <label className={'switch'} onClick={(e) => setRail(!rail)}>
                Enabled
              </label>
            </div>
            <p>Train Lines</p>
          </div>

          <div className={'method'}>
            <div className={'switch-container'}>
              <input
                readOnly
                type={'checkbox'}
                name={'moduleState'}
                id={`input-road`}
                checked={air}
              />
              <label className={'switch'} onClick={(e) => setAir(!air)}>
                Enabled
              </label>
            </div>
            <p>Airports</p>
          </div>

          <div className={'method'}>
            <div className={'switch-container'}>
              <input
                readOnly
                type={'checkbox'}
                name={'moduleState'}
                id={`input-road`}
                checked={ferry}
              />
              <label className={'switch'} onClick={(e) => setFerry(!ferry)}>
                Enabled
              </label>
            </div>
            <p>Ferrys</p>
          </div>

          <div className={'button-group'}>
            <button
              className={'button button-table'}
              onClick={(e) => updateTransportMethods()}
            >
              Save
            </button>
            {currentlyUpdating.indexOf('types') > -1 ? (
              <div className={'pending'}>
                <span className={'pending-span'} />
              </div>
            ) : null}
          </div>
        </div>

        <div className={'table'}>
          <div className={'top'}>
            <h3>
              Map Points
              {currentlyUpdating.indexOf('methods') > -1 ? (
                <div className={'pending'}>
                  <span className={'pending-span'} />
                </div>
              ) : null}
            </h3>
            <button
              className={'button button-table'}
              onClick={(e) => setActivePoint({})}
            >
              Add Point
            </button>
          </div>
          <table cellSpacing={0} cellPadding={0}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {transportLinks &&
                transportLinks.map((f) => {
                  return (
                    <tr key={Math.random().toString(16)}>
                      <td>{f.properties.title}</td>
                      <td style={{ textTransform: 'capitalize' }}>
                        {f.properties.className}
                      </td>
                      <td>
                        <button
                          className={'button button-table button-active'}
                          onClick={(e) => setActivePoint(f)}
                        >
                          Edit
                        </button>
                        <button
                          className={'button button-table button-red'}
                          onClick={(e) => deletePoint(f)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {!transportLinks.length > 0 ? (
            <div className={'no-points'}>
              <h3>No Points Found</h3>
              <p>Get started by adding a point.</p>
            </div>
          ) : null}
        </div>
        <div className={expanded ? 'table expanded' : 'table collapsed'}>
          <div
            className={'table-heading'}
            onClick={(e) => setExpanded(!expanded)}
          >
            <h3>Quick Add</h3>
            <div className={'collapse'}>
              <div
                className={
                  expanded
                    ? 'collapse-container'
                    : 'collapse-container collapsed'
                }
              >
                <span />
                <span />
              </div>
            </div>
          </div>
          <p>
            Below is a list of commonly used airports in the United Kingdom, you
            can edit the points if needed once added.
          </p>
          <table cellSpacing={0} cellPadding={0}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {airports.map(airport => (
                <tr>
                <td>{airport.name}</td>
                <td>{airport.type}</td>
                <td>
                  <button
                    className={'button button-table button-primary'}
                    onClick={() => {
                      setAddObject({
                        name: airport.name,
                        type: airport.type,
                        description: airport.description,
                        location: airport.coordinates,
                      });
                    }}
                  >
                    Add To Map
                  </button>
                </td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

// This function checks the transport link, and tries to parse the transport link state. Used as on occasion some are missing.
function checkModule(object) {
  try {
    if (object.hasOwnProperty('enabled')) return object.enabled;
  } catch (e) {
    return false;
  }

  return false;
}

export default TransportLinks;
