import { ApiError, beablooApi } from '../../utils';

const setGalleries = (data) => {
  return async (dispatch, getState) => {
    const { currentDevelopment } = getState().general;
    const { developments } = getState();

    // used to inject the ID of the development
    // that a house Type belongs to so we can filter later
    const injectId = (data, id) => {
      return {
        ...data,
        belongsTo: id,
      };
    };

    const requestRequiredGalleries = (currentDevelopment, developments) => {
      return new Promise(async (resolve, reject) => {
        try {
          let siteGalleries = [];
          let regionGalleries = [];
          let parentGalleries = [];

          // find only parent houses
          if (currentDevelopment.type === 'signtouch-parent') {
            parentGalleries = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.id}/galleries`,
            })
              .then((r) => r.data)
              .then((r) => r.map((r) => injectId(r, currentDevelopment.id)))
              .catch((e) => {
                throw new ApiError('failed to get parent galleries', []);
              });
          }

          // find parent && region houses
          if (currentDevelopment.type === 'signtouch-region') {
            parentGalleries = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.parentId}/galleries`,
            })
              .then((r) => r.data)
              .then((r) =>
                r.map((r) => injectId(r, currentDevelopment.parentId)),
              )
              .catch((e) => {
                throw new ApiError('failed to get parent galleries', []);
              });
            regionGalleries = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.id}/galleries`,
            })
              .then((r) => r.data)
              .then((r) => r.map((r) => injectId(r, currentDevelopment.id)))
              .catch((e) => {
                throw new ApiError('failed to get region galleries', []);
              });
          }

          // find parent && region && site houses
          if (currentDevelopment.type === 'signtouch-site') {
            // find the site's region so we can use it's parent Id to get the last
            // of the options available to us
            const regionDevelopment = developments.find(
              (development) => development.id === currentDevelopment.parentId,
            );
            parentGalleries = await beablooApi({
              method: 'GET',
              route: `/developments/${regionDevelopment.parentId}/galleries`,
            })
              .then((r) => r.data)
              .then((r) =>
                r.map((r) => injectId(r, regionDevelopment.parentId)),
              )
              .catch((e) => {
                throw new ApiError('failed to get parent galleries', []);
              });
            regionGalleries = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.parentId}/galleries`,
            })
              .then((r) => r.data)
              .then((r) =>
                r.map((r) => injectId(r, currentDevelopment.parentId)),
              )
              .catch((e) => {
                throw new ApiError('failed to get region galleries', []);
              });
            siteGalleries = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.id}/galleries`,
            })
              .then((r) => r.data)
              .then((r) => r.map((r) => injectId(r, currentDevelopment.id)))
              .catch((e) => {
                throw new ApiError('failed to get site galleries', []);
              });
          }

          resolve([...siteGalleries, ...regionGalleries, ...parentGalleries]);
        } catch (e) {
          reject(e);
        }
      });
    };

    dispatch({
      type: 'SET_GALLERIES',
      data: await requestRequiredGalleries(currentDevelopment, developments),
    });
  };
};

export { setGalleries };
