import { ApiError, beablooApi } from '../../utils';

const setPromotionsAndSalesMethods = (data) => {
  return async (dispatch, getState) => {
    const { currentDevelopment } = getState().general;

    // used to inject the ID of the development
    // that a house Type belongs to so we can filter later
    const injectId = (data, id) => {
      return {
        ...data,
        belongsTo: id,
      };
    };

    const requestRequiredPromotions = (currentDevelopment) => {
      return new Promise(async (resolve, reject) => {
        try {
          let regionPromotions = [];
          let parentPromotions = [];

          // find only parent houses
          if (currentDevelopment.type === 'signtouch-parent') {
            parentPromotions = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.id}/promotions`,
            })
              .then((r) => r.data)
              .then((r) => r.map((r) => injectId(r, currentDevelopment.id)))
              .catch((e) => {
                throw new ApiError('failed to get parent promotions', []);
              });
          }

          // find parent && region houses
          if (currentDevelopment.type === 'signtouch-region') {
            parentPromotions = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.parentId}/promotions`,
            })
              .then((r) => r.data)
              .then((r) =>
                r.map((r) => injectId(r, currentDevelopment.parentId)),
              )
              .catch((e) => {
                throw new ApiError('failed to get parent promotions', []);
              });
            regionPromotions = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.id}/promotions`,
            })
              .then((r) => r.data)
              .then((r) => r.map((r) => injectId(r, currentDevelopment.id)))
              .catch((e) => {
                throw new ApiError('failed to get region promotions', []);
              });
          }

          resolve([...regionPromotions, ...parentPromotions]);
        } catch (e) {
          reject(e);
        }
      });
    };

    dispatch({
      type: 'SET_PROMOTIONS',
      data: await requestRequiredPromotions(currentDevelopment),
    });
  };
};

const addPromotion = (data) => {
  return {
    type: 'ADD_PROMOTION',
    data: data,
  };
};

const updatePromotion = (data) => {
  return {
    type: 'UPDATE_PROMOTION',
    data: data,
  };
};

const deletePromotion = (data) => {
  return {
    type: 'DELETE_PROMOTION',
    data: data,
  };
};

export {
  setPromotionsAndSalesMethods,
  updatePromotion,
  addPromotion,
  deletePromotion,
};
