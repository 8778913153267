export const isMediaObject = (value, nullable) => {
  if (nullable && value === null) {
    return true;
  }

  if (typeof value === 'string') {
    const regexImage = /^data:image\/(jpeg|jpg|png);base64,/;
    const regexVideo = /^data:video\/mp4;base64,/;
    
    return regexImage.test(value) || regexVideo.test(value);
  }

  if (typeof value === 'object') {
    const { id, url } = value;
    const regex = /https:\/\/media.beabloo.com\/engine2\/attachment\/\d+/;

    return !!(id && regex.test(url));
  }

  return false;
};
