import { Button, TreeItem } from '../../components';
import { Heading } from '../../components';
import { useSelector } from 'react-redux';
import { CreateDesignAdviceCategory } from '../../components';
import { useState } from 'react';
import { CreateDesignAdviceType } from '../../components';
import { useParams } from 'react-router-dom';
import { DeleteModal } from '../../components';
import './styles.scss';

const DesignAdvice = (props) => {
  const { developmentId } = useParams();
  // Keep track of if we're deleting
  let [isDelete, setDelete] = useState(false);
  // Local copy of state
  let { categories, articles, types } = useSelector(
    (state) => state.designAdvice,
  );
  // Modals neet to store there id's somewhere
  let [categoryId, setCategoryId] = useState('');
  let [typeId, setTypeId] = useState('');
  let [articleId, setArticleId] = useState('');
  // Get rid of them.
  const clearModals = () => {
    setTypeId('');
    setCategoryId('');
  };
  // Handle close for the delete modals
  const handleClose = () => {
    setTypeId('');
    setCategoryId('');
    setArticleId('');
    setDelete(false);
  };
  // Open the type modal, set category and type as we need both
  const openTypeModal = (category, type) => {
    setTypeId(type);
    setCategoryId(category);
  };

  return (
    <div className={'page-padding'}>
      {categoryId ||
      categoryId === null ||
      typeId ||
      typeId === null ||
      articleId ? (
        <div className={'cover'} onClick={clearModals} />
      ) : null}
      {isDelete && typeId ? (
        <DeleteModal
          type={'design type'}
          designAdvice={{ id: typeId }}
          options={{ id: typeId }}
          closeModal={handleClose}
        />
      ) : null}
      {isDelete && categoryId ? (
        <DeleteModal
          type={'design category'}
          designAdvice={{ id: categoryId }}
          options={{ id: categoryId }}
          closeModal={handleClose}
        />
      ) : null}
      {isDelete && articleId ? (
        <DeleteModal
          type={'design advice'}
          designAdvice={{ id: articleId }}
          options={{ id: articleId }}
          closeModal={handleClose}
        />
      ) : null}

      {(categoryId === null || categoryId) &&
      !(typeId === null || typeId) &&
      !isDelete ? (
        <CreateDesignAdviceCategory
          closeModal={clearModals}
          categoryId={categoryId}
        />
      ) : null}
      {(typeId === null || typeId) && !isDelete ? (
        <CreateDesignAdviceType
          typeId={typeId}
          closeModal={clearModals}
          categoryId={categoryId}
        />
      ) : null}

      <div className='heading-with-button'>
        <Heading label={'Design Advice Overview'} />
        <div onClick={(e) => setCategoryId(null)}>
          <Button label={'create advice category'} />
        </div>
      </div>

      <div className='design-advice'>
        <div className={'tree'}>
          {categories.map((c) => {
            return (
              <TreeItem
                title={c.name}
                editEvent={(e) => setCategoryId(c.id)}
                editIcon
                editLink={'#'}
                addEvent={(e) => openTypeModal(c.id, null)}
                addIcon
                addLink={'#'}
                deleteIcon
                deleteEvent={(e) => {
                  setCategoryId(c.id);
                  setDelete(true);
                }}
              >
                {types.map((t) => {
                  if (c.id != t.category) return null;
                  return (
                    <TreeItem
                      title={t.name}
                      editEvent={(e) => openTypeModal(c.id, t.id)}
                      editIcon
                      editLink={'#'}
                      addIcon
                      addLink={`/developments/${developmentId}/design-advice/${t.id}/create`}
                      deleteIcon
                      deleteEvent={(e) => {
                        setTypeId(t.id);
                        setDelete(true);
                      }}
                    >
                      {articles.map((a) => {
                        if (t.id != a.type) return null;
                        return (
                          <TreeItem
                            title={a.name}
                            lastChild
                            editIcon
                            editLink={`/developments/${developmentId}/design-advice/${t.id}/${a.id}`}
                            deleteIcon
                            deleteEvent={(e) => {
                              setArticleId(a.id);
                              setDelete(true);
                            }}
                          />
                        );
                      })}
                    </TreeItem>
                  );
                })}
              </TreeItem>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DesignAdvice;
