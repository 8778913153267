import { beablooApi } from '../../utils';
import store from '../../store';

const getPlots = async () => {
  const { general: { currentDevelopment } } = store.getState();

  const response = await beablooApi({
    method: 'GET',
    route: `/developments/${currentDevelopment.id}/plots`
  });

  if (!response.success) {
    throw new Error('unable to get plots')
  }

  return response.data;
};

const createPlot = async (plot) => {
  const { general: { currentDevelopment } } = store.getState();

  const response = await beablooApi({
    method: 'POST',
    route: `/developments/${currentDevelopment.id}/plots/create`,
    payload: plot
  });

  if (!response.success) {
    throw new Error('unable to update plot')
  }

  return response.data;
};

const updatePlot = async (plot) => {
  const { general: { currentDevelopment } } = store.getState();

  const response = await beablooApi({
    method: 'PUT',
    route: `/developments/${currentDevelopment.id}/plots/${plot.id}/update`,
    payload: plot
  });

  if (!response.success) {
    throw new Error('unable to update plot')
  }

  return response.data;
};

const plotService = {
  getPlots,
  createPlot,
  updatePlot,
};

export default plotService;
