import { Notification, Form, Heading } from '../../components';
import { useParams, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { beablooApi } from '../../utils';
import { useState } from 'react';
import { updateOptionType } from '../../store/actions/optionTypes';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { addNotification } from '../../utils';
import { addError } from '../../utils';

const EditOptionType = () => {
  const { typeId, developmentId } = useParams();
  const optionTypes = useSelector((state) => state.optionTypes);
  const optionType = optionTypes.find((type) => `${type.id}` === typeId);
  const [error, setError] = useState({ error: false, message: '' });
  const [saved, setSaved] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [saving, setSaving] = useState(false);

  // const socket = useSocket();
  const dispatch = useDispatch();

  const handleSubmit = async (data) => {
    setSaving(true);

    let pendingId = addPending('Saving...');

    let payload = {};

    if (data.name) payload.name = data.name;
    if (data.images && data.images.length > 0) payload.images = data.images;

    await beablooApi({
      method: 'PUT',
      route: `/developments/${developmentId}/optionTypes/${typeId}/update`,
      payload: payload,
    }).then((r) => {
      removePending(pendingId);
      if (r.message !== 'success') {
        setSaving(false);
        setError({ error: true, message: r.message });
        addError(r.message);
      } else {
        // plot saved
        setSaving(false); // stop loading animation
        dispatch(updateOptionType(r.data)); // add the plot to state
        // socket.emit('optionCategory:create', { room: developmentId, payload: r.data });  // emit the the new plot to anyone looking at currentDevelopment.id
        setSaved(true); // tell state to redirect
        addNotification('Saved successfully.');
      }
    });
  };

  if (redirect)
    return <Navigate to={`/developments/${developmentId}/optionsOverview`} />;

  return (
    <div className='create-option page-padding'>
      {error.error ? (
        <Notification
          message={error.message}
          removeElement={(e) => setError({ error: false, message: '' })}
        />
      ) : (
        <></>
      )}

      <Heading label={`edit an option type: ${optionType.name}`} />

      <Form
        inputs={[
          {
            name: 'name',
            type: 'text',
            label: 'name',
            placeholder: 'Category Name',
            initialValue: optionType.name,
          },
          {
            name: 'thumbnail',
            type: 'single-image',
            label: 'thumbnail',
            initialValue: optionType.image,
          },
        ]}
        onSubmit={handleSubmit}
        canSubmit={!saving}
      />
    </div>
  );
};

export default EditOptionType;
