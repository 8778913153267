import { BrowserRouter as Router, Route, Navigate } from 'react-router-dom';
import { Routes } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Login, Developments } from './pages';
import { ProtectedRoute } from './middleware';
import jwtDecode from 'jwt-decode';
import { Login as ApiLogin, Logout } from './store/actions/authentication';
import { AppRoutes } from './components';
import ToastController from './components/ToastController';
import { toggleMenu } from './store/actions/ui';
import makeCssColor from './utils/makeCssColor';

const App = () => {
  const dispatch = useDispatch();

  const authentication = useSelector((state) => state.authentication);
  const { branding } = useSelector((state) => state.properties);

  const [jwt, setJwt] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('signtouch-authentication');
    if (token) {
      const decodedJwt = jwtDecode(token);
      if (Date.now() >= decodedJwt.exp * 1000) {
        localStorage.removeItem('signtouch-authentication');
        return;
      }
      dispatch(ApiLogin(token));

      setJwt(decodedJwt);
    }

    window.addEventListener('resize', handleResize);
  }, [dispatch]);

  const handleResize = () => {
    if (
      document.getElementsByClassName('side-bar')[0] &&
      document.getElementsByClassName('side-bar')[0].classList.contains('close')
    ) {
      dispatch(toggleMenu());
    }
  };

  // repeatably check if the user should be logged out
  useEffect(() => {
    // check on the users loggedin status every x seconds
    const interval = setInterval(() => {
      if (authentication.loggedin) {
        // if the jwt has expired log user out
        if (jwt) {
          if (Date.now() >= jwt.exp * 1000) {
            dispatch(Logout());
          }
        }
      }
    }, 5000);
  }, []);

  return (
    <>
      <ToastController />
      {/* <MenuTogglePageChange /> */}
      <style>
        .form-textarea-section-bg .textarea-canvas, .form-textarea-card-bg
        .textarea-canvas {`{ transition: all 0.3s ease; }`}
        .form-textarea-section-bg .textarea-canvas{' '}
        {`{background: ${makeCssColor(branding.section)} }`}
        .form-textarea-card-bg .textarea-canvas{' '}
        {`{background: ${makeCssColor(branding.card)} }`}
      </style>
      <div className='App'>
        <Router>
          <Routes>
            <Route exact path='/' element={<Navigate to='/login' />} />
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/developments' element={<ProtectedRoute><Developments /></ProtectedRoute>} />
            <Route path='/developments/:developmentId/*' element={<AppRoutes />} />
          </Routes>
        </Router>
      </div>
    </>
  );
};

export default App;
