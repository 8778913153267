import store from '../../store';
import { beablooApi } from '../../utils';

const updateMetadata = async payload => {
  const { general: { currentDevelopment } } = store.getState();

  const response = await beablooApi({
    method: 'PUT',
    route: `/developments/${currentDevelopment.id}/metadata/update`,
    payload,
  });

  if (!response.success) {
    throw new Error('unable to create local information')
  }

  return response.data;
}



const metadataService = {
  updateMetadata,
}

export default metadataService;
