import './styles.scss';
import { ReactComponent as BookmarkIcon } from '../../icons/bookmark.svg';
import { ReactComponent as HomeIcon } from '../../icons/home.svg';

const PreviewHeader = ({ development, branding }) => {
  return (
    <div className='preview-header'>
      <div className='preview-header-top'>
        <div className='preview-header-home'>
          <p>{development.name}</p>
        </div>
        <div className='preview-header-buttons'>
          <p>Back</p>
          <div
            className='preview-header-icon'
            style={{
              backgroundColor: branding.tertiary,
            }}
          >
            <BookmarkIcon fill={'#fff'} />
          </div>
          <div
            className='preview-header-icon'
            style={{
              backgroundColor: branding.tertiary,
            }}
          >
            <HomeIcon fill={'#fff'} />
          </div>
        </div>
      </div>
      <div className='preview-header-bottom'>
        <img src={branding.logo.url} alt='company logo' />
      </div>
    </div>
  );
};

export default PreviewHeader;
