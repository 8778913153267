import { Box, Modal, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Button } from '../../../Button';
import { AddLocation } from '@mui/icons-material';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import { SearchBar } from '../SearchBar';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

export const Picker = ({ open, onClose, onLocationChange }) => {
  const map = useRef(null);
  const [lng, setLng] = useState(-3.4360);
  const [lat, setLat] = useState(55.3781);
  const [zoom] = useState(5); 

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: 'picker-map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      zoom: zoom
    });

    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
    })
  });

  const handleConfirm = () => {
    onLocationChange?.([lng, lat]); 
    onClose?.();
  };

  return (
    <Modal disablePortal open={open} onClose={() => onClose?.()}>
      <Box sx={style}>
        <Box>
          <Typography variant="h6" component="h2">
            Location Picker
          </Typography>
          <Typography sx={{ mt: 1, mb: 2 }}>
            Center the map on the location you want to select, then click confirm.
          </Typography>
        </Box>

        <div id='picker-map'
          style={{
            width: '100%',
            height: 400,
            borderRadius: 8,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <SearchBar mapRef={map} />
          <AddLocation sx={{
            userSelect: 'none',
            zIndex: 1,
            color: '#27a1fc',
          }}/>
          </div>
        <Button onClick={handleConfirm}>Confirm</Button>
      </Box>
    </Modal>
  )
};
