/* eslint-disable eqeqeq */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { OptionSelectorType } from '..';
import { ReactComponent as Chevron } from '../../../../assets/icons/chevron-forward-outline.svg';
import './styles.scss';
import { useEffect } from 'react';

// component for CreateHouseType page presents the signtouch
// category as a tree structure to the user props (id, name)
// are the ID and name of and option category
const OptionSelectorCategory = ({ id, name }) => {
  // pull out the option type and filter
  // them so we only get types related
  const optionTypes = useSelector((state) => state.optionTypes).filter(
    (type) => id == type.categoryId,
  ); // == is not a typo

  // keep track of if user should be able to see category's types
  const [showTypes, setShowTypes] = useState(false);
  const toggleShowTypes = () => setShowTypes(!showTypes);

  return (
    <div className='option-selector-category'>
      <div
        className='option-selector-category-header'
        onClick={toggleShowTypes}
      >
        <motion.div
          className='chevron'
          initial={{ rotate: 0 }}
          animate={{ rotate: showTypes ? 90 : 0 }}
        >
          <Chevron />
        </motion.div>
        <h1>{name}</h1>
      </div>
      <AnimatePresence>
        {showTypes && (
          <div className='option-selector-category-types'>
            {optionTypes &&
              optionTypes.map((type, index) => {
                return (
                  <OptionSelectorType
                    key={`option-selector-type-${index}`}
                    {...type}
                  />
                );
              })}
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default OptionSelectorCategory;
