import Form from '../Form';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { ApiError, beablooApi } from '../../utils';
import { addError } from '../../utils';
import { addNotification } from '../../utils';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import {
  addDesignAdviceType,
  setDesignAdvice,
  updateDesignAdviceType,
} from '../../store/actions/designAdvice';
import { useDispatch } from 'react-redux';

const CreateDesignAdviceType = ({ typeId, categoryId, closeModal }) => {
  // Redux, bruh, help me out!
  let dispatch = useDispatch();

  let general = useSelector((state) => state.general);
  let { types } = useSelector((state) => state.designAdvice);

  // Keep track of when we're waiting on the api.
  let [isSaving, setSaving] = useState(false);

  let [type, setType] = useState({});

  let [name, setName] = useState('');
  let [description, setDescription] = useState('');
  let [image, setImage] = useState('');

  useEffect(() => {
    if (!typeId) return;

    let currentType = typeId !== null ? types.find((t) => t.id == typeId) : {};

    if (currentType) setType(currentType);
    if (currentType && currentType.hasOwnProperty('name'))
      setName(currentType.name);
    if (currentType && currentType.hasOwnProperty('description'))
      setDescription(currentType.description);
    if (currentType && currentType.hasOwnProperty('image'))
      setImage(currentType.image);
  }, []);

  const handleSubmit = async () => {
    // Tell the modal we're saving
    setSaving(true);
    // Add the pending notification
    let pendingId = addPending(
      type && type.hasOwnProperty('id') ? 'Updating...' : 'Saving...',
    );
    // Check which type create or update and run the correct function
    if (type && !type.hasOwnProperty('id')) await addNewType();
    if (type && type.hasOwnProperty('id')) await updateExisting();
    // Remove the pendng
    removePending(pendingId);
    setSaving(false);

    if (closeModal) {
      closeModal();
    }
  };

  const handleChange = (data) => {
    if (data.hasOwnProperty('image')) setImage(data.image);
    if (data.hasOwnProperty('name')) setName(data.name);
    if (data.hasOwnProperty('description')) setDescription(data.description);
  };

  return (
    <div className={'modal-container'}>
      <div className={'modal form'}>
        {type && type.hasOwnProperty('id') ? (
          <h3>Edit {type.name}</h3>
        ) : (
          <h3>Create New Type</h3>
        )}
        {!typeId || type.hasOwnProperty('id') ? (
          <Form
            inputs={[
              { name: 'name', type: 'text', label: 'Name', initialValue: name },
              {
                name: 'description',
                type: 'textarea',
                label: 'Description',
                initialValue: description,
              },
              {
                name: 'image',
                type: 'single-image',
                label: 'Thumbnail',
                initialValue: image,
              },
            ]}
            canSubmit={!isSaving}
            onChange={handleChange}
            onSubmit={handleSubmit}
          />
        ) : null}
      </div>
    </div>
  );

  function addNewType() {
    return beablooApi({
      method: 'POST',
      route: `/developments/${general.currentDevelopment.id}/designAdviceTypes/create`,
      payload: {
        name: name,
        description: description,
        image: image,
        category: categoryId,
      },
    })
      .then((r) => {
        if (!r.success) {
          throw new ApiError(r.message, r.errors);
        }

        setType(r.data);

        addNotification('Successfully created new category');

        return r.data;
      })
      .then((r) => dispatch(addDesignAdviceType(r)))
      .catch((e) => {
        addError(e.message);
      });
  }

  function updateExisting() {
    return beablooApi({
      method: 'PUT',
      route: `/developments/${general.currentDevelopment.id}/designAdviceTypes/${type.id}/update`,
      payload: {
        name: name,
        description: description,
        image: image,
      },
    })
      .then((r) => {
        if (!r.success) {
          throw new ApiError(r.message, r.errors);
        }

        addNotification('Successfully updated category');

        return r.data;
      })
      .then((r) => dispatch(updateDesignAdviceType(r)))
      .catch((e) => {
        addError(e.message);
      });
  }
};

export default CreateDesignAdviceType;
