import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { removeToast } from '../../store/actions/toasts';
import { useEffect } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/information-circle.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/alert-circle.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/checkmark-circle.svg';

import './styles.scss';

const ToastController = () => {
  const toasts = useSelector((state) => state.toasts);

  return (
    <AnimatePresence>
      <motion.div layout='position' className='toasts'>
        {toasts.map((toast) => {
          return <Toast key={toast.id} {...toast} />;
        })}
        {/* <Toast type='error' message='some text to make sense some text to make sense some text to make sense some text to make sense some text to make sense' id={69} /> */}
      </motion.div>
    </AnimatePresence>
  );
};

const Toast = ({ id, type, message }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (type !== 'pending') {
      setTimeout(() => {
        dispatch(removeToast(id));
      }, 5000);
    }
    // eslint-disable-next-line
  }, []);

  const transitionVariants = {
    open: {
      translateX: '0%',
    },
    close: {
      translateX: '120%',
    },
  };

  return (
    <motion.div
      className={`toast ${type}`}
      variants={transitionVariants}
      initial={'close'}
      exit={'close'}
      animate={'open'}
    >
      <div className='toast-icon'>
        {type === 'error' && <ErrorIcon fill={'#fff'} />}
        {type === 'pending' && <InfoIcon fill={'#fff'} />}
        {type === 'notification' && <SuccessIcon fill={'#fff'} />}
      </div>

      <div className='toast-message'>
        <p>{message}</p>
      </div>
      <div
        className='close-button-container'
        onClick={(e) => dispatch(removeToast(id))}
      >
        <div className='close-button'>
          <CloseIcon fill={'#fff'} />
        </div>
      </div>
    </motion.div>
  );
};

export default ToastController;
