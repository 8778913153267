import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ChildResult from './ChildResult';

// component for grouping development search
// results, lets the user go to the parent
// or navigate to a child using the ChildResult
// component
const ParentResult = ({ id, label, options }) => {
  const { branding } = useSelector((state) => state.properties);

  return (
    <div className='parent-result'>
      <div className='parent-heading'>
        <h3>{label}</h3>
        <Link
          style={{ backgroundColor: branding.primary }}
          to={`/developments/${id}`}
        >
          <p>Configure</p>
        </Link>
      </div>
      <div className='parent-results'>
        {options.map((option) => {
          return (
            <ChildResult {...option} key={`child-result-${option.label}`} />
          );
        })}
      </div>
    </div>
  );
};

export default ParentResult;
