import { Heading } from '../../components';
import { Form } from '../../components';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { beablooApi } from '../../utils';
import { addNotification } from '../../utils';
import { addError } from '../../utils';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { SignTouchPreview } from '../../components';
import PreviewPromotionsAndSalesMethod from '../../components/SignTouchPreview/pages/PreviewPromotionsAndSalesMethod';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addPromotion } from '../../store/actions/promotions-and-sales';

const CreatePromotionsAndSalesMethods = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { developmentId } = useParams();
  const { currentDevelopment } = useSelector((state) => state.general);

  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    name: null,
    thumbnail: null,
    images: null,
    description: null,
  });

  const handleFormChange = (data) => {
    const { name, images, thumbnail, description } = data;

    if (name) {
      setFormData((state) => {
        return {
          ...state,
          name: name,
        };
      });
    }

    if (images) {
      setFormData((state) => {
        return {
          ...state,
          images: images,
        };
      });
    }

    if (thumbnail) {
      setFormData((state) => {
        return {
          ...state,
          thumbnail: thumbnail,
        };
      });
    }

    if (description) {
      setFormData((state) => {
        return {
          ...state,
          description: description,
        };
      });
    }
  };

  const handleFormSubmit = async () => {
    // tell the page we are saving
    setSaving(true);

    const pendingId = addPending('Saving Promotion');

    await beablooApi({
      method: 'POST',
      route: `/developments/${developmentId}/promotions/create`,
      payload: formData,
    })
      .then((r) => {
        // request complete no longer saving
        setSaving(false);
        // remove notification
        removePending(pendingId);
        if (!r.success) {
          throw new Error('unable to create promotion');
        }
        return r.data;
      })
      .then((r) =>
        dispatch(addPromotion({ ...r, belongsTo: currentDevelopment.id })),
      )
      .then(() => {
        addNotification('Created Promotion');
        navigate(`/developments/${developmentId}/promotions-sales-methods`);
      })
      .catch((e) => {
        addError(e.message);
      });
  };

  return (
    <div className={'page-padding'}>
      <Heading label={`Create A New Promotion`} />

      <div className='promotions-and-sales-methods'>
        <div className={'form-content'}>
          <Form
            inputs={[
              { type: 'text', label: 'name', name: 'name' },
              { type: 'single-image', label: 'Thumbnail', name: 'thumbnail' },
              { type: 'multi-image', label: 'Images', name: 'images' },
              { type: 'textarea', label: 'description', name: 'description' },
            ]}
            onChange={handleFormChange}
            onSubmit={handleFormSubmit}
            canSubmit={!saving}
          />
        </div>
        <div
          className={'preview'}
          style={{ position: 'fixed', right: '10vw', top: '8vh' }}
        >
          <SignTouchPreview>
            <PreviewPromotionsAndSalesMethod {...formData} />
          </SignTouchPreview>
        </div>
      </div>
    </div>
  );
};

export default CreatePromotionsAndSalesMethods;
