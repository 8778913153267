const branding = {
  colours: {
    primary: '#147699',
    secondary: '#E69B4C',
    tertiary: '4CBDE6',
  },
  logo: {
    normal: '662176',
  },
  mapColours: {
    available: '#dd1f64',
    sold: '#0cb1b1',
    reserved: '#1b1b1d',
    future: 'greenyellow',
  },
};

export default branding;
