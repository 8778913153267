/* eslint-disable eqeqeq */
import { Navigate, useParams } from 'react-router-dom';
import { useState } from 'react';

// component for search results
// returns a redirect to move between developments
// when clicked. wont change development is already active
const ChildResult = ({ label, value }) => {
  const { developmentId } = useParams();

  const [redirect, setRedirect] = useState(false);
  const toggleRedirect = () => {
    // if the development is already active
    // then don't return a redirect
    if (developmentId == value) {
      return;
    }
    setRedirect(!redirect);
  };

  if (redirect) {
    return <Navigate to={`/developments/${value}`} />;
  }

  return (
    <div className='child-result' onClick={toggleRedirect}>
      <p>{label}</p>
    </div>
  );
};

export default ChildResult;
