import { styled } from 'styled-components';
import { TrashIcon, PencilSquareIcon } from '@heroicons/react/24/solid';
import { Box, Stack } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useNavigate, useParams } from 'react-router-dom';

const FloorContainer = styled.div({
  border: '0.1em #959595 solid',
  borderRadius: '0.5rem',
  boxSizing: 'border-box',
  backgroundColor: '#fff',
  cursor: 'pointer',
  padding: '20px'
});

const FloorLabel = styled.p({
  fontFamily: '"Montserrat", sans-serif',
  fontSize: '1.2em',
  fontWeight: 800,
  textTransform: 'uppercase',
  color: '#121212',
  margin: 0,
});

const StyledTrashIcon = styled(TrashIcon)({
  '&:hover': {
    fill: '#f44336',
  },
});

export const Floor = ({ label, id, onDelete }) => {
  const handleDeleteFloor = () => {
    console.log('delete floor from floor');
    onDelete(id);
  };

  return (
    <FloorContainer>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FloorLabel>{label}</FloorLabel>
        <Box cursor="pointer" onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          handleDeleteFloor();
        }}>
          <StyledTrashIcon height="24px" width="24px" />
        </Box>
      </Stack>
    </FloorContainer>
  );
};


export const SortableFloor = ({ label, id, onDelete }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });
  
  const { developmentId } = useParams();
  const navigate = useNavigate();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleDeleteFloor = () => {
    onDelete(id);
  };

  const handleEditFloor = () => {
    navigate(`/developments/${developmentId}/multi-floor/floors/${id}/edit`);
  }
  
  return (
    <FloorContainer>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
          <FloorLabel>{label}</FloorLabel>
        </div>
        <Stack direction='row' spacing={1}>
          <Box cursor="pointer" onClick={handleEditFloor}>
            <PencilSquareIcon height="24px" width="24px" />
          </Box>
          <Box cursor="pointer" onClick={e => handleDeleteFloor()}>
            <StyledTrashIcon height="24px" width="24px" />
          </Box>
        </Stack>
      </Stack>
    </FloorContainer>
  );
}