import { Clear, PlayArrow } from '@mui/icons-material';
import { Box } from '@mui/material';

export const Video = ({ src: value, alt, onRemove }) => {
  const getSourceUrl = () => {
    if (typeof value === 'string') {
      return value;
    }

    if (typeof value === 'object') {
      return value?.url;
    }

    return '';
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#333',
      borderRadius: '8px',
      width: '120px',
      height: '120px',
      overflow: 'hidden',
      position: 'relative',
    }}>
      <Box
        sx={{
          position: 'absolute',
          top: 5,
          right: 5,
          p: 0.2,
          cursor: 'pointer',
          backgroundColor: '#666',
          borderRadius: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 20,
        }} 
        onClick={onRemove}
      >
        <Clear sx={{ color: 'white' }} />
      </Box>

      <Box sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 10,
      }}>
        <PlayArrow sx={{ color: 'white' }} />
      </Box>
      <video
        src={`${getSourceUrl()}#t=1`}
        alt={alt}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    </Box>
  )
};
