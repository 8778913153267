import { ApiError, beablooApi } from '../../utils';

const updateDesignAdvice = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_DESIGN_ADVICE',
      data: data,
    });
  };
};

const setDesignAdvice = () => {
  return async (dispatch, getState) => {
    const { currentDevelopment } = getState().general;

    const categories = await getDesignAdviceCategories(currentDevelopment.id);
    const articles = await getDesignAdviceArticles(currentDevelopment.id);
    const types = await getDesignAdviceTypes(currentDevelopment.id);

    dispatch({
      type: 'SET_DESIGN_ADVICE',
      data: {
        categories: categories,
        types: types,
        articles: articles,
      },
    });
  };
};

const getDesignAdviceCategories = async (developmentId) => {
  return beablooApi({
    method: 'GET',
    route: `/developments/${developmentId}/designAdviceCategories`,
  })
    .then((r) => r.data)
    .catch((e) => {
      throw new ApiError('failed to get advice categories');
    });
};

const getDesignAdviceArticles = async (developmentId) => {
  return await beablooApi({
    method: 'GET',
    route: `/developments/${developmentId}/designAdviceArticles`,
  })
    .then((r) => r.data)
    .catch((e) => {
      throw new ApiError('failed to get advice articles');
    });
};

const getDesignAdviceTypes = async (developmentId) => {
  return await beablooApi({
    method: 'GET',
    route: `/developments/${developmentId}/designAdviceTypes`,
  })
    .then((r) => r.data)
    .catch((e) => {
      throw new ApiError('failed to get advice type');
    });
};

const addDesignAdviceArticle = (data) => {
  return {
    type: 'ADD_DESIGN_ADVICE_ARTICLE',
    data: data,
  };
};

const addDesignAdviceType = (data) => {
  return {
    type: 'ADD_DESIGN_ADVICE_TYPE',
    data: data,
  };
};

const addDesignAdviceCategory = (data) => {
  return {
    type: 'ADD_DESIGN_ADVICE_CATEGORY',
    data: data,
  };
};

const updateDesignAdviceArticle = (data) => {
  return {
    type: 'UPDATE_DESIGN_ADVICE_ARTICLE',
    data: data,
  };
};

const updateDesignAdviceType = (data) => {
  return {
    type: 'UPDATE_DESIGN_ADVICE_TYPE',
    data: data,
  };
};

const updateDesignAdviceCategory = (data) => {
  return {
    type: 'UPDATE_DESIGN_ADVICE_TYPE',
    data: data,
  };
};

const deleteDesignAdviceArticle = (data) => {
  return {
    type: 'DELETE_DESIGN_ADVICE_ARTICLE',
    data: data,
  };
};

const deleteDesignAdviceType = (data) => {
  return {
    type: 'DELETE_DESIGN_ADVICE_TYPE',
    data: data,
  };
};

const deleteDesignAdviceCategory = (data) => {
  return {
    type: 'DELETE_DESIGN_ADVICE_CATEGORY',
    data: data,
  };
};

export {
  setDesignAdvice,
  updateDesignAdvice,
  addDesignAdviceArticle,
  addDesignAdviceType,
  addDesignAdviceCategory,
  updateDesignAdviceArticle,
  updateDesignAdviceType,
  updateDesignAdviceCategory,
  deleteDesignAdviceArticle,
  deleteDesignAdviceType,
  deleteDesignAdviceCategory,
};
