import { ApiError, beablooApi } from '../../utils';

const updateLocalInformation = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'SET_LOCAL',
      data: data,
    });
  };
};

const setLocalInformation = () => {
  return async (dispatch, getState) => {
    const { currentDevelopment } = getState().general;

    const response = await beablooApi({
      method: 'GET',
      route: `/developments/${currentDevelopment.id}/localInformation`,
    })
      .then((r) => r.data)
      .catch((e) => {
        throw new ApiError('failed to get local information', []);
      });

    dispatch({
      type: 'SET_LOCAL',
      data: response,
    });
  };
};

export { setLocalInformation, updateLocalInformation };
