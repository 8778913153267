import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { PlotItem } from './PlotItem';

export const PlotGroup = ({ label, onPlotClicked, plots }) => {
  return (
    <Accordion sx={{
      backgroundColor: '#121212',
      p: 0,
    }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ fill: 'white' }} />}
        sx={{ backgroundColor: '#3d3d42' }}
        aria-controls="panel1a-content"
      >
        <Typography
          variant='h6'
          color='white'
          textTransform='uppercase'
          fontSize={14}
        >
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: '#121212',
          p: 0,
        }}
      >
        {plots.map(plot => (
          <PlotItem 
            key={plot.id}
            plot={plot}
            onClick={() => onPlotClicked(plot)}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  )
};
