import PlaceholderImage from '../../../../assets/img/placeholder.png';
import './SideCard.styles.scss';

export const SideCard = ({ image, label }) => {
  return (
    <div className='preview-side-card'>
      <div
        className='preview-side-card-image'
        style={{
          backgroundImage: `url('${image ? image : PlaceholderImage}')`
        }}
      >
        <div className='preview-side-card-image-overlay' />
        <p>{label}</p>
      </div>
    </div>
  );
};
