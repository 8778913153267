import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import MatterportLogo from '../../assets/img/integrations/matterport-logo.png'


const IntegrationCardWrapper = styled('div')({
  backgroundColor: 'white',
  padding: '1.5em',
  width: '300px',
  display: 'flex',
  gap: '15px',
  borderRadius: '8px',
  position: 'relative',

});

const IntegrationCardLabel = styled('p')({
  fontWeight: 800,
  textTransform: 'uppercase',
  fontSize: '1.2em',
  color: '#121212',
});

const IntegrationCardThumbnail = styled('img')({
  height: '48px',
  width: '48px',
});

const IntegrationCardIndicator = styled('div')(({ enabled }) => ({
  height: '10px',
  width: '10px',
  backgroundColor: enabled ? 'green' : 'red',
  position: 'absolute',
  top: '10px',
  right: '10px',
  borderRadius: '10px',
}));

const integrationDict = {
  matterport: {
    link: 'matterport',
    thumbnail: MatterportLogo,
  }
};

export const IntegrationCard = ({ name, enabled }) => {
  const { developmentId } = useParams();

  return (
    <Link to={`/developments/${developmentId}/integrations/${integrationDict[name].link}`}>
      <IntegrationCardWrapper>
        <IntegrationCardThumbnail src={integrationDict[name].thumbnail} alt={`${name} logo`} />
        <IntegrationCardLabel>{ name }</IntegrationCardLabel>
        <IntegrationCardIndicator enabled={enabled} />
      </IntegrationCardWrapper>
    </Link>
  );
};
