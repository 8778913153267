import schema from '../schemas/_developments';

const developmentsReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_DEVELOPMENTS':
      // expects and array
      return [...action.data];

    default:
      return [...state];
  }
};

export default developmentsReducer;
