import { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import { motion, AnimatePresence } from 'framer-motion';
import BasicInfomation from './pages/BasicInfomation';
import AvailbileHouses from './pages/AvailbileHouses';
import DevelopmentMapSetup from './pages/DevelopmentMapSetup';
import PlotsUpload from './pages/PlotsUpload';
import FinishingUp from './pages/FinishingUp';

const SetupWizard = (props) => {
  let transitionState = {
    in: {
      transform: 'translateX(20%)',
      opacity: 0,
    },
    default: {
      transform: 'translateX(0%)',
      opacity: 1,
    },
    out: {
      transform: 'translateX(20%)',
      opacity: 0,
    },
  };

  let stepsState = {
    out: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
  };

  let [setupStage, setSetupStage] = useState(0);

  // Oooh... a one line function...
  const advanceStage = (e) => {
    if (e > 0) {
      setSetupStage(setupStage + 1);
    } else {
      setSetupStage(setupStage - 1);
    }
  };

  return (
    <div className='page-padding setup-wizard'>
      {setupStage > 0 && (
        <motion.div
          className='tracker'
          variants={stepsState}
          initial='out'
          animate='in'
          exit='out'
        >
          <ul>
            <li
              onClick={(e) => setSetupStage(1)}
              className={
                setupStage === 1 ? 'active' : setupStage < 1 ? '' : 'complete'
              }
            />
            <li
              onClick={(e) => setSetupStage(2)}
              className={
                setupStage === 2 ? 'active' : setupStage < 2 ? '' : 'complete'
              }
            />
            <li
              onClick={(e) => setSetupStage(3)}
              className={
                setupStage === 3 ? 'active' : setupStage < 3 ? '' : 'complete'
              }
            />
            <li
              onClick={(e) => setSetupStage(4)}
              className={
                setupStage === 4 ? 'active' : setupStage < 4 ? '' : 'complete'
              }
            />
          </ul>
        </motion.div>
      )}
      <AnimatePresence>
        {setupStage === 0 && (
          <motion.div
            variants={transitionState}
            initial='default'
            animate='default'
            exit='out'
            className='s-container center'
          >
            <h2>It looks like this development hasn't been setup yet.</h2>
            <button
              onClick={(e) => setSetupStage(1)}
              className={`button button-primary${
                process.env.NODE_ENV === 'development' ? ' gandalf' : ''
              }`}
            >
              Run Setup Wizard
            </button>
            <Link to={'#'}>Create a blank development</Link>
          </motion.div>
        )}
        {setupStage === 1 && (
          <motion.div
            key='stage-1'
            variants={transitionState}
            initial={setupStage >= 1 ? 'out' : 'in'}
            animate='default'
            exit={transitionState.out}
            className='s-container center'
          >
            <BasicInfomation nextStage={advanceStage} />
          </motion.div>
        )}
        {setupStage === 2 && (
          <motion.div
            key='stage-2'
            variants={transitionState}
            initial={setupStage >= 2 ? 'out' : 'in'}
            animate='default'
            exit={transitionState.out}
            className='s-container center'
          >
            <AvailbileHouses nextStage={advanceStage} />
          </motion.div>
        )}
        {setupStage === 3 && (
          <motion.div
            key='stage-3'
            variants={transitionState}
            initial={setupStage >= 3 ? 'out' : 'in'}
            exit={transitionState.out}
            animate='default'
            className='s-container center'
          >
            <DevelopmentMapSetup nextStage={advanceStage} />
          </motion.div>
        )}
        {setupStage === 4 && (
          <motion.div
            key='stage-4'
            variants={transitionState}
            initial='in'
            animate='default'
            exit={transitionState.out}
            className='s-container center'
          >
            <PlotsUpload nextStage={advanceStage} />
          </motion.div>
        )}
        {setupStage === 5 && (
          <motion.div
            key='stage-5'
            variants={transitionState}
            initial='in'
            animate='default'
            exit={transitionState.out}
            className='s-container center'
          >
            <FinishingUp nextStage={advanceStage} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SetupWizard;
