import { usePreviewProperties } from '../../contexts/brandingContext';
import { makeCssColor } from '../../../../utils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import IntroVideo from './intro-video.mp4';
import HomeImage1 from './image-1.jpg';
import HomeImage2 from './image-2.jpg';
import './styles.scss';

const PreviewHome = ({ video, image1, image2 }) => {
  // ask the preview to give us its properties
  const { branding } = usePreviewProperties();

  const { currentDevelopment } = useSelector((state) => state.general);

  const startButtonVariants = {
    initial: {
      scale: 1,
    },
    animate: {
      scale: [1, 1.2, 1],
    },
  };

  useEffect(() => {}, [video, image1, image2]);

  return (
    <div
      className='preview-home'
      style={{
        background: makeCssColor(branding.section),
      }}
    >
      <div className='preview-home-header'>
        <p>
          Welcome to <br />
          {currentDevelopment.name}
        </p>
      </div>
      <div className='preview-home-video'>
        <video src={video ? video : IntroVideo} autoPlay muted loop />
      </div>

      <div className='preview-start-button-conatiner'>
        <motion.div
          className='preview-start-button-layer-1'
          variants={startButtonVariants}
          initial='initial'
          animate='animate'
          transition={{ repeat: Infinity, duration: 2 }}
        >
          <div className='preview-start-button-layer-2'>
            <div
              className='preview-start-button'
              style={{
                background: branding.startButton,
              }}
            >
              <p style={{ color: branding.startButtonText }}>start</p>
            </div>
          </div>
        </motion.div>
        <p>Touch me and explore!</p>
      </div>

      <div className='preview-home-images'>
        <img src={image1 ? image1 : HomeImage1} alt='house 1' />
        <img src={image2 ? image2 : HomeImage2} alt='house 2' />
      </div>
    </div>
  );
};

export default PreviewHome;
