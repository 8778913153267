import { useEffect, useState, useRef } from 'react';

const TextInput = (props) => {
  const [state, setState] = useState({ value: '', error: false });
  const [isInitial, setInitial] = useState(false);
  const ref = useRef();

  const handleChange = (e) => {
    const { value, id } = e.target;

    let isError = false;
    if (props.validation) {
      const { length } = props.validation;
      if (value.length > length) isError = true;
    }

    setState({ value: value, error: isError });
    // pass back to <Form />
    props.onChange({ name: props.name, value: value });
  };

  useEffect(() => {
    if (props.initialValue && !isInitial) {
      setState({ value: props.initialValue, error: false });
    }
  }, []);

  return (
    <div
      className={`form-input-text ${
        props.error ? 'form-input-text-error' : ''
      }`}
    >
      <label>
        {props.label}
        <input
          placeholder={props.placeholder ? props.placeholder : ''}
          onChange={handleChange}
          value={state.value}
        />
      </label>
      {props.validation ? (
        <span>
          {state.value.length}/{props.validation.length}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TextInput;
