import { useEffect } from 'react';
import Select from 'react-select';
import styles from '../../../../styles/_colors.module.scss';
import './styles.scss';

const DropDown = ({ name, label, onChange, options, initialValue, error }) => {
  const handleChange = (e) => {
    const { value } = e;
    onChange({ name: name, value: `${value}` });
  };

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? `${styles.primary} !important`
        : error
        ? 'red'
        : '#959595',
      borderWidth: '2px',
      outline: 'none',
      boxShadow: 'none',
      marginBottom: '.3em',
      fontFamily: 'Montserrat',
      fontWeight: 'normal',
      textTransform: 'none',
      '&:focus': {
        borderColor: styles.primary,
      },
      '&:hover': {
        borderColor: '#959595',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? styles.primary : 'white',
      borderWidth: '2px',
      outline: 'none',
      boxShadow: 'none',
      '&:hover': {
        background: '#cdcdcd',
        color: 'black',
      },
    }),
  };

  const errorSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? `${styles.primary} !important`
        : error
        ? 'red'
        : '#d21616',
      borderWidth: '2px',
      outline: 'none',
      boxShadow: 'none',
      marginBottom: '.3em',
      fontFamily: 'Montserrat',
      fontWeight: 'normal',
      textTransform: 'none',
      '&:focus': {
        borderColor: styles.primary,
      },
      '&:hover': {
        borderColor: '#b90a0a',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? styles.primary : 'white',
      borderWidth: '2px',
      outline: 'none',
      boxShadow: 'none',
      '&:hover': {
        background: '#cdcdcd',
        color: 'black',
      },
    }),
  };

  return (
    <div className='form-input-select'>
      <label>{label}</label>
      <Select
        defaultValue={
          initialValue
            ? options[
                options.findIndex(
                  (option) => `${option.value}` === `${initialValue}`,
                )
              ]
            : false
        }
        options={[...options]}
        styles={error ? errorSelectStyles : selectStyles}
        onChange={handleChange}
      />
    </div>
  );
};

export default DropDown;
