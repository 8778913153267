import { Box } from '@mui/material';
import { Label } from '../Label';
import { Image, PreviewThumbnail } from './components';
import { Button } from '../Button';
import { useRef } from 'react';
import { useController } from 'react-hook-form';
import { HelperText } from '../HelperText';

export const MultiImage = ({ name, label, control, defaultValue }) => {
  const inputRef = useRef(null);

  const { 
    field: { onChange, value },
    fieldState: { error }
  } = useController({ name, control, defaultValue });

  const handleClick = () => {
    inputRef?.current.click();
  };

  const handleFileChange = async e => {
    const { files } = e.target;
    
    const newFiles = await Promise.all([...files].map(async file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve, reject) => {
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    }));

    onChange([...value, ...newFiles]);
  };

  const handleRemove = index => {
    onChange(value.filter((_, i) => i !== index));
    inputRef.current.value = '';
  };

  return (
    <>
      <Label>{label}</Label>
      <Box sx={error
        ? { border: '#D82C0D 1px solid', borderRadius: '8px', bgcolor: '#FFF4F4', p: 2 }
        : {
            backgroundColor: '#ededed',
            p: 2,
            borderRadius: '8px',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            gap: 2
          }
        }
      >
        {value.length > 0 && value.map((image, index) => (
          <Image src={image} alt='staged thumbnail' onRemove={() => handleRemove(index)} />
        ))}

        {!value.length > 0 && (
          <PreviewThumbnail />
        )}
      </Box>
      <input
        type='file'
        style={{ display: 'none' }}
        ref={inputRef}
        onChange={handleFileChange}
        accept='.png,.jpg,.jpeg'
        multiple
      />
      {error && (
        <HelperText message={error?.message} />
      )}
      <Button onClick={handleClick}>Choose files</Button>
    </>
  )
};