// fucntion to remove an items from
// an array using an ID as the argument
const removeToast = (state, id) => {
  let newArray = [...state];

  return newArray.filter((item) => item.id !== id);
};

// create a redux reducer to handle toasts
// a toast should take the following data
// format
// {
//   id: 'string' || int
//   type: 'notification' || 'error',
//   message: 'string'
// }
const ToastReducer = (state = [], action) => {
  switch (action.type) {
    // add a toast to the
    // redux handler must pass in a toast object
    case 'ADD_TOAST':
      return [...state, action.data];

    // remove a toast from the state
    // when calling action must pass
    // an id of a toast
    case 'REMOVE_TOAST':
      return [...removeToast(state, action.data)];

    default:
      return [...state];
  }
};

export default ToastReducer;
