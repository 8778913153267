import { ApiError, beablooApi } from '../../utils';

const applicationReady = () => {
  return {
    type: 'APPLICATION_READY',
  };
};

const applicationUnready = () => {
  return {
    type: 'APPLICATION_UNREADY',
  };
};

const applicationLoadError = () => {
  return {
    type: 'APPLICATION_LOAD_ERROR',
  };
};

const applicationRemoveLoadError = () => {
  return {
    type: 'APPLICATION_REMOVE_LOAD_ERROR',
  };
};

const setCurrentDevelopment = (data) => {
  localStorage.setItem('signtouch-current-development', JSON.stringify(data));
  return {
    type: 'SET_CURRENT_DEVELOPMENT',
    data: data,
  };
};

const setCurrentlyLoading = (data) => {
  return {
    type: 'SET_CURRENTLY_LOADING',
    data: data,
  };
};

const setDevelopmentMetaData = () => {
  return async (dispatch, getState) => {
    const { currentDevelopment } = getState().general;

    if (!currentDevelopment.parentId) return;

    if (currentDevelopment.parentId) {
      const metaData = await beablooApi({
        method: `GET`,
        route: `/developments/${currentDevelopment.id}/metadata`,
      })
        .then((r) => r.data)
        .catch((e) => {
          throw new ApiError('failed to get metadata', []);
        });

      dispatch({
        type: 'SET_CURRENT_DEVELOPMENT',
        data: {
          ...currentDevelopment,
          ...metaData,
        },
      });
    }
  };
};

export {
  applicationReady,
  applicationUnready,
  setCurrentDevelopment,
  setCurrentlyLoading,
  setDevelopmentMetaData,
  applicationLoadError,
  applicationRemoveLoadError,
};
