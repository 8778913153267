import schema from '../schemas/_home';

const homeReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_HOME':
      return {
        ...action.data,
      };

    default:
      return {
        ...state,
      };
  }
};

export { homeReducer };
