import { ApiError, beablooApi, findDevelopmentId } from '../../utils';

const setOptions = () => {
  return async (dispatch, getState) => {
    const { currentDevelopment } = getState().general;
    const { developments } = getState();

    // used to inject the ID of the development
    // that a house Type belongs to so we can filter later
    const injectId = (data, id) => {
      return {
        ...data,
        belongsTo: id,
      };
    };

    const requestRequiredOptions = (currentDevelopment, developments) => {
      return new Promise(async (resolve, reject) => {
        try {
          let siteOptions = [];
          let regionOptions = [];
          let parentOptions = [];

          // find only parent houses
          if (currentDevelopment.type === 'signtouch-parent') {
            parentOptions = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.id}/options`,
            })
              .then((r) => r.data)
              .then((r) => r.map((r) => injectId(r, currentDevelopment.id)))
              .catch((e) => {
                throw new ApiError('failed to get parent options', []);
              });
          }

          // find parent && region houses
          if (currentDevelopment.type === 'signtouch-region') {
            parentOptions = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.parentId}/options`,
            })
              .then((r) => r.data)
              .then((r) =>
                r.map((r) => injectId(r, currentDevelopment.parentId)),
              )
              .catch((e) => {
                throw new ApiError('failed to get parent options', []);
              });
            regionOptions = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.id}/options`,
            })
              .then((r) => r.data)
              .then((r) => r.map((r) => injectId(r, currentDevelopment.id)))
              .catch((e) => {
                throw new ApiError('failed to get region options', []);
              });
          }

          // find parent && region && site houses
          if (currentDevelopment.type === 'signtouch-site') {
            // find the site's region so we can use it's parent Id to get the last
            // of the options available to us
            const regionDevelopment = developments.find(
              (development) => development.id === currentDevelopment.parentId,
            );
            parentOptions = await beablooApi({
              method: 'GET',
              route: `/developments/${regionDevelopment.parentId}/options`,
            })
              .then((r) => r.data)
              .then((r) =>
                r.map((r) => injectId(r, regionDevelopment.parentId)),
              )
              .catch((e) => {
                throw new ApiError('failed to get parent options', []);
              });
            regionOptions = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.parentId}/options`,
            })
              .then((r) => r.data)
              .then((r) =>
                r.map((r) => injectId(r, currentDevelopment.parentId)),
              )
              .catch((e) => {
                throw new ApiError('failed to get region options', []);
              });
            siteOptions = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.id}/options`,
            })
              .then((r) => r.data)
              .then((r) => r.map((r) => injectId(r, currentDevelopment.id)))
              .catch((e) => {
                throw new ApiError('failed to get site options', []);
              });
          }

          resolve([...siteOptions, ...regionOptions, ...parentOptions]);
        } catch (e) {
          reject(e);
        }
      });
    };

    dispatch({
      type: 'SET_OPTIONS',
      data: await requestRequiredOptions(currentDevelopment, developments),
    });
  };
};

const addOption = (data) => {
  return async (dispatch, getState) => {
    const { options } = getState();
    const { currentDevelopment } = getState().general;

    const repeated = options.find((option) => option.id === data.id);
    if (repeated) return;

    dispatch({
      type: 'ADD_OPTION',
      data: { ...data, belongsTo: currentDevelopment.id },
    });
  };
};

const updateOption = (data) => {
  return async (dispatch, getState) => {
    let { options } = getState();

    let option = options.find(
      (option) => String(option.id) === String(data.id),
    );
    const index = options.indexOf(option);

    options[index] = data;

    dispatch({
      type: 'SET_OPTIONS',
      data: options,
    });
  };
};

const delOption = (data) => {
  return async (dispatch, getState) => {
    let { options } = getState();

    let option = options.find(
      (option) => String(option.id) === String(data.id),
    );
    const index = options.indexOf(option);

    options.splice(index, 1);

    dispatch({
      type: 'SET_OPTIONS',
      data: options,
    });
  };
};

export { setOptions, addOption, updateOption, delOption };
