import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeCssColor } from '../../../../utils';
import Carousel from '../../components/Carousel';
import PlaceholderImage from '../../../../assets/img/placeholder.png';
import './styles.scss';

// Preview component for <SigntouchPreview />

const PreviewDesignAdvice = ({ name, images, description }) => {
  const { branding } = useSelector((state) => state.properties);

  return (
    <div className='preview-individual-house-type'>
      <div
        className='preview-individual-house-type-heading'
        style={{ background: makeCssColor(branding.section) }}
      >
        <h3>{name ? name : 'Property Name'}</h3>
      </div>
      <div className='preview-individual-house-images'>
        {images && images.length > 0 ? (
          <Carousel key={`slider-render-${images.length}`} images={images} />
        ) : (
          <Carousel images={[PlaceholderImage]} />
        )}
      </div>
      <div className='preview-individual-house-split'>
        <div className='preview-individual-house-split-left'>
          <div className='preview-individual-house-price'></div>
          <div className='preview-individual-house-about preview-text-padding'>
            <p
              dangerouslySetInnerHTML={{
                __html: description
                  ? description
                  : '<p>Content will go here.</p>',
              }}
            />
          </div>
        </div>
        <div className='preview-individual-house-split-right'></div>
      </div>
    </div>
  );
};

export default PreviewDesignAdvice;
