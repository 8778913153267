import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AddIcon, DeleteIcon, EditIcon } from '../Icons';
import './styles.scss';

const TreeItem = (props) => {
  const {
    children,
    title,
    lastChild,
    addIcon,
    addLink,
    addEvent,
    editIcon,
    editLink,
    editEvent,
    deleteIcon,
    deleteEvent,
    openOnStart,
  } = props;

  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  const collapseVariants = {
    open: {
      display: 'block',
    },
    close: {
      display: 'none',
    },
  };
  const arrowVariants = {
    open: {
      rotate: 90,
    },
    close: {
      rotate: 0,
    },
  };

  const handleDeleteIcon = () => {
    if (deleteEvent) deleteEvent();
  };

  const handleEditIcon = () => {
    if (editEvent) editEvent();
  };

  const handleAddIcon = () => {
    if (addEvent) addEvent();
  };

  // check to see if block should be open on mount
  useEffect(() => {
    if (openOnStart) {
      if (!open) {
        toggleOpen();
      }
    }
  }, []);

  return (
    <div
      className='tree-item'
      style={{ paddingLeft: lastChild ? '2rem' : '1rem' }}
    >
      <div className='header'>
        <Link to={editLink && lastChild ? editLink : `#`}>
          <div
            className='title'
            onClick={toggleOpen}
            style={{
              marginBottom:
                !lastChild && open && children.length > 0 ? '.5rem' : '0',
            }}
          >
            {/* add a drop down arrow */}
            {!lastChild && (
              <motion.svg
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                x='0px'
                y='0px'
                viewBox='0 0 492.004 492.004'
                space='preserve'
                variants={arrowVariants}
                initial='close'
                animate={open ? 'open' : 'close'}
                transition={{ duration: 0.2 }}
              >
                <path
                  fill='#121212'
                  d='M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12
                  c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028
                  c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265
                  c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z'
                />
              </motion.svg>
            )}
            {/* name */}
            <p>{title}</p>
          </div>
        </Link>
        <div className='icons'>
          {/* extra props */}
          {addIcon && (
            <div onClick={handleAddIcon}>
              <Link to={addLink ? addLink : '#'}>
                <AddIcon />
              </Link>
            </div>
          )}
          {editIcon && editLink && (
            <div onClick={handleEditIcon}>
              <Link to={editLink}>
                <EditIcon />
              </Link>
            </div>
          )}
          {deleteIcon && (
            <div onClick={handleDeleteIcon}>
              <DeleteIcon />
            </div>
          )}
        </div>
      </div>
      <motion.div
        className='tree-item-child'
        variants={collapseVariants}
        initial='close'
        animate={open ? 'open' : 'close'}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default TreeItem;
