import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import authenticationReducer from './reducers/authentication';
import developmentsReducer from './reducers/developments';
import generalReducer from './reducers/general';
import plotsReducer from './reducers/plots';
import houseTypesReducer from './reducers/houseTypes';
import brandingReducer from './reducers/branding';
import optionCategoriesReducer from './reducers/optionCategories';
import optionTypesReducer from './reducers/optionTypes';
import optionReducer from './reducers/options';
import galleriesReducer from './reducers/galleries';
import propertiesReducer from './reducers/properties';
import toastReducer from './reducers/toasts';
import UIreducer from './reducers/ui';
import { welcomeReducer } from './reducers/welcome';
import { homeReducer } from './reducers/home';
import { registerInterestReducer } from './reducers/register-intrest';
import { contactReducer } from './reducers/contact';
import { transportLinksReducer } from './reducers/transportLinks';
import { promotionsAndSalesMethodsReducer } from './reducers/promotions-and-sales';
import { localInformationReducer } from './reducers/localInformation';
import { designAdviceReducer } from './reducers/designAdvice';
import playerUIReducer from './reducers/playerUI';
import multiFloorReducer from './reducers/multiFloor';

const allReducers = combineReducers({
  authentication: authenticationReducer,
  general: generalReducer,
  branding: brandingReducer,
  developments: developmentsReducer,
  plots: plotsReducer,
  houseTypes: houseTypesReducer,
  optionCategories: optionCategoriesReducer,
  optionTypes: optionTypesReducer,
  options: optionReducer,
  galleries: galleriesReducer,
  properties: propertiesReducer,
  toasts: toastReducer,
  ui: UIreducer,
  welcome: welcomeReducer,
  home: homeReducer,
  contact: contactReducer,
  registerInterest: registerInterestReducer,
  transportLinks: transportLinksReducer,
  promotions: promotionsAndSalesMethodsReducer,
  localInformation: localInformationReducer,
  designAdvice: designAdviceReducer,
  playerUI: playerUIReducer,
  multiFloor: multiFloorReducer,
});

const store =
  process.env.NODE_ENV === 'production'
    ? compose(applyMiddleware(thunk))(createStore)(allReducers)
    : compose(
        applyMiddleware(thunk),
        typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'undefined'
          ? (a) => a
          : window.__REDUX_DEVTOOLS_EXTENSION__ &&
              window.__REDUX_DEVTOOLS_EXTENSION__(),
      )(createStore)(allReducers);

export default store;
