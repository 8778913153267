import { Box, TextField } from '@mui/material';
import axios from 'axios';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useEffect, useState } from 'react';
import { Result } from './Result';


export const SearchBar = ({ mapRef }) => {

  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [inputFocused, setInputFocused] = useState(false);

  const handleToggleInputFocus = () => setInputFocused(!inputFocused);

  const handleSearchChange = event => {
    const { value } = event.target;
    setQuery(value);
  };

  const handleUpdateResults = results => {
    setResults(results);
  };

  const queryMapboxSearch = async queryString => {
    const response = await axios({
      method: 'GET',
      url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${queryString}.json/?access_token=${mapboxgl.accessToken}&country=gb`,
    });

    return response.data;
  };

  const handleQuery = async () => {
    let response;
    try {
      response = await queryMapboxSearch(query);
    } catch {

    }
    handleUpdateResults(response.features);
  };

  useEffect(() => {
    if (query.length > 1) {
      handleQuery(query);
    }
    if (query.length === 0) {
      handleUpdateResults([]);
    }
  }, [query]);

  const handleResultClick = result => {
    mapRef.current.flyTo({
      center: result.center,
      zoom: 14,
    });
  };

  return (
    <Box sx={{
      position: 'absolute',
      top: 0,
      right: 0,
      p: 2,
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end'
    }}>
      <TextField
        onChange={handleSearchChange}
        value={query}
        onFocus={handleToggleInputFocus}
        onBlur={() => setTimeout(handleToggleInputFocus, 400)}
        sx={{
          overflow: 'hidden',
          width: 200,
          bgcolor: 'background.paper',
        }}
      />

      {inputFocused && results.map(result => (
        <Result result={result} onClick={() => handleResultClick(result)} />
      ))}
    </Box>
  )
};
