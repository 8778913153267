import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HouseCard } from '../../../components';

export const RegionView = () => {
  // get the current development for filtering
  const { currentDevelopment } = useSelector((state) => state.general);
  const developments = useSelector((state) => state.developments);
  const houseTypes = useSelector((state) => state.houseTypes);

  const [parentHouses, setParentHouses] = useState([]);
  const [regionHouses, setRegionHouses] = useState([]);

  // on mount filter each houseType
  // to have parent and region
  useEffect(() => {
    console.log('RUN FUNC');

    setParentHouses(() => {
      return houseTypes.filter(
        (house) => house.belongsTo === currentDevelopment.parentId,
      );
    });

    setRegionHouses(() => {
      return houseTypes.filter(
        (house) => house.belongsTo === currentDevelopment.id,
      );
    });
  }, [houseTypes]);

  return (
    <>
      <div className='house-type-grid-title'>
        <h3>Created By Head Office &#40;uneditable&#41;</h3>
      </div>
      <div className='house-types-grid'>
        {parentHouses.map((house) => (
          <HouseCard {...house} key={house.id} />
        ))}
      </div>

      <div className='house-type-grid-title'>
        <h3>Created By {currentDevelopment.name}</h3>
      </div>
      <div className='house-types-grid'>
        {regionHouses.map((house) => (
          <HouseCard {...house} key={house.id} />
        ))}
      </div>
    </>
  );
};
