import { ApiError, beablooApi } from '../../utils';

const updateTransportLinks = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'SET_TRANSPORT',
      data: data,
    });
  };
};

const setTransportLinks = () => {
  return async (dispatch, getState) => {
    const { general } = getState();

    const response = await beablooApi({
      method: 'GET',
      route: `/developments/${general.currentDevelopment.id}/transportLinks`,
    })
      .then((r) => r.data)
      .catch((e) => {
        throw new ApiError('failed to get transport links', []);
      });

    dispatch({
      type: 'SET_TRANSPORT',
      data: response,
    });
  };
};

export { setTransportLinks, updateTransportLinks };
