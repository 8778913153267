import { styled } from 'styled-components'

const Grid = styled.div({
  display: 'grid',
  gap: '1rem',
  maxWidth: '1920px',
  gridTemplateColumns: 'repeat(4, 1fr)',
  '@media (max-width: 1675px)': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@media (max-width: 1400px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@media (max-width: 900px)': {
    gridTemplateColumns: '1fr',
  }
});

export const PageGrid = ({ children }) => {
  return (
    <Grid>
      {children}
    </Grid>
  )
}