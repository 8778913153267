import { Link } from 'react-router-dom';
import './styles.scss';
import houseTypeImage from '../../assets/img/temp/signtouch-logo.svg';
import { useSelector } from 'react-redux';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as BedIcon } from '../../assets/icons/bed.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { DeleteModal } from '../';
import { useState } from 'react';

const HouseCard = (props) => {
  // keep track of request status (delete)
  const [isPendingRequest, setIsPendingRequest] = useState(false);
  const toggleIsPendingRequest = () =>
    setIsPendingRequest((state) => {
      return !state;
    });

  const currentDevelopment = useSelector(
    (state) => state.general.currentDevelopment,
  );
  let plots = useSelector((state) => state.plots);
  plots = plots.filter((plot) => `${plot.houseType}` === `${props.id}`);

  const getAvailability = () => {
    let status = 'non available';
    for (let p = 0; p < plots.length; p++) {
      const plot = plots[p];
      if (plot.status === 'available') {
        status = 'available';
        return status;
      }
    }
    return status;
  };

  const isEditable = props.belongsTo === currentDevelopment.id;

  return (
    <>
      <div className='house-card' style={{ opacity: isEditable ? 1 : 0.6 }}>
        <Link
          to={
            isEditable
              ? `/developments/${currentDevelopment.id}/houseTypes/${props.id}`
              : '#'
          }
        >
          <div
            className='house-card-image'
            style={{
              backgroundImage: `url(https://images.weserv.nl/?url=${
                props.thumbnail ? props.thumbnail.url : houseTypeImage
              }&w=200&h=200&sharp=3)`,
            }}
          />
          <div className='house-card-about'>
            <h3>{props.name}</h3>
            <div className='house-card-info'>
              <BedIcon />
              <div className='house-name-bedrooms'>
                <p>{props.details ? props.details.bedrooms : 0} Bedrooms</p>
              </div>
            </div>
          </div>
          <p className='availability'>{getAvailability()}</p>
        </Link>

        <div className='actions'>
          {isEditable && (
            <div onClick={toggleIsPendingRequest}>
              <DeleteIcon className='delete' />
            </div>
          )}

          <div>
            <Link
              to={`/developments/${currentDevelopment.id}/houseTypes/${props.id}/copy`}
            >
              <CopyIcon className='copy' />
            </Link>
          </div>
        </div>
      </div>

      {isPendingRequest && (
        <DeleteModal
          type='house type'
          objectName='House Type'
          house={props}
          closeModal={toggleIsPendingRequest}
        />
      )}
    </>
  );
};

export default HouseCard;
