const galleriesReducer = (state = [], action) => {
  switch (action.type) {
    case 'SET_GALLERIES':
      return [...action.data];

    default:
      return [...state];
  }
};

export default galleriesReducer;
