import { Button, Heading, Page, SubHeading } from '../../components';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {  addNotification } from '../../utils';
import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { MultiFloorImageSection } from './MultiFloorImageSection';
import { setMultiFloor } from '../../store/actions/multiFloor'
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { multiFloorService } from '../../lib/signtouch';
import { SortFloors } from './NewSortFloor';


export const MultiFloorConfigurationPage = () => {
  const dispatch = useDispatch();
  const multiFloor = useSelector(state => state.multiFloor);
  const [floors, setFloors] = useState(multiFloor.floors || []);
  const [multiFloorImage, setMultiFloorImage] = useState(null);
  const { developmentId } = useParams();
  
  const [saving, setSaving] = useState(false);
  const handleFloorsChange = values => {
    setFloors(values);
  }

  const onDeleteFloor = id => {
    const newFloors = floors.filter(floor => floor.id !== id);
    setFloors(newFloors.map((floor, index) => ({ ...floor, order: index + 1 })));
  };

  const handleUpdateMultiFloor = async () => {
    const pendingToast = addPending('updating multi floor...');
    setSaving(true); 

    const payload = { floors };

    if (multiFloorImage) payload.developmentImage = multiFloorImage;

    try {
      await multiFloorService.updateMultiFloor(payload);
      addNotification('multi floor updated');
      dispatch(setMultiFloor(payload));
    } catch {
      throw new Error('unable to update multi floor')
    }

    setSaving(false);
    removePending(pendingToast);
  };

  const handleDevelopmentImageChange = values => {
    setMultiFloorImage(values.developmentImage);
  };

  return (
    <Page>
      <div className='heading-with-button'>
        <Heading label='Multi Floor Configuration' />
        <Link to={`/developments/${developmentId}/multi-floor/floors/create`}>
          <Button label={'create floor'} />
        </Link>
      </div>
      <SubHeading>
        Here you can manage the floors and development overview of your site.
      </SubHeading>
      <Box sx={{ maxWidth: '1600px', width: '100%' }}>
        <Stack direction={'column'} alignItems='stretch' spacing={{ xs: 1, sm: 2 }}>

          <SortFloors
            floors={floors}
            handleFloorsChange={handleFloorsChange}
            onDeleteFloor={onDeleteFloor}
          />

          <Box flexGrow={1}>
            <MultiFloorImageSection
              onChange={handleDevelopmentImageChange}
            />
          </Box>
        </Stack>
      </Box>
      <Button
        label={saving ? 'saving...' : 'Update Multi Floor'}
        onClick={handleUpdateMultiFloor}
        disabled={saving}
      />
    </Page>
  );
};
