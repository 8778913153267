import { Accordion, AccordionDetails, AccordionSummary, Box, Modal, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useQuery, useYupResolver } from '../../../../hooks';
import { featureSchema } from './feature.schema';
import { Button, CoordinateSelect, Label, Select, TextInput, Wysiwyg } from '../../../../components/FormV2/components';
import { ICON_OPTIONS } from './constants';
import { ExpandMore } from '@mui/icons-material';
import { localInformationService } from '../../../../lib/signtouch';
import addPending from '../../../../utils/addPending';
import removePending from '../../../../utils/removePending';
import { addError, addNotification } from '../../../../utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLocalInformation } from '../../../../store/actions/localInformation';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  maxHeight: '70vh',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};
export const FeatureModal = ({ open }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const query = useQuery();
  const informationOrigin = query.get('informationOrigin');
  const selectedFeature = `${decodeURIComponent(query.get('selectedFeature'))}`;
  const isNewFeature = selectedFeature === 'null';
  const localInformation = useSelector(state => state.localInformation);
  const information = localInformation.content.find(info => `${info.id}` === `${informationOrigin}`);
  const feature = information.features.find(feature => `${feature.properties.title}` === `${selectedFeature}`);

  const resolver = useYupResolver(featureSchema);
  const { control, reset, handleSubmit, formState: { errors } } = useForm({
    resolver,
  });

  const handleClose = () => {
    reset();
    navigate(pathname)
  };

  const onSubmit = async data => {
    const featureExists = information.features.find(feature => feature.properties.title.toLowerCase() === data.name.toLowerCase());
    if (featureExists && isNewFeature) {
      addError('A feature with the same name already exists');
      return;
    }

    const pendingToast = addPending(feature ? 'updating feature' : 'creating feature');
    const { name, type, className, description, coordinates, car, bus, rail, walk } = data; 

    let payload;
    if (feature) {
      payload = {
        ...feature,
        properties: {
          ...feature.properties,
          title: name,
          description,
          type,
          className,
          distance: {
            car,
            bus,
            rail,
            walk,
          },
        },
        geometry: {
          ...feature.geometry,
          coordinates,
        }
      }
    } else {
      payload = {
        type: 'Feature',
        properties: {
          title: name,
          description,
          type,
          className,
          distance: {
            car,
            bus,
            rail,
            walk,
          },
          openingTimes: [],
          optServices: [],
        },
        geometry: {
          coordinates,
          type: 'Point',
        }
      }
    }

    const request = feature
      ? await localInformationService.updateLocalInformationFeature(payload, informationOrigin, selectedFeature)
      : await localInformationService.createLocalInformationFeature(payload, informationOrigin);

    removePending(pendingToast);

    if (request) {
      await dispatch(setLocalInformation())
      addNotification(feature ? 'feature updated successfully' : 'Feature created successfully');
      handleClose();
    }
  }

  return (
    <Modal disablePortal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {isNewFeature ? 'Create a new feature' : 'Update an existing feature'}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1, mb: 2 }}>
          Plot a new feature on the local information map
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} style={{ margin: '10px 0' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
              <TextInput
                name='name'
                label='NAME'
                control={control}
                defaultValue={feature?.properties?.title}
                full
              />

              <TextInput
                name='type'
                label='TYPE'
                control={control}
                defaultValue={feature?.properties?.type}
                full
              />
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
              <Select
                name='className'
                label='ICON'
                control={control}
                options={ICON_OPTIONS}
                defaultValue={feature?.properties?.className}
                full
              />
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: 2 }}>
              <CoordinateSelect
                name='coordinates'
                control={control}
                defaultValue={feature?.geometry?.coordinates}
              />
            </Box>

            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: 2 }}>
              <Wysiwyg
                name='description'
                label='DESCRIPTION'
                control={control}
                defaultValue={feature?.properties?.description}
              />
            </Box>

            <Accordion sx={{ boxShadow: 'none' }}>
              <AccordionSummary expandIcon={<ExpandMore />}
                sx={{
                  p: 0,
                  background: 'none',
                }}
              >
                <Label>DISTANCE (OPTIONAL)</Label>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Typography>
                    Enter the distance from the feature to your development in minuets
                  </Typography>

                  <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                    <TextInput
                      name='car'
                      label='CAR'
                      control={control}
                      defaultValue={feature?.properties?.distance?.car}
                      full
                    />
                    <TextInput
                      name='rail'
                      label='RAIL'
                      control={control}
                      defaultValue={feature?.properties?.distance?.rail}
                      full
                    />
                  </Box>

                  <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                    <TextInput
                      name='walk'
                      label='WALK'
                      control={control}
                      defaultValue={feature?.properties?.distance?.walk}
                      full
                    />
                    <TextInput
                      name='bus'
                      label='BUS'
                      control={control}
                      defaultValue={feature?.properties?.distance?.bus}
                      full
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Button type='submit'>Submit</Button>
          </Box>

        </form>
      </Box>
    </Modal>
  )
};
