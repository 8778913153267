import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeCssColor } from '../../../../utils';
import Carousel from '../../components/Carousel';
import PlaceholderImage from '../../../../assets/img/placeholder.png';
import RegisterInterestImage from '../../../../assets/img/register-interest.jpg';
import ContactUsImage from '../../../../assets/img/contact-us.jpg';
import { ContentBlock, SideCard } from '../../components';
import './styles.scss';

// Preview component for <SigntouchPreview />
// takes in everything from the HouseType Model
// https://signtouch-api-docs.prodigitalsignage.co.uk/models/housetype/

const PreviewPromotionAndSalesMethod = ({
  name,
  thumbnail,
  images,
  description,
}) => {
  const { currentDevelopment } = useSelector((state) => state.general);

  const { branding } = useSelector((state) => state.properties);

  // update whenever anything changes
  useEffect(() => {}, [name, thumbnail, images, description]);

  return (
    <div className='preview-promotions-index'>
      <div
        className='heading'
        style={{ background: makeCssColor(branding.section) }}
      >
        <h3>
          {currentDevelopment.name}
          <br />
          {name ? name : 'Promotion Name'}
        </h3>
      </div>
      <div className='preview-individual-house-images'>
        {images && images.length > 0 ? (
          <Carousel key={`slider-render-${images.length}`} images={images} />
        ) : (
          <Carousel images={[PlaceholderImage]} />
        )}
      </div>
      <div className={'preview-promotions-split'}>
        <div className='preview-promotions-content'>
          <ContentBlock html={description} />
        </div>
        <div className='preview-promotions-side-cards'>
          <SideCard label='register interest' image={RegisterInterestImage} />
          <SideCard label='Contact Us' image={ContactUsImage} />
        </div>
      </div>
    </div>
  );
};

export default PreviewPromotionAndSalesMethod;
