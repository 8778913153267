import { Box } from '@mui/material'
import { Form } from '../../components'
import { useSelector } from 'react-redux';

export const MultiFloorImageSection = ({ onChange }) => {
  const { developmentImage } = useSelector(state => state.multiFloor);
  const handleChange = values => {
    onChange(values);
  }

  return (
    <Box height='100%' width='100%'>
      <Form
        inputs={[
          {
            name: 'developmentImage',
            label: 'Development Image',
            type: 'single-image',
            initialValue: developmentImage,
          }
        ]}
        onChange={handleChange}
      />
    </Box>
  )
}