import store from '../../store';
import { beablooApi } from '../../utils';

const createLocalInformation = async payload => {
  const { general: { currentDevelopment } } = store.getState();

  const response = await beablooApi({
    method: 'POST',
    route: `/developments/${currentDevelopment.id}/localInformation/create`,
    payload,
  });

  if (!response.success) {
    throw new Error('unable to create local information')
  }

  return response.data;
}

const updateLocalInformation = async (payload, informationEntryId) => {
  const { general: { currentDevelopment } } = store.getState();

  const response = await beablooApi({
    method: 'PUT',
    route: `/developments/${currentDevelopment.id}/localInformation/${informationEntryId}/update`,
    payload,
  });

  if (!response.success) {
    throw new Error('unable to update local information')
  }

  return response.data;
}

const deleteLocalInformation = async id => {
  const { general: { currentDevelopment } } = store.getState();

  const response = await beablooApi({
    method: 'DELETE',
    route: `/developments/${currentDevelopment.id}/localInformation/${id}/delete`,
  });

  if (!response.success) {
    throw new Error('unable to delete local information')
  }

  return response.data;
};

const createLocalInformationFeature = async (feature, informationEntryId) => {
  const { localInformation, general: { currentDevelopment } } = store.getState();

  const existingEntry = localInformation.content.find(i => `${i.id}` === `${informationEntryId}`);
  const payload = {
    ...existingEntry,
    features: [
      ...existingEntry.features,
      feature
    ]
  };

  const response = await beablooApi({
    method: 'PUT',
    route: `/developments/${currentDevelopment.id}/localInformation/${informationEntryId}/update`,
    payload,
  });

  if (!response.success) {
    throw new Error('unable to update local information')
  }

  return response.data;
};

const updateLocalInformationFeature = async (feature, informationEntryId, oldTitle) => {
  const { localInformation, general: { currentDevelopment } } = store.getState();

  const existingEntry = localInformation.content.find(i => `${i.id}` === `${informationEntryId}`);
  const payload = {
    ...existingEntry,
    features: [
      ...existingEntry.features.filter(f => `${f.properties.title}` !== oldTitle),
      feature
    ]
  };

  const response = await beablooApi({
    method: 'PUT',
    route: `/developments/${currentDevelopment.id}/localInformation/${informationEntryId}/update`,
    payload,
  });

  if (!response.success) {
    throw new Error('unable to update local information')
  }

  return response.data;
}

const deleteLocalInformationFeature = async (title, informationEntryId) => {
  const { localInformation, general: { currentDevelopment } } = store.getState();

  const existingEntry = localInformation.content.find(i => `${i.id}` === `${informationEntryId}`);
  const payload = {
    ...existingEntry,
    features: [
      ...existingEntry.features.filter(f => `${f.properties.title}` !== title),
    ]
  };

  const response = await beablooApi({
    method: 'PUT',
    route: `/developments/${currentDevelopment.id}/localInformation/${informationEntryId}/update`,
    payload,
  });

  if (!response.success) {
    throw new Error('unable to update local information')
  }

  return response.data;
}

const localInformationService = {
  createLocalInformation,
  updateLocalInformation,
  deleteLocalInformation,
  createLocalInformationFeature,
  updateLocalInformationFeature,
  deleteLocalInformationFeature,
}

export default localInformationService;
