const Login = (data) => {
  return {
    type: 'LOGIN',
    data: data,
  };
};

const Logout = () => {
  return {
    type: 'LOGOUT',
  };
};

const Expired = () => {
  return {
    type: 'EXPIRED',
  };
};

export { Login, Logout, Expired };
