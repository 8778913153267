import { useEffect, useState } from 'react';
import ParentResult from './ParentResult';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';

// component for searching for developments
// grabs all the developments from state and
// then goes though a couple of simple if statements
// to find matches closes to the input query
// prop 'options' must be an array
const Search = ({ options, closeMenu }) => {
  const [results, setResults] = useState([]);

  // take the value of the input element
  // and test it to see if there are any
  // close matches to what the user has typed
  const handleSearch = (e) => {
    const { value } = e.target;

    // don't do anything if wrong data type
    if (!Array.isArray(options)) {
      return;
    }

    // create a place to store any matches
    let queryResults = [];

    // test each parents children to see if there
    // is a match
    for (let i = 0; i < options.length; i++) {
      const parent = options[i];

      // filter the children of the parent
      // and pull out any that match
      const matches = parent.options.filter((o) =>
        o.label.toLowerCase().includes(value),
      );

      // if we got some matches add them
      // to the queryResults array to return later
      if (matches.length > 0) {
        queryResults.push({
          ...parent,
          options: [...matches],
        });
      }

      // in this case user is searching for a parent
      else if (parent.label.toLowerCase().includes(value)) {
        queryResults.push({
          ...parent,
        });
      }
    }

    // no query string (value) so return everything
    if (queryResults.length < 0) {
      return [...options];
    }

    // finally set results to state to be rendered
    setResults(queryResults);
  };

  useEffect(() => {
    // on initalise give results all options
    setResults([...options]);

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className='development-selector-close'>
        <CloseIcon onClick={closeMenu} />
      </div>
      <div className='development-selector-search'>
        <input
          type='text'
          onChange={handleSearch}
          placeholder='Search for a Development'
        />
      </div>
      <div className='development-selector-instructions'>
        <p>
          Use this tab to quickly search for and navigate between developments
        </p>
      </div>
      <div className='development-selector-results'>
        {results &&
          results.map((result) => {
            return (
              <ParentResult {...result} key={`search-result-${result.label}`} />
            );
          })}
      </div>
    </>
  );
};

export default Search;
