import { useState, useRef, useEffect } from 'react';
import { formatCurrency } from '../../../../utils';

const MoneyInput = ({
  onChange,
  name,
  label,
  validation,
  initialValue,
  error,
}) => {
  const input = useRef(null);

  const [price, setPrice] = useState({ value: '', error: false });

  const handleChange = (e) => {
    let { value } = e.target;
    value = parseFloat(value);

    setPrice({ value: value, error: false });
  };

  // when the input element blur event is fired
  // format the content for the input to "currency"
  const formatInputCurrency = () => {
    input.current.value = formatCurrency(
      input.current.value
        .replace(',', '')
        .replace(/[a-zA-Z]/, '')
        .replace('£', ''),
    ).replace('£', '');

    // user is done editing send the data back to the Form
    if (onChange) {
      onChange({ name: name, value: { amount: price.value, currency: 'GBP' } });
    }
  };
  // when the input element is selected revert the number
  // to a Float so for ease of editing
  const revertToInt = () => {
    // remove comma and pound signs from input value
    input.current.value = input.current.value
      .replace(',', '')
      .replace(/[a-zA-Z]/, '')
      .replace('£', '');
  };

  // check to see if there is an initial value to set
  useEffect(() => {
    if (initialValue) {
      input.current.value = formatCurrency(initialValue.amount);
    }
  }, []);

  return (
    <div className={`form-input-text ${error ? 'form-input-text-error' : ''}`}>
      <label>
        {label ? label : 'no label provided'}

        <div className={error ? 'price-input error' : 'price-input'}>
          <input
            type='text'
            placeholder='0'
            onChange={handleChange}
            onBlur={formatInputCurrency}
            onFocus={revertToInt}
            ref={input}
          />
        </div>
      </label>

      {/* { validation &&
        <span>{ price.value.length }/{ validation.length }</span>
      } */}
    </div>
  );
};

export default MoneyInput;
