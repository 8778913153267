import { useParams } from 'react-router';
import { Heading, Page, SubHeading } from '../../components';
import { IntegrationCard } from './IntergrationCard';
import { config } from './config';
import { addError, beablooApi } from '../../utils';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';


export const Integrations = () => {
  const { developmentId } = useParams();
  const [loading, setLoading] = useState(true);
  const [integrations, setIntegrations] = useState({ integrations: config });
  const [error, setError] = useState(false);

  const handleLoad = async () => {
    setLoading(true);
    try {
      const response = await beablooApi({
        method: 'GET',
        route: `/developments/${developmentId}/integrations`,
      });

      setIntegrations({
        integrations: {
          ...config,
          ...response.data.integrations,
        },
      });
      setLoading(false);
    } catch (e) {

      addError('There was an issue fetching your integrations. Please try again later');
      setError(true);
      return;
    }
  };

  useEffect(() => {
    handleLoad();
  }, []);

  if (error) {
    return (
      <Navigate to={`/developments/${developmentId}`} />
    )
  }

  // map each key of intergrations to an array
  const keys = Object.keys(integrations.integrations);
  const integration = keys.map(key => integrations.integrations[key]);

  return (
    <Page>
      <Heading label='Manage Integrations' />
      <SubHeading>
        Manage integrations for your Signtouch development.
      </SubHeading>

      {loading && (
        <p>fetching your integrations...</p>
      )}

      {!loading && (
        <>
          {integration.map(({ name, enabled }) => (
            <IntegrationCard 
              key={`${name}`}
              name={name}
              enabled={enabled}
            />
          ))}
        </>
      )}
    </Page>
  )
};