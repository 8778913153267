const FinishingUp = (props) => {
  return (
    <div className='center justify-top'>
      <h1>Creating Development</h1>
      <div className='loading-container'>
        <div className='sk-chase'>
          <div className='sk-chase-dot' />
          <div className='sk-chase-dot' />
          <div className='sk-chase-dot' />
          <div className='sk-chase-dot' />
          <div className='sk-chase-dot' />
          <div className='sk-chase-dot' />
        </div>
      </div>
      <p>We're just creating your development, this may take a few moments.</p>
    </div>
  );
};

export default FinishingUp;
