import store from '../../store';
import { beablooApi } from '../../utils';

const updateMultiFloor = async (payload) => {
  const { general: { currentDevelopment } } = store.getState();

  const response = await beablooApi({
    method: 'PUT',
    route: `/developments/${currentDevelopment.id}/multiFloor//update`,
    payload: {
      ...payload,
      floors: payload.floors.map(floor => ({
        ...floor,
        backgroundColor: floor.backgroundColor || '#FFFFFF'
      }))
    }
  });

  if (!response.success) {
    throw new Error('unable to update plot')
  }

  return response.data;
};

const multiFloorService = {
  updateMultiFloor,
};

export default multiFloorService;
