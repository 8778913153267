import { Typography } from '@mui/material';

export const Label = ({ children }) => {
  return (
    <Typography
      sx={{
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 'bold',
        fontSize: '0.9rem',
        color: '#121212',
      }}
    >
      {children}
    </Typography>
  );
};
