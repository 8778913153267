import { styled } from 'styled-components';
import { ReactComponent as LocateIcon } from '../../../assets/icons/locate.svg';

const PlotItemContainer = styled.div({
  padding: '0.75em 2em',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#1e1e1e',
  },
  cursor: 'pointer',
});

const PlotLabel = styled.p({
  margin: 0,
  color: '#fff',
  textTransform: 'uppercase',
  fontSize: '16px',
  fontWeight: 'bold',
})

export const PlotItem = ({ plot, onClick }) => {
  return (
    <PlotItemContainer>
      <PlotLabel>plot {plot.number}</PlotLabel>
      <LocateIcon
        onClick={onClick}
        style={{ height: '24px', width: '24px', color: 'white', cursor: 'pointer' }}
      />
    </PlotItemContainer>
  )
};
