import axios from 'axios';
import store from '../store';
import { Logout } from '../store/actions/authentication';
const beablooApi = async ({ method, route, payload = false }) => {
  const state = store.getState().authentication;

  const headers = {
    'Content-Type': 'application/json',
  };
  if (state.loggedin) headers['authorization'] = `Bearer ${state.token}`;

  return await axios({
    method: method,
    headers: headers,
    url: `${process.env.REACT_APP_API_URL}${route}`,
    data: payload ? payload : {},
    timeout: 1200000,
  })
    .then((r) => {
      return r.data;
    })
    .catch((error) => {
      if (!error.response) {
        return {
          statusCode: 500,
          message: 'An unknwon error',
          status: '',
          errors: [],
        };
      }

      if (error.response.data.message === 'Bearer token has expired') {
        store.dispatch(Logout());
      }

      return {
        statusCode: error.response.status,
        message: error.response.data.message,
        status: error.response.data.success,
        errors: error.response.data.errors ? error.response.data.errors : [],
      };
    });
};

export default beablooApi;
