import { useState, useRef, useEffect } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { ReactComponent as CenterIcon } from '../../../../assets/img/centre-me.svg';

import noLocation from '../../../../assets/img/no-location.png';

import './style.scss';
import axios from 'axios';
import { addError } from '../../../../utils';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

const LocationSelector = (props) => {
  const checkWebGl = () => {
    try {
      let canvas = document.createElement('canvas');
      return (
        !!window.WebGLRenderingContext &&
        (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))
      );
    } catch (e) {
      return false;
    }
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  // Used to keep track of what the user sees and the final result.
  const [webGl, setWebGl] = useState('uncheck');
  const [location, setLocation] = useState(null);
  const [isOpen, setOpen] = useState(false);
  // Used to keep track of what we're searching for and the results from mapbox.
  const [searchValue, setSearchValue] = useState('');
  const [results, setResults] = useState([]);

  // This is for mapbox, used to keep track of ze dats
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(9);

  // This checks for a default location and sets it for the map and display
  useEffect(() => {
    if (
      props.hasOwnProperty('initialValue') &&
      Array.isArray(props.initialValue) &&
      props.initialValue.length > 1
    ) {
      setLocation(props.initialValue);
      setLng(props.initialValue[0]);
      setLat(props.initialValue[1]);
    }
  }, []);

  // Checks the map and loads it using the correct style
  useEffect(() => {
    if (webGl === 'uncheck') {
      setWebGl(checkWebGl() ? 'available' : 'unavailable');
    }

    if (webGl === 'unavailable') return;

    // If the mapContainer can't be found, skip it all as it would result in an error.
    if (!mapContainer.current) return;
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11', // This is the style we're loading from mapbox.
      center: [lng, lat],
      zoom: zoom,
    });
  });

  // Event for handling clicking on a search result
  const handleClick = (location) => {
    if (!map.current) return; // Check we have a map to interact with.
    // Take us to the location of the result
    map.current.flyTo({
      center: location,
      zoom: zoom,
    });
  };

  // USed to reload the map when the container is unloaded and reloaded.
  const handleReady = () => {
    // This is basically the same as above but used when the component is loaded and needs a new map loaded.
    // Don't keep the map loaded as it's not memory light.
    if (!mapContainer.current) return;
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom,
    });
  };

  // Updates the location of the map every state update
  useEffect(() => {
    if (!map.current) return;
    if (map.current === null) return;
    map.current.on('move', () => {
      if (!map.current) return;
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  });

  // Used for searching and adding the result to state.
  useEffect(() => {
    // If the searchValue is smaller than 1 skip, no matches are likly to be useful
    if (searchValue.length > 1) {
      // Request the results from mapbox.
      axios({
        url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchValue}.json/?access_token=${mapboxgl.accessToken}&country=gb`,
      })
        .then((result) => {
          // Check the data has results
          if (result.data.hasOwnProperty('features')) {
            setResults(result.data.features);
          }
        })
        .catch((error) => {
          if (error) {
            addError('An error occurred when searching');
          }
        });
    }
  }, [searchValue]);

  // This function opens the overlay for choosing a location
  const openSelector = () => {
    if (!checkWebGl()) {
      addError(
        'Unable to open the map editor. WebGL is not supported or disabled in your browser.',
      );
      handleClose();
      return;
    }
    setOpen(!isOpen);
  };

  // Used when the user clicks save
  const saveLocation = (e) => {
    // Store the final location in geoJSON from (y, x)
    setLocation([lng, lat]);
    map.current = null;
    // TODO: Tell the form component we have a location.
    setOpen(false);
    if (props.onChange) {
      props.onChange({ name: props.name ? props.name : '', value: [lng, lat] });
    }
  };

  const handleClose = () => {
    map.current = null;
    setOpen(false);
  };

  return (
    <div className={'location-selctor'} onKeyPress={handleEnter}>
      <label>Location</label>
      <div style={{ display: 'flex', flexDirection: 'column', width: '500px' }}>
        {location ? (
          <img
            src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${location[0]},${location[1]},12.25,0,0/600x200?access_token=pk.eyJ1IjoidGltaWtpbnMiLCJhIjoiY2tyeXgxaTIzMTRjdjJxbnFxcTNjc2h4YyJ9.KCc6_H3rVjvJi9zvi7Karw`}
            alt={'A preview of the location'}
          />
        ) : (
          <img src={noLocation} alt={'A preview of the location'} />
        )}
        <button
          onClick={openSelector}
          className={'button button-primary button-full-width'}
        >
          {location ? 'Change' : 'Select'} Location
        </button>
      </div>

      {isOpen ? (
        <div className={'select-box'}>
          <div className={'map-alignment'}>
            <div className={'search'}>
              <input
                type={'text'}
                placeholder={'Search'}
                value={searchValue}
                onKeyPress={handleEnter}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              {results.length > 0 ? (
                <div className='searchResults'>
                  {results.map(({ place_name, center }) => {
                    return (
                      <div
                        className={'result'}
                        onClick={(e) => handleClick(center)}
                      >
                        <p>{place_name}</p>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
            <span className={'center'}>
              <CenterIcon />
            </span>
            <div
              onChange={handleReady}
              ref={mapContainer}
              className='map-container'
            />
          </div>
          <div className={'location-controls'}>
            <button
              onClick={saveLocation}
              className={'button button-primary button-table'}
            >
              Save
            </button>
            <button
              onClick={handleClose}
              className={'button button-red button-table'}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default LocationSelector;
