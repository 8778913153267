import schema from '../schemas/_authentication';

const authenticationReducer = (state = schema, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('signtouch-authentication', action.data);
      return {
        loggedin: true,
        expired: false,
        token: action.data,
      };

    case 'LOGOUT':
      localStorage.removeItem('signtouch-authentication');
      return {
        loggedin: false,
        token: false,
        expired: false,
      };

    case 'EXPIRED':
      localStorage.removeItem('signtouch-authentication');
      return {
        loggedin: false,
        token: false,
        expired: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export default authenticationReducer;
