export const ICON_OPTIONS = [
  { label: 'Medical', value: 'medical' },
  { label: 'Council', value: 'council' },
  { label: 'Food & Drink', value: 'food' },
  { label: 'School', value: 'school' },
  { label: 'Emergency', value: 'emergency' },
  { label: 'Shopping', value: 'shopping' },
  { label: 'Nightlife', value: 'night' },
  { label: 'Theatre', value: 'theatre' },
  { label: 'Arts', value: 'arts' },
];

