const transportLinksSchema = {
  features: [],
  road: {
    enabled: false,
  },
  rail: {
    enabled: false,
  },
  bus: {
    enabled: false,
  },
  airport: {
    enabled: false,
  },
  ferry: {
    enabled: false,
  },
};

export default transportLinksSchema;
