import * as yup from 'yup';
import { isMediaObject } from '../../utils';

export const homePageSchema = yup.object({
  video: yup.mixed()
    .test('is-media-object', 'must be valid media', value => isMediaObject(value))
    .required('video is required'),
  image1: yup.mixed()
    .test('is-media-object', 'must be valid media', value => isMediaObject(value))
    .required('image-1 is required'),
  image2: yup.mixed()
    .test('is-media-object', 'must be valid media', value => isMediaObject(value))
    .required('image-2 is required'),
});
