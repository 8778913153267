import { Box, Typography } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { useController } from 'react-hook-form';
import { Label } from '../Label';
import { HelperText } from '../HelperText';
import { useClickOutside } from '../../../../hooks';

export const ColorPicker = ({ name, control, defaultValue = '#ffffff', label }) => {
  const { 
    field: { onChange, value },
    fieldState: { error }
  } = useController({ name, control, defaultValue });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }} >
      <Label>{label}</Label>
      <PopoverPicker color={value} onChange={onChange} />
      {error && (
        <HelperText message={error?.message} />
      )}
    </Box>
  )
};

export const PopoverPicker = ({ color, onChange }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Box
          sx={{
            width: '3rem',
            height: '3rem',
            borderRadius: '3px',
            cursor: 'pointer',
            border: '1px solid black',
          }}
          style={{ backgroundColor: color }}
          onClick={() => toggle(true)}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ fontWeight: 'bold', p: 0, m: 0, fontSize: 16 }} >{String(color).toUpperCase()}</Typography>
          <Typography sx={{ p: 0, m: 0, color: '#666', fontSize: 14 }}>Click to change color</Typography>
        </Box>

      </Box>

      {isOpen && (
        <Box ref={popover}
          sx={{
            position: 'absolute',
            top: 'calc(100% + 2px)',
            left: 0,
            borderRadius: '9px',
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)'
          }}
        >
          <HexColorPicker color={color} onChange={onChange} />
          <HexColorInput color={color} onChange={onChange} />
        </Box>
      )}
    </Box>
  );
};
