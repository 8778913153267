export default (currentProperties, properties) => {
  // handle what properties the preview should be showing
  // based of its own state (currentProperties)
  // and the state that the user passes to the component
  // using the properties prop
  // check though each section and return a new object to
  // ensure no data is missing when called

  // pull out props from currentProperties ease of use
  const { name, branding, modules, houseModules, welcome } = currentProperties;

  // keep tack of the overall state
  let state = {
    name: properties.name ? properties.name : name,
    branding: { ...branding },
    modules: { ...modules },
    houseModules: { ...houseModules },
    welcome: properties.welcome ? properties.welcome : {},
    home: properties.home ? properties.home : {},
    contact: properties.contact
      ? properties.contact
      : {
          description: '',
          contacts: [],
        },
    registerInterest: properties.registerInterest
      ? properties.registerInterest
      : {
          content: '',
          codeText: '',
          codeUrl: '',
        },
  };

  // check over the branding props passed in
  if (properties.hasOwnProperty('branding')) {
    state.branding = {
      ...branding,
      primary: properties.branding.primary
        ? properties.branding.primary
        : branding.primary,
      secondary: properties.branding.secondary
        ? properties.branding.secondary
        : branding.secondary,
      tertiary: properties.branding.tertiary
        ? properties.branding.tertiary
        : branding.tertiary,
      card: properties.branding.card ? properties.branding.card : branding.card,
      section: properties.branding.section
        ? properties.branding.section
        : branding.section,
      map: properties.branding.map ? properties.branding.map : branding.map,
      startButton: properties.branding.startButton
        ? properties.branding.startButton
        : branding.startButton,
      startButtonText: properties.branding.startButtonText
        ? properties.branding.startButtonText
        : branding.startButtonText,
      logo: properties.branding.logo ? properties.branding.logo : branding.logo,
      line: properties.branding.line ? properties.branding.line : branding.line,
    };
  }

  // check over the modules props passed in
  if (properties.hasOwnProperty('modules')) {
    state.modules = {
      ...modules,
      // developmentOverview
      developmentOverview: properties.modules.developmentOverview
        ? properties.modules.developmentOverview
        : modules.developmentOverview,
      // propertyOptionCenter
      propertyOptionCenter: properties.modules.propertyOptionCenter
        ? properties.modules.propertyOptionCenter
        : modules.propertyOptionCenter,
      // individualPropertyTypeOverview
      individualPropertyTypeOverview: properties.modules
        .individualPropertyTypeOverview
        ? properties.modules.individualPropertyTypeOverview
        : modules.individualPropertyTypeOverview,
      // plots
      plots: properties.modules.plots
        ? properties.modules.plots
        : modules.plots,
      // localInformation
      localInformation: properties.modules.localInformation
        ? properties.modules.localInformation
        : modules.localInformation,
      // transportationLinks
      transportationLinks: properties.modules.transportationLinks
        ? properties.modules.transportationLinks
        : modules.transportationLinks,
      // otherHousingDevelopments
      otherHousingDevelopments: properties.modules.otherHousingDevelopments
        ? properties.modules.otherHousingDevelopments
        : modules.otherHousingDevelopments,
      // promotionsAndSalesMethods
      promotionsAndSalesMethods: properties.modules.promotionsAndSalesMethods
        ? properties.modules.promotionsAndSalesMethods
        : modules.promotionsAndSalesMethods,
      // designAdvice
      designAdvice: properties.modules.designAdvice
        ? properties.modules.designAdvice
        : modules.designAdvice,
      // registerInterest
      registerInterest: properties.modules.registerInterest
        ? properties.modules.registerInterest
        : modules.registerInterest,
      // contactUs
      contactUs: properties.modules.contactUs
        ? properties.modules.contactUs
        : modules.contactUs,
      // wishList
      wishList: properties.modules.wishList
        ? properties.modules.wishList
        : modules.wishList,
    };
  }

  // check over the houseModules props passed in
  if (properties.hasOwnProperty('houseModules')) {
    state.houseModules = {
      ...houseModules,
      // floorplan
      floorplan: properties.houseModules.floorplan
        ? properties.houseModules.floorplan
        : houseModules.floorplan,
      // options
      options: properties.houseModules.options
        ? properties.houseModules.options
        : houseModules.options,
      // gallery
      gallery: properties.houseModules.gallery
        ? properties.houseModules.gallery
        : houseModules.gallery,
      // flyover
      flyover: properties.houseModules.flyover
        ? properties.houseModules.flyover
        : houseModules.flyover,
      // location
      location: properties.houseModules.location
        ? properties.houseModules.location
        : houseModules.location,
    };
  }

  if (properties.hasOwnProperty('welcome')) {
    // state.welcome = {
    // 	rightText: properties.rightText ? properties.rightText : welcome.rightText,
    // 	description: properties.description ? properties.description : welcome.description,
    // 	video: properties.video ? properties.video : welcome.video
    // }
  }

  // return the processed state :)
  return state;
};
