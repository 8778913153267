import { ApiError, beablooApi } from '../../utils';

const setWelcome = () => {
  return async (dispatch, getState) => {
    const { general } = getState();

    const response = await beablooApi({
      method: 'GET',
      route: `/developments/${general.currentDevelopment.id}/pages/welcome`,
    })
      .then((r) => r.data)
      .catch((e) => {
        throw new ApiError('failed to get welcome', []);
      });

    dispatch({
      type: 'SET_WELCOME',
      data: response,
    });
  };
};

export { setWelcome };
