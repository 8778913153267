/* eslint-disable eqeqeq */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { OptionSelectorOption } from '..';
import { ReactComponent as Chevron } from '../../../../assets/icons/chevron-forward-outline.svg';
import './styles.scss';
import { useEffect } from 'react';

// component that is used as a child to OptionSelectorCategory
// props (id, name) are the name and id of a signtouch option type
// used to contain all the signtouch options that fall within the option type
const OptionSelectorType = ({ id, name }) => {
  // get options from state and filter it to only
  // give the options with the optionType that match the
  // type prop of this component
  const options = useSelector((state) => state.options).filter(
    (option) => id == option.optionType,
  );

  // keep track of if user should see the options
  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  return (
    <div className='option-selector-type'>
      <div className='option-selector-type-header' onClick={toggleShowOptions}>
        <motion.div
          className='chevron'
          initial={{ rotate: 0 }}
          animate={{ rotate: showOptions ? 90 : 0 }}
        >
          <Chevron />
        </motion.div>
        <h3>{name}</h3>
      </div>

      <AnimatePresence>
        {showOptions && (
          <motion.div
            className='option-selector-type-options'
            key={`option-selector-type-dropdown-${name}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: 'stiff', duration: 0.1 }}
          >
            {options &&
              options.map((option) => {
                return (
                  <OptionSelectorOption
                    key={`option-selector-option=${option.name}`}
                    {...option}
                  />
                );
              })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default OptionSelectorType;
