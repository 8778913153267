import { ApiError, beablooApi, findDevelopmentId } from '../../utils';

const setOptionTypes = () => {
  return async (dispatch, getState) => {
    const currentDevelopment = getState().general.currentDevelopment;
    const { developments } = getState();

    // used to inject the ID of the development
    // that a house Type belongs to so we can filter later
    const injectId = (data, id) => {
      return {
        ...data,
        belongsTo: id,
      };
    };

    const requestRequiredOptionTypes = (currentDevelopment, developments) => {
      return new Promise(async (resolve, reject) => {
        try {
          let siteTypes = [];
          let regionTypes = [];
          let parentTypes = [];

          // find only parent houses
          if (currentDevelopment.type === 'signtouch-parent') {
            parentTypes = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.id}/optionTypes`,
            })
              .then((r) => r.data)
              .then((r) => r.map((r) => injectId(r, currentDevelopment.id)))
              .catch((e) => {
                throw new ApiError('failed to get parent option types', []);
              });
          }

          // find parent && region houses
          if (currentDevelopment.type === 'signtouch-region') {
            parentTypes = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.parentId}/optionTypes`,
            })
              .then((r) => r.data)
              .then((r) =>
                r.map((r) => injectId(r, currentDevelopment.parentId)),
              )
              .catch((e) => {
                throw new ApiError('failed to get parent option types', []);
              });
            regionTypes = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.id}/optionTypes`,
            })
              .then((r) => r.data)
              .then((r) => r.map((r) => injectId(r, currentDevelopment.id)))
              .catch((e) => {
                throw new ApiError('failed to get region option types', []);
              });
          }

          // find parent && region && site houses
          if (currentDevelopment.type === 'signtouch-site') {
            // find the site's region so we can use it's parent Id to get the last
            // of the options available to us
            const regionDevelopment = developments.find(
              (development) => development.id === currentDevelopment.parentId,
            );
            parentTypes = await beablooApi({
              method: 'GET',
              route: `/developments/${regionDevelopment.parentId}/optionTypes`,
            })
              .then((r) => r.data)
              .then((r) =>
                r.map((r) => injectId(r, regionDevelopment.parentId)),
              )
              .catch((e) => {
                throw new ApiError('failed to get parent option types', []);
              });
            regionTypes = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.parentId}/optionTypes`,
            })
              .then((r) => r.data)
              .then((r) =>
                r.map((r) => injectId(r, currentDevelopment.parentId)),
              )
              .catch((e) => {
                throw new ApiError('failed to get region option types', []);
              });
            siteTypes = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.id}/optionTypes`,
            })
              .then((r) => r.data)
              .then((r) => r.map((r) => injectId(r, currentDevelopment.id)))
              .catch((e) => {
                throw new ApiError('failed to get site option types', []);
              });
          }

          resolve([...siteTypes, ...regionTypes, ...parentTypes]);
        } catch (e) {
          reject(e);
        }
      });
    };

    dispatch({
      type: 'SET_OPTION_TYPES',
      data: await requestRequiredOptionTypes(currentDevelopment, developments),
    });
  };
};

const addOptionType = (data) => {
  return async (dispatch, getState) => {
    const { optionTypes } = getState();
    const { currentDevelopment } = getState().general;
    const repeated = optionTypes.find((option) => option.id === data.id);
    if (repeated) return;

    dispatch({
      type: 'SET_OPTION_TYPES',
      data: [{ ...data, belongsTo: currentDevelopment.id }, ...optionTypes],
    });
  };
};
//
const updateOptionType = (data) => {
  return async (dispatch, getState) => {
    let { optionTypes } = getState();

    let option = optionTypes.find(
      (option) => String(option.id) === String(data.id),
    );
    const index = optionTypes.indexOf(option);

    optionTypes[index] = data;

    dispatch({
      type: 'SET_OPTION_TYPES',
      data: optionTypes,
    });
  };
};

const deleteOptionType = (data) => {
  return async (dispatch, getState) => {
    let { optionTypes } = getState();

    let type = optionTypes.find((plot) => plot.id === data.id);
    const index = optionTypes.indexOf(type);
    optionTypes.splice(index, 1);

    dispatch({
      type: 'SET_OPTION_TYPES',
      data: optionTypes,
    });
  };
};

export { setOptionTypes, addOptionType, updateOptionType, deleteOptionType };
