// Api Error is used to catch errors
// passed back from the Signtouch API
// so that they can be used in a catch block
class ApiError extends Error {
  constructor(message, errors) {
    super(message);
    this.errors = errors;
  }
}

export default ApiError;
