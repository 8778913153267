import { addError, ApiError, beablooApi, findDevelopmentId } from '../../utils';
import { applicationLoadError } from './general';

// properties comes from the signtouch-parent type
// so depending on the organisation we need to look
// for where to get the properties from
const setProperties = () => {
  return async (dispatch, getState) => {
    const { currentDevelopment } = getState().general;
    const { developments } = getState();

    const data = await beablooApi({
      method: 'GET',
      route: `/developments/${findDevelopmentId(
        currentDevelopment,
        developments,
      )}/properties`,
    })
      .then((r) => r.data)
      .then((r) => {
        return r;
      })
      .catch((e) => {
        addError(
          'Something went wrong loading the application. please try again',
        );
        dispatch(applicationLoadError());

        throw new ApiError('failed to get properties', []);
      });

    dispatch({
      type: 'SET_PROPERTIES',
      data: data,
    });
  };
};

export { setProperties };
