/* eslint-disable */
import { useState } from 'react';
import { useEffect } from 'react';
import './styles.scss';
import { addError } from '../../../../utils';

const OtherDetails = ({ onChange, initalValue }) => {
  // Used the manage all the contacts
  let [contacts, setContacts] = useState([]);
  // Tracks the item currently being edited
  let [selected, setSelected] = useState();
  // Keeps track of the delete state
  let [confirmDelete, setConfirmDelete] = useState(null);
  // Used to know when we need to move to the last item for the user automagically
  let [isGrabCursor, setGrabCursour] = useState(false);

  // Adds an empty contact item to the array
  const addContact = () => {
    let newItem = {
      title: '',
      phone: '',
      email: '',
      id: Math.random().toString(16),
    };

    if (selected && selected.hasOwnProperty('id')) {
      if (!selected.hasOwnProperty('title') || !selected.title.length > 0) {
        addError('Contacts need a title.');
        return;
      }

      if (
        selected &&
        !selected.phone.length > 0 &&
        !selected.email.length > 0
      ) {
        addError('Contacts need either a phone phone or email.');
        return;
      }

      if (contacts.length >= 4) {
        addError('A maximum of 4 contacts are supported.');
        return;
      }

      setContacts([
        ...contacts.filter((c) => c.id !== selected.id),
        selected,
        newItem,
      ]);
      setSelected(newItem);
      return;
    }

    if (contacts.length >= 4) {
      addError('A maximum of 4 contacts are supported.');
      return;
    }

    setContacts([...contacts, newItem]);

    setSelected(newItem);
  };

  // functions for updating the item in state
  const settitle = (e) => setSelected({ ...selected, title: e.target.value });
  const setEmail = (e) => setSelected({ ...selected, email: e.target.value });
  const setphone = (e) => setSelected({ ...selected, phone: e.target.value });

  const saveItem = (e) => {
    if (
      !selected ||
      !selected.hasOwnProperty('title') ||
      selected.title.length < 1
    ) {
      addError('Contacts need a title.');
      return;
    }

    if (!selected.phone.length > 0 && !selected.email.length > 0) {
      addError('Contacts need either a phone phone or email.');
      return;
    }

    // Update the selected item in the array
    setContacts(
      contacts.map((contact) => {
        return selected.id === contact.id ? selected : contact;
      }),
    );

    // Clears the selected contact and ensures delete is clear
    setSelected(null);
    setConfirmDelete(null);
  };

  const deleteItem = () => {
    // If no delete is pending then set as pending
    if (confirmDelete === null) {
      setConfirmDelete(false);
      return;
    }
    // if a delete is pending tell useEffect to process it.
    if (confirmDelete === false) {
      setConfirmDelete(true);
    }
  };

  // Used to load the data from the inital
  useEffect(() => {
    if (initalValue) {
      setContacts(
        initalValue.map((value) => {
          return { ...value, id: Math.random().toString('16') };
        }),
      );
    }
  }, []);

  // Use this to check for a delete and it being confirmed
  useEffect(() => {
    if (confirmDelete !== true) return;

    setContacts(
      contacts.filter((c) => {
        return c.id !== selected.id;
      }),
    );

    setConfirmDelete(null);
    setSelected({});
  }, [confirmDelete]);

  // Runs to tell a parent about new contact details
  useEffect(() => {
    if (onChange) {
      onChange(contacts.map((c) => stripId(c)));
    }
  }, [contacts]);

  // Removed the id from the array, just makes the post request cleaner.
  // Id is only used by react for state anyway.
  const stripId = (element) => {
    return {
      title: element.title,
      phone: element.phone,
      email: element.email,
    };
  };

  useEffect(() => {
    if (isGrabCursor) {
      setSelected(contacts[contacts.length - 1]);
      setGrabCursour(false);
    }
  }, [contacts]);

  const isEnter = (e) => {
    if (e.code === 'Enter' && e.shiftKey) {
      setGrabCursour(true);
      addContact();
      saveItem();
      return;
    }

    if (e.code === 'Enter') {
      saveItem();
      return;
    }
  };

  return (
    <div className='other-contacts'>
      <table className='table' cellSpacing={0} cellPadding={0}>
        <thead>
          <tr>
            <th style={{ minWidth: '30%' }}>title</th>
            <th style={{ minWidth: '30%' }}>phone</th>
            <th style={{ minWidth: '30%' }}>Email</th>
            <th style={{ minWidth: '13rem' }}></th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((item, index) => {
            return (
              <tr key={item.id}>
                {selected &&
                selected.hasOwnProperty('id') &&
                selected.id === item.id ? (
                  <>
                    <td>
                      <input
                        type='text'
                        onKeyDown={isEnter}
                        onChange={settitle}
                        value={selected.title}
                        autoFocus
                      />
                    </td>
                    <td>
                      <input
                        type='text'
                        onKeyDown={isEnter}
                        onChange={setphone}
                        value={selected.phone}
                      />
                    </td>
                    <td>
                      <input
                        type='text'
                        onKeyDown={isEnter}
                        onChange={setEmail}
                        value={selected.email}
                      />
                    </td>
                    <td>
                      <button
                        className='button button-table'
                        onClick={(e) => saveItem()}
                      >
                        Save
                      </button>
                      <button
                        className='button button-table'
                        onClick={(e) => deleteItem()}
                      >
                        {confirmDelete === null ? 'Delete' : 'Confirm?'}
                      </button>
                    </td>
                  </>
                ) : (
                  <>
                    <td>{item.title} </td>
                    <td>{item.phone} </td>
                    <td>{item.email} </td>
                    <td>
                      <button
                        className='button button-table'
                        onClick={(e) => setSelected(item)}
                      >
                        Edit
                      </button>
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      <button onClick={addContact} className='button'>
        Add Contacts
      </button>
    </div>
  );
};

export default OtherDetails;
