import * as yup from 'yup';
import { isCoordinates } from '../../../../utils'

export const featureSchema = yup.object({
  name: yup.string().required('Name is required'),
  type: yup.string().required('Type is required'),
  className: yup.string().required('Icon is required'),
  coordinates: yup.mixed().test('is-coordinates', 'Coordinates are required', value => isCoordinates(value)),
  car: yup.number().typeError('Car distance must be a number').optional(),
  walk: yup.number().typeError('Walking distance must be a number').optional(),
  rail: yup.number().typeError('Rail distance must be a number').optional(),
  bus: yup.number().typeError('Bus distance must be a number').optional(),
});
