export const isCoordinates = value => {
  if (!Array.isArray(value)) {
    return false;
  }

  if (value.length !== 2) {
    return false;
  }

  const [lat, lng] = value;

  if (typeof lat !== 'number' || typeof lng !== 'number') {
    return false;
  }

  return true;
};
