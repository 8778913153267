import { Form, Saving, Heading } from '../../components';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { beablooApi } from '../../utils';
import { useState } from 'react';
import { addError } from '../../utils';
import { addNotification } from '../../utils';
import { SignTouchPreview } from '../../components';
import { PreviewRegisterInterest } from '../../components/SignTouchPreview/pages';
import { setIntrested } from '../../store/actions/register-interest';
import './index.scss';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';

const EditRegisterInterest = () => {
  const { developmentId } = useParams();
  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  const { content, codeText, codeUrl } = useSelector(
    (state) => state.registerInterest,
  );

  const [editedContent, setContent] = useState(content);
  const [editedCodeText, setCodeText] = useState(codeText);
  const [editedCodeUrl, setCodeUrl] = useState(codeUrl);

  // const socket = useSocket();
  const dispatch = useDispatch();

  const handleSubmit = async (data) => {
    // trigger spinner
    setSaving(true);
    let pendingId = addPending('Saving...');

    const payload = {
      content: editedContent,
      codeUrl: editedCodeUrl,
      codeText: editedCodeText,
    };

    await beablooApi({
      method: 'PUT',
      route: `/developments/${developmentId}/pages/registerinterest/update`,
      payload: payload,
    }).then((r) => {
      removePending(pendingId);
      if (!r.success) {
        setSaving(false);
        addError(r.message);
        setErrors(r.errors);
      } else {
        setSaving(false); // stop loading animation
        dispatch(setIntrested()); // update the redux state
        addNotification('Register Interest page has been updated.');
      }
    });
  };

  const handleChange = (data) => {
    if (data.content) {
      setContent(data.content);
    }

    if (data.codeText) {
      setCodeText(data.codeText);
    }

    if (data.codeUrl) {
      setCodeUrl(data.codeUrl);
    }
  };

  return (
    <div className='create-option page-padding'>
      <div className={'form-preview-container'}>
        <Heading label={`Edit Register Interest Page`} />

        <Form
          errors={errors}
          onChange={handleChange}
          onSubmit={handleSubmit}
          canSubmit={!saving}
          inputs={[
            {
              name: 'content',
              type: 'textarea',
              label: 'Main Content',
              initialValue: editedContent,
              bgType: 'section',
            },
            {
              name: 'codeText',
              type: 'textarea',
              label: 'Code Content',
              initialValue: editedCodeText,
              bgType: 'section',
            },
            {
              name: 'codeUrl',
              type: 'text',
              label: 'QR Code Location',
              initialValue: editedCodeUrl,
            },
          ]}
        />
      </div>
      <div style={{ position: 'fixed', right: '10vw', top: '10%' }}>
        <SignTouchPreview>
          <PreviewRegisterInterest
            content={editedContent ? editedContent : content}
            codeUrl={editedCodeUrl ? editedCodeUrl : codeUrl}
            codeText={editedCodeText ? editedCodeText : codeText}
          />
        </SignTouchPreview>
      </div>
    </div>
  );
};

export default EditRegisterInterest;
