import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';

// create a new context for staging
const StagedOptionsContext = createContext();

// export an access hook
export const useStagedOptions = () => {
  return useContext(StagedOptionsContext);
};

// export provider for staged options
export const StagedOptionsProvider = ({ children, value }) => {
  // keep track of selected options
  const [options, setOptions] = useState(value ? value : []);
  // user wants to have all options
  const [optionsOverride, setOptionsOverride] = useState(false);

  const totalOptions = useSelector((state) => state.options);
  const optionTypes = useSelector((state) => state.optionTypes);
  const optionCategories = useSelector((state) => state.optionCategories);
  const [usedOptions, setUsedOptions] = useState([]);
  useEffect(() => {
    setUsedOptions(() => {
      return totalOptions.filter((option) => {
        const optionType = optionTypes.find(
          (optionType) => optionType.id == option.optionType,
        );
        if (!optionType) {
          return false;
        }

        const optionCategory = optionCategories.find(
          (optionCategory) => optionCategory.id == optionType.categoryId,
        );
        if (!optionCategory) {
          return false;
        }

        return true;
      });
    });
  }, []);

  const stageListOfOptions = (data) => {
    setOptions(() => {
      return [...data];
    });
  };

  // add a new option to state
  const stageOption = (optionId) => {
    setOptions((options) => {
      return [...options, optionId];
    });
  };

  // remove the option from stating
  const unStageOption = (optionId) => {
    setOptions((options) => {
      return options.filter((option) => option !== optionId);
    });
  };

  // allow the user to set a list of options at once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setStagedOptionsList = useMemo(() => (list) => {
    setOptions(() => {
      return list;
    });
  });

  // toggle all options override
  const overrideOptions = (data) => {
    setOptionsOverride(() => {
      return data;
    });
  };

  useEffect(() => {
    if (optionsOverride) {
      setStagedOptionsList(usedOptions.map((option) => option.id));
    }

    // eslint-disable-next-line
  }, [optionsOverride]);

  return (
    <StagedOptionsContext.Provider
      value={{
        options,
        optionsOverride,
        stageListOfOptions,
        stageOption,
        unStageOption,
        overrideOptions,
        setStagedOptionsList,
      }}
    >
      {children}
    </StagedOptionsContext.Provider>
  );
};
