import schema from '../schemas/_branding';

const brandingReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_BRANDING':
      return {
        ...action.data,
      };

    case 'UPDATE_BRANDING':
      return {
        ...state,
      };

    default:
      return {
        ...state,
      };
  }
};

export default brandingReducer;
