import { useEffect, useState } from 'react';
import { Form, Heading, Page } from '../../components';
import styled from 'styled-components';
import { addError, addNotification, beablooApi } from '../../utils';
import { Navigate, useParams } from 'react-router';
import { defaults } from './defaults';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';

const ToggleWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '150px',
});

export const Matterport = () => {
  const { developmentId } = useParams();
  const [loading, setLoading] = useState(true);
  const [matterport, setMatterport] = useState(defaults);
  const [integrations, setIntegrations] = useState();
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false);

  const handleLoad = async () => {
    setLoading(true);

    try {
      const response = await beablooApi({
        method: 'GET',
        route: `/developments/${developmentId}/integrations`,
      });
  
      if (response.data?.integrations?.matterport) {
        setMatterport(response.data.integrations.matterport);
        setIntegrations(response.data);
        setLoading(false);
        return;
      }

      // if matter port is not in the list add it to the list
      const payload = {
        ...response.data,
        integrations: {
          matterport: {
            name: 'matterport',
            enabled: false,
            showHomeUrl: '',
          }
        }
      };
  
      const creation = await beablooApi({
        method: 'PUT',
        route: `/developments/${developmentId}/integrations/update`,
        payload,
      });
  
      setMatterport(creation?.data?.integrations?.matterport);
      setLoading(false);

    } catch (e) {
      addError('There was an issue loading the integration. Please try again later')
      setError(true);
    }
  };

  useEffect(() => {
    handleLoad();
  }, []);

  const [saving, setSaving] = useState();

  const handleSubmit = async data => {
    setSaving(true);
    const pendingToast = addPending('Updating Matterport...');

    const payload = {
      integrations: {
        ...integrations.integrations,
        matterport: {
          ...matterport,
          ...data,
        }
      }
    };

    const response = await beablooApi({
      method: 'PUT',
      route: `/developments/${developmentId}/integrations/update`,
      payload,
    });

    removePending(pendingToast);

    if (response.success) {
      addNotification('Matterport updated')
      setRedirect(true);
      return;
    };

    addError('Error updating Matterport')
    setSaving(false);
  };

  const handleToggle = () => {
    setMatterport({ ...matterport, enabled: !matterport.enabled });
  }

  if (error) {
    return (
      <Navigate to={`/developments/${developmentId}/`} />
    )
  }

  if (redirect) {
    return (
      <Navigate to={`/developments/${developmentId}/integrations`} />
    )
  }

  return (
    <Page>
      <Heading label='configure matterport' />

      {loading && (
        <p>fetching matterport...</p>
      )}

      {!loading && (
        <>
          <ToggleWrapper>
            <p>Enabled</p>
            <input type='checkbox' checked={matterport.enabled} onChange={handleToggle} />
          </ToggleWrapper>

          <Form 
            inputs={[
              {
                name: 'showHomeUrl',
                type: 'text',
                label: 'Show Home Matterport URL',
                initialValue: matterport?.showHomeUrl,
              },
            ]}
            // errors={error}
            canSubmit={!saving}
            onSubmit={handleSubmit}
          />
        </>
      )}
    </Page>
  );
}