import { ApiError, beablooApi } from '../../utils';

const downloadHouseType = (houseId) => {
  return async (dispatch, getState) => {
    const { general } = getState();

    await beablooApi({
      method: 'GET',
      route: `/developments/${general.currentDevelopment.id}/houseTypes/${houseId}`,
    }).then((r) => r.data);
  };
};

// request data from the sign touch API and return
// houseTypes based on the type of development
const setHouseTypes = () => {
  return async (dispatch, getState) => {
    const { currentDevelopment } = getState().general;
    const { developments } = getState();

    // used to inject the ID of the development
    // that a house Type belongs to so we can filter later
    const injectId = (data, id) => {
      return {
        ...data,
        belongsTo: id,
      };
    };

    const requestRequiredHouseTypes = (currentDevelopment, developments) => {
      return new Promise(async (resolve, reject) => {
        try {
          let siteHouses = [];
          let regionHouses = [];
          let parentHouses = [];

          // find only parent houses
          if (currentDevelopment.type === 'signtouch-parent') {
            parentHouses = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.id}/houseTypes`,
            })
              .then((r) => r.data)
              .then((r) => r.map((r) => injectId(r, currentDevelopment.id)))
              .catch((e) => {
                throw new ApiError('failed to get parent house types', []);
              });
          }

          // find parent && region houses
          if (currentDevelopment.type === 'signtouch-region') {
            parentHouses = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.parentId}/houseTypes`,
            })
              .then((r) => r.data)
              .then((r) =>
                r.map((r) => injectId(r, currentDevelopment.parentId)),
              )
              .catch((e) => {
                throw new ApiError('failed to get parent house types', []);
              });
            regionHouses = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.id}/houseTypes`,
            })
              .then((r) => r.data)
              .then((r) => r.map((r) => injectId(r, currentDevelopment.id)))
              .catch((e) => {
                throw new ApiError('failed to get region house types', []);
              });
          }

          // find parent && region && site houses
          if (currentDevelopment.type === 'signtouch-site') {
            // find the site's region so we can use it's parent Id to get the last
            // of the options available to us
            const regionDevelopment = developments.find(
              (development) => development.id === currentDevelopment.parentId,
            );
            parentHouses = await beablooApi({
              method: 'GET',
              route: `/developments/${regionDevelopment.parentId}/houseTypes`,
            })
              .then((r) => r.data)
              .then((r) =>
                r.map((r) => injectId(r, regionDevelopment.parentId)),
              )
              .catch((e) => {
                throw new ApiError('failed to get parent house types', []);
              });
            regionHouses = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.parentId}/houseTypes`,
            })
              .then((r) => r.data)
              .then((r) =>
                r.map((r) => injectId(r, currentDevelopment.parentId)),
              )
              .catch((e) => {
                throw new ApiError('failed to get region house types', []);
              });
            siteHouses = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.id}/houseTypes`,
            })
              .then((r) => r.data)
              .then((r) => r.map((r) => injectId(r, currentDevelopment.id)))
              .catch((e) => {
                throw new ApiError('failed to get parent site types', []);
              });
          }

          resolve([...siteHouses, ...regionHouses, ...parentHouses]);
        } catch (e) {
          reject(e);
        }
      });
    };

    dispatch({
      type: 'SET_HOUSE_TYPES',
      data: await requestRequiredHouseTypes(currentDevelopment, developments),
    });
  };
};

const addHouseType = (data) => {
  return {
    type: 'ADD_HOUSE_TYPE',
    data: data,
  };
};

const updateHouseType = (data) => {
  return {
    type: 'UPDATE_HOUSE_TYPE',
    data: data,
  };
};

const deleteHouseType = (data) => {
  return {
    type: 'DELETE_HOUSE_TYPE',
    data: data,
  };
};

export {
  setHouseTypes,
  downloadHouseType,
  addHouseType,
  updateHouseType,
  deleteHouseType,
};
