export const propertyTypeOptions = [
  { label: 'Flat', value: 'flat' },
  { label: 'Apartment', value: 'apartment' },
  { label: 'Studio', value: 'studio' },
  { label: 'House', value: 'house' },
  { label: 'Detached House', value: 'detached house' },
  { label: 'Semi-detached House', value: 'semi-detached house' },
  { label: 'Home', value: 'home' },
  { label: 'Detached Home', value: 'detached home' },
  { label: 'Semi-detached Home', value: 'semi-detached home' },
  { label: 'Mid Terrace', value: 'mid terrace' },
  { label: 'End Terrace', value: 'end terrace' },
  { label: 'Town House', value: 'town house' },
  { label: 'Bungalow', value: 'bungalow' },
  { label: 'Duplex', value: 'duplex' },
];

export const houseTypeBedroomOptions = [
  { label: 'Studio', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
];
