import { useEffect, useRef, useState } from 'react';

const NumberInput = ({
  initialValue = false,
  onChange,
  name,
  label,
  error,
}) => {
  const [state, setState] = useState({ value: 1 });
  const ref = useRef();
  const handleChange = (e) => {
    const { value, id } = e.target;

    setState({ value: parseInt(value) });
    // pass back to <Form />
    onChange({ name: name, value: value });
  };

  useEffect(() => {
    if (initialValue) ref.current.value = initialValue;
  }, [initialValue]);

  return (
    <div className={`form-input-text ${error ? 'form-input-text-error' : ''}`}>
      <label>
        {label}
        <input
          placeholder='123456'
          type='number'
          onChange={handleChange}
          min={0}
          ref={ref}
        />
      </label>
    </div>
  );
};

export default NumberInput;
