import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, DeleteModal, Heading } from '../../components';
import { Link } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../assets/icons/create.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { useState } from 'react';
import './style.scss';
import { ParentView, RegionView } from './Views';

export const Promotion = ({ id, name, isParent }) => {
  // keep track of request status (delete)
  const [isPendingRequest, setIsPendingRequest] = useState(false);
  const toggleIsPendingRequest = () =>
    setIsPendingRequest((state) => {
      return !state;
    });

  const { developmentId } = useParams();

  return (
    <>
      <div className='promotion'>
        <div className='promotion-details'>
          <h4>{name}</h4>
        </div>
        <div className='promotion-links'>
          {!isParent && (
            <Link
              to={`/developments/${developmentId}/promotions-sales-methods/${id}`}
            >
              <EditIcon />
            </Link>
          )}
          {!isParent && (
            <div className='delete-promotion' onClick={toggleIsPendingRequest}>
              <DeleteIcon />
            </div>
          )}
        </div>
      </div>

      {isPendingRequest && (
        <DeleteModal
          type='promotion'
          objectName='Promotion'
          options={{ id: id }}
          closeModal={toggleIsPendingRequest}
        />
      )}
    </>
  );
};

const PromotionsAndSalesMethods = () => {
  // DevelopmentID
  const { developmentId } = useParams();
  const { currentDevelopment } = useSelector((state) => state.general);

  // Promotions and Sales methods

  return (
    <div className={'page-padding promotions-and-sales-methods'}>
      <div className='heading-with-button'>
        <Heading label='Promotions' />
        <Link
          to={`/developments/${developmentId}/promotions-sales-methods/create`}
        >
          <Button label={'create promotion'} />
        </Link>
      </div>

      {currentDevelopment.type == 'signtouch-parent' && <ParentView />}

      {currentDevelopment.type == 'signtouch-region' && <RegionView />}
    </div>
  );
};

export default PromotionsAndSalesMethods;
