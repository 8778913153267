import './styles.scss';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { motion } from 'framer-motion';
import PlusIcon from '../../assets/img/icons8-plus-math-50.svg';

const SideBarItem = (props) => {
  const [hover, setHover] = useState(false);
  const toggleHover = () => setHover(!hover);

  const plusButtonVariants = {
    hidden: {
      left: '14rem',
    },
    show: {
      left: '4rem',
    },
  };

  return (
    <div className={'sidebar-item-container'}>
      <Link to={props.to}>
        <div className='sidebar-item'>
          <div className='icon'>
            {/* <img src={ props.icon } alt={`${props.label} icon`} /> */}
            {props.icon ? props.icon : <></>}
          </div>

          <div className='label'>
            <p>{props.label}</p>
          </div>
        </div>
      </Link>

      {props.plus ? (
        <Link to={props.plusTo}>
          <motion.div
            className='plus-button'
            variants={plusButtonVariants}
            initial='hidden'
            animate={hover ? 'show' : 'hidden'}
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
          >
            <img src={PlusIcon} />
            <p>{props.plusText}</p>
          </motion.div>
        </Link>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SideBarItem;
