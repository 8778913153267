import './styles.scss';

const Card = ({ heading, quantity, description, icon, body }) => {
  const Heading = () => {
    return (
      <div className='heading'>
        <h3>{heading ? heading : 'No Heading'}</h3>
        {icon ? icon : <></>}
      </div>
    );
  };

  const Quantity = () => {
    return (
      <div className='quantity'>
        <p>{quantity ? quantity : 'N/A'}</p>
      </div>
    );
  };

  const Description = () => {
    return (
      <div className='description'>
        <p>{description ? description : 'avaliable for this development'}</p>
      </div>
    );
  };

  return (
    <div className='card-container'>
      <div className='card'>
        <Heading />
        {body ? (
          body
        ) : (
          <>
            <Quantity />
            <Description />
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
