import { Image } from '@mui/icons-material'
import { Box } from '@mui/material'

export const PreviewThumbnail = () => {
  return (
    <Box 
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#333',
        borderRadius: '8px',
        width: '120px',
        height: '120px',
      }}
    >
      <Image style={{ color: 'white' }} />
    </Box>
  )
};
