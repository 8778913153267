import schema from '../schemas/_transportLinks';

const transportLinksReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_TRANSPORT':
      return {
        ...action.data,
      };

    default:
      return {
        ...state,
      };
  }
};

export { transportLinksReducer };
