import { ApiError, beablooApi } from '../../utils';

export const setPlayerUI = (data) => {
  return async (dispatch, getState) => {
    try {
      // find the current development
      const { currentDevelopment } = getState().general;

      const playerUIdata = await beablooApi({
        method: 'GET',
        route: `/developments/${currentDevelopment.id}/playerUI`,
      })
        .then((r) => {
          if (!r.success) {
            throw new ApiError('failed to get player UI', []);
          }
          return r.data;
        })
        .catch(() => {
          throw new ApiError('failed to get player UI', []);
        });

      dispatch({
        type: 'SET_PLAYER_UI',
        data: playerUIdata,
      });
    } catch (e) {
      // failed to get player ui state
      dispatch({
        type: 'SET_PLAYER_UI_ERROR',
      });
    }
  };
};

export const setPLayerUIFooterButtons = (data) => {
  return {
    type: 'SET_PLAYER_UI_FOOTER_BUTTONS',
    data: data,
  };
};
