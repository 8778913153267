import defaultState from '../schemas/_ui';

const UIreducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'TOGGLE_MENU':
      return {
        ...state,
        menuOpen: !state.menuOpen,
      };

    default:
      return {
        ...state,
      };
  }
};

export default UIreducer;
