/* eslint-disable eqeqeq */
// used to accept base46 or a media object
// and return a url (b64 or http)

const parseMediaObject = (data) => {
  if (typeof data == 'object') {
    const keys = Object.keys(data);

    if (keys.find((key) => key == 'url') && keys.find((key) => key == 'id')) {
      return data.url;
    }
  }

  // is a base64 string
  return data;
};

export default parseMediaObject;
