import { Typography } from '@mui/material';

export const HelperText = ({ message }) => {
  return (
    <Typography
      variant='p'
      sx={{
        color: '#D72C0D',
        fontWeight: 400,
        fontSize: '14px'
      }}
    >
      {message}
    </Typography>
  )
};
