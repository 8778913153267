import schema from '../schemas/_general';

const generalReducer = (state = schema, action) => {
  switch (action.type) {
    case 'APPLICATION_READY':
      return {
        ...state,
        ready: true,
      };

    case 'APPLICATION_LOAD_ERROR':
      return {
        ...state,
        loadError: true,
      };

    case 'APPLICATION_REMOVE_LOAD_ERROR':
      return {
        ...state,
        loadError: false,
      };

    case 'APPLICATION_UNREADY':
      return {
        ...state,
        ready: false,
      };

    case 'SET_CURRENT_DEVELOPMENT':
      return {
        ...state,
        currentDevelopment: action.data,
      };

    case 'SET_CURRENTLY_LOADING':
      return {
        ...state,
        currenltyLoading: action.data,
      };

    default:
      return {
        ...state,
      };
  }
};

export default generalReducer;
