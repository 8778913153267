import { useEffect, useReducer, useState, useRef } from 'react';
import { DndContext } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { SortableImage } from './components';
import { Button } from '../';
import './styles.scss';
import { beablooApi } from '../../utils';
import { useParams } from 'react-router-dom';
import Form from '../Form';

const GalleryEditor = ({ initalState }) => {
  const { developmentId } = useParams();
  const [currentImage, setCurrentImage] = useState(null);
  const galleryReducer = (state, action) => {
    switch (action.type) {
      case 'ADD_IMAGE':
        return [
          ...state,
          {
            id: action.id,
            url: action.url,
          },
        ];

      case 'REMOVE_IMAGE':
        return [...state.filter((image) => image.id !== action.data)];

      case 'REORDER':
        return [...action.data];

      default:
        return [...state];
    }
  };
  const [gallery, dispatch] = useReducer(galleryReducer, []);

  const form = useRef(null);
  const files = useRef(null);
  const handleFiles = (e) => {
    e.preventDefault();

    // handle files
    for (let i = 0; i < files.current.files.length; i++) {
      const reader = new FileReader();
      const file = files.current.files[i];
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const url = reader.result; // file as a b64 string w/ mime type
        await dispatch({
          type: 'ADD_IMAGE',
          id: (Math.random() + 1).toString(24),
          url: url,
        });
      };
    }

    // clear the form
    form.current.reset();
  };

  const handleSetFocusImage = (id) => {
    const image = gallery.find((img) => img.id === id);
    if (!image) return;
    setCurrentImage(image);
  };
  const handleRemoveImage = (id) => {
    dispatch({ type: 'REMOVE_IMAGE', data: id });
  };
  const handleDragEnd = (e) => {
    if (!e.over) return;
    const { active, over } = e;
    const getNewOrder = () => {
      const oldIndexObject = gallery.find((image) => active.id === image.id);
      const oldIndex = gallery.indexOf(oldIndexObject);
      const newIndexObject = gallery.find((image) => over.id === image.id);
      const newIndex = gallery.indexOf(newIndexObject);
      return arrayMove(gallery, oldIndex, newIndex);
    };
    if (active.id !== over.id) {
      dispatch({
        type: 'REORDER',
        data: getNewOrder(),
      });
    }
  };

  const handleSubmit = () => {
    const payload = {
      name: 'test',
      images: gallery.map((image) => image.url),
    };
    beablooApi({
      method: 'POST',
      route: `/developments/${developmentId}/galleries/create`,
      payload: payload,
    });
  };

  useEffect(() => {
    // component did mount
    if (initalState) {
      if (initalState.length > 0) setCurrentImage(initalState[0]);
    }
  }, []);

  useEffect(() => {
    // set the current image to be index 0 of new images
    setCurrentImage(gallery[0]);
  }, [gallery]);

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext items={gallery}>
        <div className='gallery-editor'>
          {/* <Form 
          inputs={[
            { name: 'name', type: 'text', label: 'name', placeholder: 'Gallery Name' },
          ]}
        /> */}
          <div className='images-array'>
            <div className='image-array-content'>
              {gallery.length === 0 && (
                <p>
                  No Images!
                  <br /> upload some to create a gallery
                </p>
              )}
              {gallery.length > 0 && (
                <div className='gallery-editor-images'>
                  {gallery.map((image, index) => {
                    return (
                      <SortableImage
                        id={image.id}
                        key={`gallery-image-${image.id}`}
                        {...image}
                        onClick={handleSetFocusImage}
                        onDelete={handleRemoveImage}
                      />
                    );
                  })}
                </div>
              )}
            </div>
            <div className='image-array-controls'>
              <form onSubmit={handleFiles} ref={form}>
                <label>
                  Upload Files
                  <input
                    type='file'
                    ref={files}
                    multiple
                    accept='image/jpg, image/jpeg, image/gif, image/png'
                  />
                </label>
                <input type='submit' value='upload files' />
              </form>
            </div>
          </div>
          <div className='image-viewer'>
            {currentImage && <img src={currentImage.url} alt='selcted image' />}
          </div>
        </div>
        <div className='submit'>
          <div onClick={handleSubmit}>
            <Button label='save' />
          </div>
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default GalleryEditor;
