import schema from '../schemas/_designAdvice';

const designAdviceReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_DESIGN_ADVICE':
      return {
        ...action.data,
      };

    case 'ADD_DESIGN_ADVICE_ARTICLE':
      return {
        ...state,
        articles: [...state.articles, action.data],
      };

    case 'ADD_DESIGN_ADVICE_TYPE':
      return {
        ...state,
        types: [...state.types, action.data],
      };

    case 'ADD_DESIGN_ADVICE_CATEGORY':
      return {
        ...state,
        categories: [...state.categories, action.data],
      };

    case 'UPDATE_DESIGN_ADVICE_ARTICLE':
      return {
        ...state,
        articles: [
          ...state.articles.filter((article) => article.id != action.data.id),
          action.data,
        ],
      };

    case 'UPDATE_DESIGN_ADVICE_TYPE':
      return {
        ...state,
        types: [
          ...state.types.filter((type) => type.id != action.data.id),
          action.data,
        ],
      };

    case 'UPDATE_DESIGN_ADVICE_CATEGORY':
      return {
        ...state,
        categories: [
          ...state.categories.filter(
            (category) => category.id != action.data.id,
          ),
          action.data,
        ],
      };

    case 'DELETE_DESIGN_ADVICE_ARTICLE':
      return {
        ...state,
        articles: [
          ...state.articles.filter((article) => article.id != action.data.id),
        ],
      };

    case 'DELETE_DESIGN_ADVICE_TYPE':
      return {
        ...state,
        types: [...state.types.filter((type) => type.id != action.data.id)],
      };

    case 'DELETE_DESIGN_ADVICE_CATEGORY':
      return {
        ...state,
        categories: [
          ...state.categories.filter(
            (category) => category.id != action.data.id,
          ),
        ],
      };

    default:
      return {
        ...state,
      };
  }
};

export { designAdviceReducer };
