import { useSelector } from 'react-redux';
import { Promotion } from '..';
import { Heading } from '../../../components';

const RegionView = () => {
  const { currentDevelopment } = useSelector((state) => state.general);
  const parentPromotions = useSelector((state) => state.promotions).filter(
    (promotion) => promotion.belongsTo == currentDevelopment.parentId,
  );

  const promotions = useSelector((state) => state.promotions).filter(
    (promotion) => promotion.belongsTo == currentDevelopment.id,
  );

  return (
    <>
      <div className='parent-promotions'>
        <Heading label={`created by head office (uneditable)`} />

        <div className={'promotions-list'}>
          {parentPromotions.map((promotion) => {
            return <Promotion key={promotion.id} isParent {...promotion} />;
          })}
        </div>
      </div>

      <Heading label={`created by ${currentDevelopment.name}`} />

      <div className={'promotions-list'}>
        {promotions.map((promotion) => {
          return <Promotion key={promotion.id} {...promotion} />;
        })}
      </div>
    </>
  );
};

export default RegionView;
