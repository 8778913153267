import { Box, TextField } from '@mui/material';
import { useController } from 'react-hook-form';
import { NumericFormat, removeNumericFormat } from 'react-number-format';
import { Label } from '../Label';
import { HelperText } from '../HelperText';

export const Price = ({ name, label, control, defaultValue, full }) => {
  const { field: { onChange, value }, fieldState: { error } } = useController({ name, control, defaultValue });

  const handleValueChange = (e, changeMeta) => {
    const value = e.target.value;
    const result = removeNumericFormat(value, changeMeta, {});
    onChange({
      amount: Number(result),
      currency: 'GBP'
    });
  };

  return (
    <Box 
      sx={{ 
        width: full ? '100%' : 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}
    >
      <Box>
        <Label>{ label }</Label>
        <NumericFormat
          customInput={TextField}
          allowNegative={false}
          thousandSeparator={true}
          prefix={'£'}
          isNumericString
          sx={error
            ? { width: full ? '100%' : 'auto', border: '#D82C0D 1px solid', borderRadius: '4px', bgcolor: '#FFF4F4' }
            : { width: full ? '100%' : 'auto' }
          }
          value={value?.amount}
          onChange={handleValueChange}
        />
      </Box>
      {error && (
        <HelperText message={error?.message} />
      )}
    </Box>
  );
};