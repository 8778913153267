import { Heading } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { addError } from '../../utils';
import { addNotification } from '../../utils';
import { SignTouchPreview } from '../../components';
import { PreviewHome } from '../../components/SignTouchPreview/pages';
import { setHome } from '../../store/actions/home';
import { useForm, useWatch } from 'react-hook-form';
import { useYupResolver } from '../../hooks';
import { homePageSchema } from './homePage.schema';
import { Button, SingleImage, SingleVideo } from '../../components/FormV2/components';
import { Box, Typography } from '@mui/material';
import { staticPageService } from '../../lib/signtouch';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';

const EditHomePage = () => {
  const resolver = useYupResolver(homePageSchema);
  const { control, handleSubmit } = useForm({
    resolver,
  });

  const video = useWatch({ control, name: 'video' });
  const image1 = useWatch({ control, name: 'image1' });
  const image2 = useWatch({ control, name: 'image2' });

  const home = useSelector((state) => state.home);

  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = async values => {
    const payload = {
      video: values.video,
      images: [
        values.image1,
        values.image2
      ]
    }
    const pendingNotification = addPending('Updating home page');
    setSaving(true);

    try {
      await staticPageService.updateHomePage(payload);    
      removePending(pendingNotification);

      await dispatch(setHome());
      addNotification('Home page updated');

    } catch {
      addError('Failed to update home page');
    }

    setSaving(false);
  }

  return (
    <div className='create-option page-padding'>
      <Heading label={`Edit Home`} />

      <Box 
        sx={{
          maxWidth: '600px', p: 4, backgroundColor: 'white', borderRadius: 2
        }}
      >
        <Typography variant="h6" component="h2">
          Edit Home Page
        </Typography>
        <Typography sx={{ mt: 1, mb: 2 }}>
          Change the video and images for the home page.
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <SingleVideo
              name='video'
              label='VIDEO'
              control={control}
              defaultValue={home.video}
            />

            <SingleImage
              name='image1'
              label='IMAGE 1'
              control={control}
              defaultValue={home.images[0]}
            />

            <SingleImage
              name='image2'
              label='IMAGE 2'
              control={control}
              defaultValue={home.images[1]}
            />
            <Button type='submit' disabled={saving}>{saving ? 'Saving...' : 'Save'}</Button>
          </Box>
        </form>
      </Box>

      <div style={{ position: 'fixed', right: '10vw', top: '10%' }}>
        <SignTouchPreview>
          <PreviewHome
            image1={image1 ? image1 : home.images[0].url}
            image2={image2 ? image2 : home.images[1].url}
            video={video ? video : home.video.url}
          />
        </SignTouchPreview>
      </div>
    </div>
  );
};

export default EditHomePage;
