import { usePreviewProperties } from '../../contexts/brandingContext';
import { makeCssColor } from '../../../../utils';
import WelcomeVideo from './video.mp4';
import './styles.scss';
import { useSelector } from 'react-redux';

const PreviewWelcome = ({ description, rightText, video }) => {
  // ask the preview to give us its properties
  const properties = usePreviewProperties();
  const { currentDevelopment } = useSelector((state) => state.general);

  return (
    <div
      className='preview-welcome'
      style={{
        background: makeCssColor(properties.branding.section),
      }}
    >
      <div className='preview-welcome-header'>
        <p>
          Welcome to <br />
          {currentDevelopment.name}
        </p>
      </div>
      <div className='preview-welcome-video'>
        <video
          src={video.hasOwnProperty('url') ? video.url : WelcomeVideo}
          autoPlay
          muted
          loop
        />
      </div>

      <div className='flex-grid white'>
        <div className='col-2-3 preview-welcome-left'>
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <div className='col-1-3 preview-welcome-right'>
          <p
            className='border-t border-b bold-font'
            style={{
              borderTop: `solid 1px ${properties.branding.line}`,
              borderBottom: `solid 1px ${properties.branding.line}`,
            }}
            dangerouslySetInnerHTML={{ __html: rightText }}
          />
        </div>
      </div>
    </div>
  );
};

export default PreviewWelcome;
