import { Box } from '@mui/material';
import { Button } from '../Button';
import { Label } from '../Label';
import { CoordinateThumbnail, Picker } from './components';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import { HelperText } from '../HelperText';

export const CoordinateSelect = ({ name, control, defaultValue }) => {
  const { 
    field: { onChange, value },
    fieldState: { error }
  } = useController({ name, control, defaultValue });

  const [showPicker, setShowPicker] = useState(false);
  const togglePicker = () => setShowPicker(!showPicker);

  const handleLocationChange = location => {
    const locationAsFloats = [Number(location[0]), Number(location[1])];
    onChange(locationAsFloats);
    togglePicker();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }} >
      <Label>LOCATION</Label>
      <CoordinateThumbnail location={value} error={error} />
      {error && (
        <HelperText message={error?.message} />
      )}
      <Button onClick={togglePicker}>Select Location</Button>

      {showPicker && (
        <Picker
          open={showPicker}
          onClose={togglePicker}
          onLocationChange={handleLocationChange}
        />
      )}
    </Box>
  )
};
