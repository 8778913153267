export const airports = [
  {
    name: 'London Heathrow',
    type: 'airport',
    description:
      'Heathrow Airport, originally called London Airport until 1966 and now known as London Heathrow, is a major international airport in London, England. With Gatwick, City, Luton, Stansted and Southend, it is one of six international airports serving London.',
    coordinates: [-0.461389, 51.4775],
  },
  {
    name: 'London Gatwick',
    type: 'airport',
    description:
      'Gatwick Airport is large international airport serving more than 250 destinations with more than 5 million passengers a year.',
    coordinates: [-0.1846378, 51.1536654],
  },
  {
    name: 'London Stansted',
    type: 'airport',
    description:
      'London Stansted Airport is an international airport located in Stansted Mountfitchet, Essex, England, 42 mi northeast of Central London. London Stansted serves over 160 destinations across Europe, Asia and Africa.',
    coordinates: [0.235, 51.885],
  },
  {
    name: 'Manchester Airport',
    type: 'airport',
    description:
      'Manchester Airport is large international airport serving more than 250 destinations with more than 5 million passengers a year.',
    coordinates: [-2.2746, 53.3581],
  },
  {
    name: 'London Luton',
    type: 'airport',
    description:
      'London Luton Airport is an international airport located 1.5 miles east of Luton town centre in England, and is 28 miles north of Central London. The airport is owned by London Luton Airport Ltd, a company wholly owned by Luton Borough Council, and operated by London Luton Airport Operations Ltd.',
    coordinates: [-0.368333, 51.874722],
  },
  {
    name: 'Edinburgh Airport',
    type: 'airport',
    description:
      'Edinburgh Airport is an airport located in the Ingliston area of Edinburgh, Scotland. It was the busiest airport in Scotland in 2019, handling over 14.7 million passengers. It was also the sixth-busiest airport in the United Kingdom by total passengers in 2019.',
    coordinates: [-3.3725, 55.95],
  },
  {
    name: 'Birmingham',
    type: 'airport',
    description:
      'Birmingham Airport, formerly Birmingham International Airport, is an international airport located 7 nautical miles east-southeast of Birmingham city centre, 9.5 nautical miles west-northwest of Coventry slightly north of Bickenhill village in the Metropolitan Borough of Solihull, England.',
    coordinates: [-1.748056, 52.453889],
  },
  {
    name: 'Glasgow',
    type: 'airport',
    description:
      'Glasgow Airport, also known as Glasgow International Airport, formerly Abbotsinch Airport, is an international airport in Scotland. It is located in Paisley, Renfrewshire, 8.6 nautical miles west of Glasgow city centre.',
    coordinates: [-4.433056, 55.871994],
  },
  {
    name: 'Belfast International',
    type: 'airport',
    description:
      'Belfast International Airport is an airport 11.5 NM northwest of Belfast in Northern Ireland. Formerly known as Aldergrove Airport, after the nearby village of Aldergrove. In 2018, over 6.2 million passengers travelled through the airport, marking a 7.4% increase compared with 2017.',
    coordinates: [-6.215833, 54.6575],
  },
  {
    name: 'Liverpool',
    type: 'airport',
    description:
      'Liverpool John Lennon Airport is an international airport located in Liverpool, England. The airport is within the City of Liverpool on the banks of the estuary of the River Mersey 6.5 nautical miles south-east of Liverpool city centre.',
    coordinates: [-2.849722, 53.333611],
  },
  {
    name: 'Newcastle',
    type: 'airport',
    description:
      'Newcastle International Airport is an international airport located on the outskirts of Newcastle upon Tyne in North East England. As of 2017, Newcastle International handled just under 5.2 million passengers annually.',
    coordinates: [-1.689772, 55.038056],
  },
  {
    name: 'Airport',
    type: 'airport',
    description:
      'Aberdeen International Airport is an international airport, located in the Dyce suburb of Aberdeen, Scotland, approximately 5 nautical miles northwest of Aberdeen city centre. A total of just under 3.1 million passengers used the airport in 2017, an increase of 4.6% compared with 2016.',
    coordinates: [-2.198056, 57.2025],
  },
  {
    name: 'London City',
    type: 'airport',
    description:
      `London City Airport is an international airport in London, England. It is located in the Royal Docks in the London Borough of Newham, approximately 6 miles east of the City of London and 3 miles east of Canary Wharf. These are the twin centres of London's financial industry, which is a major user of the airport.`,
    coordinates: [0.055278, 51.505278],
  },
  {
    name: 'East Midlands',
    type: 'airport',
    description:
      'East Midlands Airport is an international airport in the East Midlands of England, close to Castle Donington in northwestern Leicestershire, between Loughborough, Derby and Nottingham; Leicester is to the south and Lincoln north east.',
    coordinates: [-1.33333, 52.83333],
  },
  {
    name: 'Leeds Bradford',
    type: 'airport',
    description:
      'Leeds Bradford Airport is located in Yeadon, in the City of Leeds Metropolitan District in West Yorkshire, England, about 7 miles northwest of Leeds city centre, and about 9 miles northeast from Bradford city centre.',
    coordinates: [-1.660833, 52.866111],
  },
  {
    name: 'Belfast City',
    type: 'airport',
    description:
      'George Best Belfast City Airport is a single-runway airport in Belfast, Northern Ireland. Situated in County Down, it is adjacent to the Port of Belfast and is 3 miles from Belfast City Centre. It shares the site with the Spirit AeroSystems aircraft manufacturing facility.',
    coordinates: [-5.8725, 54.618056],
  },
  {
    name: 'Jersey',
    type: 'airport',
    description:
      'Jersey Airport is an international airport located in the parish of Saint Peter, 4 NM west northwest of Saint Helier in Jersey, in the Channel Islands.',
    coordinates: [-2.19578, 49.208056],
  },
  {
    name: 'London Southend',
    type: 'airport',
    description:
      'London Southend Airport is an international airport situated on the outskirts of Southend-on-Sea in Essex, England, approximately 36 miles from the centre of London. The airport straddles the boundaries between the borough of Southend-on-Sea and the Rochford District.',
    coordinates: [0.693333, 51.570278],
  },
  {
    name: 'Doncaster Sheffield',
    type: 'airport',
    description:
      'Doncaster Sheffield Airport, formerly Robin Hood Airport Doncaster Sheffield, is an international airport at the former RAF Finningley station, in the Metropolitan Borough of Doncaster within South Yorkshire, England. The airport lies 6 miles south-east of Doncaster and 19 mi east of Sheffield.',
    coordinates: [-1.004167, 53.475278],
  },
  {
    name: 'Southampton',
    type: 'airport',
    description:
      'Southampton Airport is an international airport located in both Southampton and Eastleigh, Hampshire in the United Kingdom. The airport is located on the border between the two councils, but most of the airport is in the borough of Eastleigh.',
    coordinates: [-1.356667, 50.950278],
  },
  {
    name: 'Inverness',
    type: 'airport',
    description:
      'Inverness Airport is an international airport situated at Dalcross, 7 NM north-east of the city of Inverness, Scotland. It is owned by Highlands and Islands Airports Limited.',
    coordinates: [-4.0475, 57.5425],
  },
  {
    name: 'Cardiff',
    type: 'airport',
    description:
      `Cardiff Airport is the busiest airport in Wales. It has been under the ownership of the Welsh Government since March 2013, operating at an arm's length as a commercial business. Passenger numbers were 1.66 million in 2019 and were increasing year-on-year.`,
    coordinates: [-3.343333, 51.396667],
  },
  {
    name: 'Guernsey',
    type: 'airport',
    description:
      'Guernsey Airport is an international airport on the island of Guernsey and the largest airport in the Bailiwick of Guernsey. It is located in the Forest, a parish in Guernsey, 2.5 nautical miles southwest of St. Peter Port and features mostly flights to Great Britain and some other European destinations.',
    coordinates: [],
  },
  {
    name: 'Bournemouth',
    type: 'airport',
    description:
      `Bournemouth Airport is an airport located 3.5 NM north-northeast of Bournemouth, in southern England. The site opened as RAF Hurn in 1941, but was transferred to civil control in 1944. For a short period Hurn served as London's international airport, until the opening of facilities at Heathrow.`,
    coordinates: [-1.8425, 50.78],
  },
];
