import schema from '../schemas/_welcome';

const welcomeReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_WELCOME':
      return {
        ...action.data,
      };

    default:
      return {
        ...state,
      };
  }
};

export { welcomeReducer };
