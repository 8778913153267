import { useState } from 'react';
import { Form, Heading, Page } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { addError, addNotification } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { addMultiFloorFloor } from '../../store/actions/multiFloor';
import { multiFloorService } from '../../lib/signtouch';
import { useForm } from 'react-hook-form';
import { useYupResolver } from '../../hooks';
import { floorSchema } from './CreateFloor.schema';
import { Button, ColorPicker, SingleImage, TextInput } from '../../components/FormV2/components';
import { Box } from '@mui/material';

export const CreateFloorPage = () => {
  const [saving, setSaving] = useState(false);
  const { developmentId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const multiFloor = useSelector(state => state.multiFloor);

  const resolver = useYupResolver(floorSchema);
  const { control, reset, handleSubmit } = useForm({
    resolver,
  });

  const onSubmit = async data => {
    const payload = {
      floors: [
        ...multiFloor.floors,
        {
          ...data,
          order: multiFloor.floors.length + 1
        }
      ]
    }

    const pendingToast = addPending('Adding your floor...');
    setSaving(true);

    try {
      const response = await multiFloorService.updateMultiFloor(payload);
      const newFloor = response.floors.find(floor => floor.order === multiFloor.floors.length + 1);
      dispatch(addMultiFloorFloor(newFloor))
  
      addNotification('Floor was created successfully')
      navigate(`/developments/${developmentId}/multi-floor/configuration`);
      
    } catch {
      addError('unable to create floor');
    }

    setSaving(false);
    removePending(pendingToast);
  };

  return (
    <Page>
      <Heading label='create floor' />

      <Box 
        sx={{
          maxWidth: '600px', p: 4, backgroundColor: 'white', borderRadius: 2
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <TextInput
              name='name'
              label='Name'
              control={control}
              full
            />
            <SingleImage
              name='image'
              label='Image'
              control={control}
              full
            />
            <ColorPicker
              name='backgroundColor'
              label='Background Color'
              control={control}
              full
            />

            <Button type='submit' disabled={saving}>{saving ? 'Saving...' : 'Save'}</Button>
          </Box>
        </form>
      </Box>

    </Page>
  )
};