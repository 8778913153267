import Form from '../Form';
import { useState } from 'react';
import addPending from '../../utils/addPending';
import { ApiError, beablooApi } from '../../utils';
import { useSelector } from 'react-redux';
import removePending from '../../utils/removePending';
import { useDispatch } from 'react-redux';
import {
  addDesignAdviceCategory,
  setDesignAdvice,
  updateDesignAdviceCategory,
} from '../../store/actions/designAdvice';
import { addError } from '../../utils';
import { addNotification } from '../../utils';
import { useEffect } from 'react';

const CreateDesignAdviceCategory = ({ categoryId, closeModal }) => {
  // Redux, I summon you!
  let dispatch = useDispatch();

  // Keep track of if a request is in progress.
  let [isSaving, setSaving] = useState(false);

  // If we load a category, load it here.
  let [category, setCategory] = useState({});

  let [name, setName] = useState('');
  let [description, setDescription] = useState('');
  let [image, setImage] = useState('');

  let general = useSelector((state) => state.general);
  let { categories } = useSelector((state) => state.designAdvice);

  useEffect(() => {
    if (!categoryId) return;

    let currentCategory =
      categoryId !== null ? categories.find((c) => c.id == categoryId) : null;

    setCategory(currentCategory);
  }, []);

  const handleSubmit = async () => {
    setSaving(true);
    // Add the pending notification
    let pendingId = addPending(
      category && category.hasOwnProperty('id') ? 'Updating...' : 'Saving...',
    );

    if (!categoryId) await addNewCategory();
    if (categoryId) await saveCategory();

    removePending(pendingId);
    setSaving(false);

    dispatch(setDesignAdvice());

    if (closeModal) {
      closeModal();
    }
  };

  const handleChange = (data) => {
    if (data.hasOwnProperty('name')) setName(data.name);
    if (data.hasOwnProperty('description')) setDescription(data.description);
    if (data.hasOwnProperty('image')) setImage(data.image);
  };

  return (
    <div className={'modal-container'}>
      <div className={'form modal'}>
        {category && category.hasOwnProperty('id') ? (
          <h3>Edit {category.name}</h3>
        ) : (
          <h3>Create New Category</h3>
        )}
        <br />
        {(categoryId && category.hasOwnProperty('id')) || !categoryId ? (
          <Form
            inputs={[
              {
                name: 'name',
                type: 'text',
                label: 'Name',
                initialValue: category.name,
              },
              {
                name: 'description',
                type: 'textarea',
                label: 'Description',
                initialValue: category.description,
              },
              {
                name: 'image',
                type: 'single-image',
                label: 'Thumbnail',
                initialValue: category.image,
              },
            ]}
            onSubmit={handleSubmit}
            onChange={handleChange}
            canSubmit={!isSaving}
          />
        ) : null}
      </div>
    </div>
  );

  function addNewCategory() {
    return beablooApi({
      method: 'POST',
      route: `/developments/${general.currentDevelopment.id}/designAdviceCategories/create`,
      payload: {
        name: name,
        description: description,
        image: image,
      },
    })
      .then((r) => {
        if (!r.success) {
          throw new ApiError(r.message, r.error);
        }

        setCategory(r.data);

        addNotification('Successfully created new category');

        return r.data;
      })
      .then((r) => dispatch(addDesignAdviceCategory(r)))
      .catch((e) => {
        addError(e.message);
      });
  }

  function saveCategory() {
    return beablooApi({
      method: 'PUT',
      route: `/developments/${general.currentDevelopment.id}/designAdviceCategories/${category.id}/update`,
      payload: {
        name: name,
        description: description,
        image: image,
      },
    })
      .then((r) => {
        if (!r.success) {
          throw new ApiError(r.message, r.errors);
        }

        addNotification('Successfully updated category');

        return r.data;
      })
      .then((r) => dispatch(updateDesignAdviceCategory(r)))
      .catch((e) => {
        addError(e.message);
      });
  }
};

export default CreateDesignAdviceCategory;
