/* eslint-disable eqeqeq */
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import './styles.scss';
import Search from './Search';

const DevelopmentSelector = () => {
  const developments = useSelector((state) => state.developments);
  const [developmentsAsGroups, setDevelopmentsAsGroups] = useState([]);

  const { developmentId } = useParams();
  const [currentDevelopmentAsOption, setCurrentDevelopmentAsOption] = useState({
    value: 'Select A Development',
    label: 'Select A Development',
  });

  const [active, setActive] = useState(false);
  const toggleActive = () => {
    setActive(!active);
  };

  // sort the developments every time
  // there is a change to redux from
  // anywhere in the app
  useEffect(() => {
    // grab all the parent developments from redux
    const parents = developments
      .filter((development) => development.type === 'signtouch-region')
      .map((development) => {
        return { label: development.name, id: development.id };
      });
    // grab all the children developments from redux
    const children = developments
      .filter((development) => development.type === 'signtouch-site')
      .map((development) => {
        return {
          value: development.id,
          label: development.name,
          parentId: development.parentId,
        };
      });

    // add children to the parents under a "children" array
    const formattedDevelopments = parents.map((parent) => {
      return {
        ...parent,
        options: [...children.filter((child) => child.parentId === parent.id)],
      };
    });

    // pass the sorted developments to state
    setDevelopmentsAsGroups(formattedDevelopments);
  }, [developments]);

  // set the default select option
  // if the developmentId ever changes
  useEffect(() => {
    // pull out the current development
    const development = developments.find(
      (development) => development.id == developmentId,
    );

    setCurrentDevelopmentAsOption({
      label: development.name,
      value: development.name,
    });
  }, [developmentId, developments]);

  const labelSiteType = (type) => {
    switch (type) {
      case 'signtouch-parent':
        return 'parent';
      case 'signtouch-site':
        return 'site';
      case 'signtouch-region':
        return 'region';
      default:
        return type;
    }
  };

  return (
    <>
      <div className='development-selector-current' onClick={toggleActive}>
        <p>
          {currentDevelopmentAsOption.label} (
          {labelSiteType(
            developments.find((development) => development.id == developmentId)
              .type,
          )}
          )
        </p>
      </div>
      <AnimatePresence>
        {active && (
          <div className='development-selector-container'>
            <motion.div
              className='development-selector'
              key='development-selector'
              initial={{ translateX: '100%' }}
              animate={{ translateX: '0%' }}
              exit={{ translateX: '100%' }}
              transition={{ type: 'stiff' }}
            >
              <Search options={developmentsAsGroups} closeMenu={toggleActive} />
            </motion.div>
            <motion.div
              className='development-selector-dismissal'
              key='development-selector-dismissal'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={toggleActive}
            />
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

export default DevelopmentSelector;
