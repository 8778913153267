import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Heading } from '../../components';
import { ParentView, RegionView, SiteView } from './views';
import './styles.scss';

const HouseTypes = () => {
  const currentDevelopment = useSelector(
    (state) => state.general.currentDevelopment,
  );
  const houseTypes = useSelector((state) => state.houseTypes);

  const { developmentId } = useParams();

  useEffect(() => {}, [houseTypes]);

  return (
    <div className='page-padding'>
      <div className='heading-with-button'>
        <Heading label='house types' />
        <Link to={`/developments/${developmentId}/houseTypes/create`}>
          <Button label={'create house type'} />
        </Link>
      </div>

      {currentDevelopment.type === 'signtouch-site' && <SiteView />}

      {currentDevelopment.type === 'signtouch-region' && <RegionView />}

      {currentDevelopment.type === 'signtouch-parent' && <ParentView />}
    </div>
  );
};

export default HouseTypes;
