import { Heading } from '../../components';
import { Module } from './Module';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import {
  useSensors,
  useSensor,
  PointerSensor,
  DndContext,
} from '@dnd-kit/core';
import {
  arrayMove,
  verticalListSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import { beablooApi, addError, addNotification } from '../../utils';
import { useParams } from 'react-router-dom';
import './index.scss';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';

const Modules = (props) => {
  const { developmentId } = useParams();

  const [saving, setSaving] = useState(false);

  // Get the current modules from state
  const { modules, houseModules } = useSelector((state) => state.properties);

  // Define some custom sensors for DNDKit, means we can hopefully use components inside the sortable
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  // Keep a copy of the modified modules here for sending to the api on saving.
  const [loadedModules, setLoadedModules] = useState([]);
  const [loadedHouseModules, setHouseModules] = useState([]);

  // When the page loads convert the object into an array
  useEffect(() => {
    // Create an array
    const modulesList = [];
    const houseModulesList = [];
    // Got through all children properties in the modules object
    for (const [key, value] of Object.entries(modules)) {
      // Make a tempory item with the module properties
      let tempItem = value;
      // Set the id of the module to be its internal id e.g wishList
      tempItem.id = key;
      // Add the module to the array so we can put it in state
      modulesList.push(tempItem);
    }

    for (const [key, value] of Object.entries(houseModules)) {
      // Make a tempory item with the module properties
      let tempItem = value;
      // Set the id of the module to be its internal id e.g wishList
      tempItem.id = key;
      // Add the module to the array so we can put it in state
      houseModulesList.push(tempItem);
    }

    setHouseModules(houseModulesList.sort((a, b) => a.weight - b.weight));
    // Put the newly created array in state
    setLoadedModules(modulesList.sort((a, b) => a.weight - b.weight));
  }, [modules]);

  // This function runs when a user drops a module.
  const handleDragEnd = (e) => {
    // Get the two realted modules
    const { active, over } = e;
    // Check it actually needs to swap.
    if (active.id !== over.id) {
      // Modify the state to the new order
      setLoadedModules((modules) => {
        // Find the positions in the array of loaded moduels - we only get Id's
        const oldIndex = modules.indexOf(
          modules.find((m) => m.id === active.id),
        );
        const newIndex = modules.indexOf(modules.find((m) => m.id === over.id));
        // Use a dndkit function to swap them round and return to set state
        return arrayMove(modules, oldIndex, newIndex);
      });
    }
  };

  // This function runs when a user drops a house module.
  const handleModulesDragEnd = (e) => {
    // Get the two realted modules
    const { active, over } = e;
    // Check it actually needs to swap.
    if (active.id !== over.id) {
      // Modify the state to the new order
      setHouseModules((modules) => {
        // Find the positions in the array of loaded moduels - we only get Id's
        const oldIndex = modules.indexOf(
          modules.find((m) => m.id === active.id),
        );
        const newIndex = modules.indexOf(modules.find((m) => m.id === over.id));
        // Use a dndkit function to swap them round and return to set state
        return arrayMove(modules, oldIndex, newIndex);
      });
    }
  };

  const handleSubmit = () => {
    // Stops us saving multiple times
    if (saving) return;

    let pendingId = addPending('Saving...');

    setSaving(true);

    // Creates a payload for us to send to the api
    let modulesPayload = {};
    // Goes through the array and turns it back into an object with keys and weight
    for (let i = 0; i < loadedModules.length; i++) {
      // Add the correct weight back into the module
      loadedModules[i].weight = i + 1;
      // Add the module cofig to the payload object by id
      modulesPayload[loadedModules[i].id] = loadedModules[i];
    }

    let houseModulesPayload = {};

    for (let i = 0; i < loadedHouseModules.length; i++) {
      // Add the correct weight back into the module
      loadedHouseModules[i].weight = i + 1;
      // Add the module cofig to the payload object by id
      houseModulesPayload[loadedHouseModules[i].id] = loadedHouseModules[i];
    }

    // TODO: Needs to complete update redux

    beablooApi({
      method: 'PUT',
      route: `/developments/${developmentId}/properties/update`,
      payload: { modules: modulesPayload, houseModules: houseModulesPayload },
    }).then((result) => {
      removePending(pendingId);

      setSaving(false);

      if (!result.success) {
        addError('There was an error updating the properties.');
        return;
      }

      // dispatch(setProperties({properties, ...colors}))

      addNotification('Successfully updated the properties.');
    });
  };

  // Used to toggle modules as they're nestred
  const toggleModule = (e, state) => {
    // find the index of the module we want
    const oldIndex = loadedModules.indexOf(
      loadedModules.find((m) => m.id === e),
    );
    // Start updating the state
    setLoadedModules((modules) => {
      // Copy the state as it's immutable.
      let moduilesCopy = modules;
      // Set the state of enabled to whatever the child said using the index from before.
      moduilesCopy[oldIndex].enabled = state;
      // Return the newly modified array
      return moduilesCopy;
    });
  };

  // Used to toggle modules as they're nestred
  const toggleHouseModules = (e, state) => {
    // find the index of the module we want
    const oldIndex = loadedHouseModules.indexOf(
      loadedHouseModules.find((m) => m.id === e),
    );

    // Start updating the state
    setHouseModules((modules) => {
      // Copy the state as it's immutable.
      let moduilesCopy = modules;
      // Set the state of enabled to whatever the child said using the index from before.
      moduilesCopy[oldIndex].enabled = state;
      // Return the newly modified array
      return moduilesCopy;
    });
  };

  const setIcon = (e, newIcon) => {
    const oldIndex = loadedModules.indexOf(
      loadedModules.find((m) => m.id === e),
    );
    // Start updating the state
    setLoadedModules((modules) => {
      // Copy the state as it's immutable.
      let moduilesCopy = modules;
      // Set the state of enabled to whatever the child said using the index from before.
      moduilesCopy[oldIndex].customIcon = newIcon ? newIcon : false;
      // Return the newly modified array
      return moduilesCopy;
    });
  };

  const setTitle = (e, newTitle) => {
    const oldIndex = loadedModules.indexOf(
      loadedModules.find((m) => m.id === e),
    );
    // Start updating the state
    setLoadedModules((modules) => {
      // Copy the state as it's immutable.
      let moduilesCopy = modules;
      // Set the state of enabled to whatever the child said using the index from before.
      moduilesCopy[oldIndex].title = newTitle;
      // Return the newly modified array
      return moduilesCopy;
    });
  };

  return (
    <div className={'page-padding modules'}>
      <div className='modules'>
        <Heading label={'Configure Modules'} />
        <p>
          You can enable or disable modules on this page. Disabling a module
          prevents it from being used on any totem within the organisation. You
          can also drag the modules to re-order how they appear in the menu.
        </p>

        <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
          <SortableContext
            items={loadedModules}
            strategy={verticalListSortingStrategy}
          >
            {loadedModules.map((mod) => {
              return (
                <Module
                  canExpand
                  key={mod.id}
                  itemName={mod.title}
                  itemKey={mod.id}
                  {...mod}
                  setIcon={setIcon}
                  setTitle={setTitle}
                  disableModule={toggleModule}
                />
              );
            })}
          </SortableContext>
        </DndContext>
        <br />
        <Heading label={'Configure House Modules'} />
        <p>
          House modules are optional extra content shown on plots and house
          types pages. You can enable or re-order them here.
        </p>
        <DndContext sensors={sensors} onDragEnd={handleModulesDragEnd}>
          <SortableContext
            items={loadedHouseModules}
            strategy={verticalListSortingStrategy}
          >
            {loadedHouseModules.map((mod) => {
              return (
                <Module
                  key={mod.id}
                  itemName={mod.id}
                  itemKey={mod.id}
                  customIcon={mod.customIcon ? mod.customIcon : null}
                  {...mod}
                  disableModule={toggleHouseModules}
                />
              );
            })}
          </SortableContext>
        </DndContext>
        <button
          className={'button button-primary'}
          onClick={(e) => handleSubmit()}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Modules;
