import { useSelector } from 'react-redux';
import { OptionSelectorCategory } from '..';
import { useStagedOptions } from '../../contexts/stagedOptions';
import { useEffect } from 'react';
import { Toggle } from '../../../../components';
import './styles.scss';
import { useState } from 'react';

const OptionSelector = ({ onChange }) => {
  // pull out all the signtouch option categories
  const optionCategories = useSelector((state) => state.optionCategories);
  const optionTypes = useSelector((state) => state.optionTypes);

  // access all options
  const totalOptions = useSelector((state) => state.options);

  // access option context for feedback to the use
  // optionsOverride is a boolean
  // overrideOptions is the function to change the boolean
  // setStagedOptionsList is a function
  const { options, optionsOverride, overrideOptions } = useStagedOptions();

  useEffect(() => {
    // if handler is not set don't try to use it
    if (!onChange) {
      return;
    }

    // pass back only options in the context
    onChange(options);

    // eslint-disable-next-line
  }, [options]);

  // only account for used options (assigned to an active type)
  const [usedOptions, setUsedOptions] = useState([]);
  useEffect(() => {
    setUsedOptions(() => {
      return totalOptions.filter((option) => {
        const optionType = optionTypes.find(
          (optionType) => optionType.id == option.optionType,
        );
        if (!optionType) {
          return false;
        }

        const optionCategory = optionCategories.find(
          (optionCategory) => optionCategory.id == optionType.categoryId,
        );
        if (!optionCategory) {
          return false;
        }

        return true;
      });
    });
  }, []);

  return (
    <div className='options-selector'>
      <div className='options-selector-header'>
        <div className='options-selector-label'>
          <h3>Property Options</h3>
          <p>
            {optionsOverride ? usedOptions.length : options.length} /{' '}
            {usedOptions.length}
          </p>
        </div>
        <div className='options-selector-all'>
          <p>All Options</p>
          <Toggle
            onChange={(data) => {
              overrideOptions(data);
            }}
            overrideState={optionsOverride}
          />
        </div>
      </div>

      {optionCategories.length > 0 ? (
        optionCategories.map((category, index) => {
          return (
            <OptionSelectorCategory
              key={`option-selector-category-${index}`}
              {...category}
            />
          );
        })
      ) : (
        <p>This organisation doesn't seem to have any options set up</p>
      )}
    </div>
  );
};

export default OptionSelector;
