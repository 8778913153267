import { Link } from 'react-router-dom';
import { Button } from '../../../../components';
import { Heading } from '../../../../components';
import { DevelopmentSiteCard } from '..';
import './DevelopmentGroup.styles.scss';

// returns groups of developments where `signtouch-parent` is the
// root component and then nests regions and sites so that
// all 3 tiers of development can be accessed
const DevelopmentGroup = ({ id, name, regions }) => {
  return (
    <div className='development-group'>
      <div className='development-parent'>
        <div className='development-parent-heading'>
          <Heading label={name} />
          <Link to={`/developments/${id}/`}>
            <Button label={'configure organisation'} />
          </Link>
        </div>

        <div className='development-regions'>
          {regions.map((region) => {
            return <RegionGroup key={Math.random().toString(16)} {...region} />;
          })}
        </div>
      </div>
    </div>
  );
};

// returns a region with development grouped together by region
const RegionGroup = ({ id, name, sites }) => {
  return (
    <div className='development-region'>
      <div className='development-region-heading'>
        <h3>{name}</h3>
        <Link to={`/developments/${id}/`}>
          <Button label={'manage region'} />
        </Link>
      </div>

      {sites.length < 1 && (
        <div className='no-sites'>
          <p>no sites have been configured for this region</p>
        </div>
      )}

      {sites.length > 0 && (
        <div className='development-region-sites'>
          {sites.map((site) => {
            return (
              <DevelopmentSiteCard key={Math.random().toString(16)} {...site} />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DevelopmentGroup;
