import { useForm } from 'react-hook-form';
import { useQuery, useYupResolver } from '../../../../hooks';
import { localInformationSchema } from './localInformation.schema';
import { Box, Modal, Typography } from '@mui/material';
import { Button, SingleImage, TextInput, Wysiwyg } from '../../../../components/FormV2/components';
import { useLocation, useNavigate } from 'react-router-dom';
import { localInformationService } from '../../../../lib/signtouch';
import addPending from '../../../../utils/addPending';
import removePending from '../../../../utils/removePending';
import { addNotification } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { setLocalInformation } from '../../../../store/actions/localInformation';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  maxHeight: '70vh',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

export const LocalInformationModal = ({ open }) => {
  const query = useQuery();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const informationId = query.get('selectedInformation');
  const isNewInformation = informationId === 'null';
  const localInformation = useSelector(state => state.localInformation);
  const information = localInformation.content.find(info => `${info.id}` === `${informationId}`);

  const resolver = useYupResolver(localInformationSchema);
  const { control, reset, handleSubmit } = useForm({
    resolver,
  });

  const handleClose = () => {
    reset();
    navigate(pathname)
  };

  const onSubmit = async data => {
    const { name, description, thumbnail } = data;
    let payload = {};

    if (information) {
      payload = {
        ...information,
        name,
        description,
        thumbnail,
      }
    } else {
      payload = {
        name,
        description,
        thumbnail,
        features: [],
      };
    }

    const pendingToast = addPending(information ? 'updating feature' : 'creating feature');
    const response = information
      ? await localInformationService.updateLocalInformation(payload, information.id)
      : await localInformationService.createLocalInformation(payload);
    
    if (response) {
      await dispatch(setLocalInformation())
      removePending(pendingToast);
      addNotification(information ? 'Updated Feature' : 'Feature created successfully');
      handleClose();
    };

    removePending(pendingToast);
  };

  return (
    <Modal disablePortal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {isNewInformation 
            ? 'Create a new Local Information Category' 
            : `Edit: ${information?.name}`
          }
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1, mb: 2 }}>
          Manage your local information categories
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
              <TextInput 
                name='name'
                label='NAME'
                control={control}
                defaultValue={information?.name}
                full
              />
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: 2 }}>
              <Wysiwyg 
                name='description'
                label='DESCRIPTION'
                defaultValue={information?.description}
                control={control}
              />
            </Box>

            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: 2 }}>
              <SingleImage
                name='thumbnail'
                label='THUMBNAIL'
                defaultValue={information?.thumbnail}
                control={control}
              />
            </Box>

            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: 2 }}>
              <Button type='submit'>Submit</Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};
