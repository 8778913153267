import { PreviewMenuProvider } from './contexts/previewMenu';
import {
  PreviewPropertiesProvider,
  useUpdatePreviewProperties,
  usePreviewProperties,
} from './contexts/brandingContext';
import { PreviewLayout } from './components';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import handleProperties from './utils/handleProperties';

// return a component to preview updates to the
// Signtouch application based on pages passed
// into the Layout
//
// Uses a Provider called PreviewMenuProvider to handle the state
// of the menu outside redux to avoid confusion and uneeded
// storage of data in the global redux state
//
// LayoutComponent has most of the UI avalilable too it
// modify the /PreviewLayout component to update this component
const SignTouchPreview = ({ children, properties }) => {
  // pull out the app properties from the state
  // and make them accessable to the children
  const currentProperties = useSelector((state) => state.properties);
  const welcome = useSelector((state) => state.welcome);
  // create some state to hold the properties that
  // the preview should use
  const [previewProperties, setPreviewProperties] = useState(currentProperties);

  // find the current development and send it to the props
  const { currentDevelopment } = useSelector((state) => state.general);

  const combinedProperties = { ...currentProperties, ...welcome };

  // check each argument of the component to see if
  // we should pass any new features down to the preview
  // since we would have to actually update and save the
  // real state, so we can pass in what the user is updating here
  useEffect(() => {
    // check though the nested objects and overwrite the newones with
    // preview properties

    if (('preview', properties)) {
      // replace the old object with the preview argument
      if (typeof properties !== 'object') return;

      // pass the new props to the preview state
      setPreviewProperties(handleProperties(combinedProperties, properties));

      // pass propreties to provider
      // updateProperties(handleProperties(currentProperties, properties));
    }
  }, [properties]);

  return (
    <PreviewMenuProvider>
      <PreviewPropertiesProvider properties={previewProperties}>
        <PreviewLayout {...previewProperties} development={currentDevelopment}>
          {children}
        </PreviewLayout>
      </PreviewPropertiesProvider>
    </PreviewMenuProvider>
  );
};

export default SignTouchPreview;
