import { useState } from 'react';

const BasicInfomation = (props) => {
  function advanceStage() {
    if (props.hasOwnProperty('nextStage')) {
      props.nextStage(1);
    }
  }

  function toggleIndex(i) {
    let newSelect = houses;
    let newHouse = newSelect[i];

    newHouse.random = Math.random();

    if (newHouse.selected) {
      newHouse.selected = !newHouse.selected;
    } else {
      newHouse.selected = true;
    }

    newSelect[i] = newHouse;

    setHouses(newSelect);
  }

  let [houses, setHouses] = useState([
    {
      name: 'The Tombstone',
      thumbnail: 'https://source.unsplash.com/random/600x459',
    },
    {
      name: 'The Tree',
      thumbnail: 'https://source.unsplash.com/random/600x403',
    },
    {
      name: 'The Tombstone',
      thumbnail: 'https://source.unsplash.com/random/600x442',
      selected: true,
    },
    {
      name: 'Tombstone',
      thumbnail: 'https://source.unsplash.com/random/600x419',
    },
    {
      name: 'The Croft',
      thumbnail: 'https://source.unsplash.com/random/600x412',
    },
    {
      name: 'The Town House',
      thumbnail: 'https://source.unsplash.com/random/600x400',
    },
    {
      name: 'The Shed',
      thumbnail: 'https://source.unsplash.com/random/600x454',
    },
    {
      name: 'The Batcave',
      thumbnail: 'https://source.unsplash.com/random/600x435',
      selected: true,
    },
    {
      name: 'The One Without a Roof',
      thumbnail: 'https://source.unsplash.com/random/600x402',
    },
    {
      name: 'The With A Pool',
      thumbnail: 'https://source.unsplash.com/random/600x403',
    },
    {
      name: 'The Greeen One',
      thumbnail: 'https://source.unsplash.com/random/600x410',
    },
    {
      name: 'The Castle',
      thumbnail: 'https://source.unsplash.com/random/600x430',
      selected: true,
    },
  ]);

  function prevStage() {
    if (props.hasOwnProperty('nextStage')) {
      props.nextStage(0);
    }
  }

  return (
    <div className='center'>
      <h1>Available Houses</h1>
      <div className='grid-container'>
        <div className='houses-grid'>
          {houses.map((house, index) => {
            return (
              <div
                key={house.random}
                className='house'
                onClick={(e) => toggleIndex(index)}
              >
                <div
                  className='thumbnail'
                  style={{
                    backgroundImage: `url(${
                      house.thumbnail ? house.thumbnail : ''
                    })`,
                  }}
                />
                <h3>{house.name.toUpperCase()}</h3>
                {house.selected ? <div className='selected' /> : null}
              </div>
            );
          })}
        </div>
      </div>
      <div className='button-group'>
        <button className='button button-gray' onClick={prevStage}>
          Back
        </button>
        <button className='button button-primary' onClick={advanceStage}>
          Next
        </button>
      </div>
    </div>
  );
};

export default BasicInfomation;
