import { Image } from '@mui/icons-material';
import { Box } from '@mui/material';

const REACT_APP_MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY;
export const CoordinateThumbnail = ({ location = null, error }) => {
  if (!location) {
    return (
      <Box 
        sx={error 
          ? {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#FFF4F4',
              border: '#D82C0D 1px solid',
              borderRadius: '8px',
              width: '100%',
              height: '150px',
            }
          : {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#333',
              borderRadius: '8px',
              width: '100%',
              height: '150px',
            }
          }
      >
        <Image style={{ color: error ? '#D82C0D' : 'white' }} />
      </Box>
    )
  }

  return (
    <img 
      src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${location[0]},${location[1]},12.25,0,0/600x200?access_token=${REACT_APP_MAPBOX_API_KEY}`}
      alt={'coordinate thumbnail'}
      style={{
        width: '100%',
        height: '150px',
        borderRadius: '8px',
      }}
    />
  );
};
