import {
  CircularInputProvider,
  CircularInput,
  CircularProgress,
  CircularTrack,
  CircularThumb,
  useCircularInputContext,
} from 'react-circular-input';
import { useState } from 'react';
import './style.scss';
import { useEffect } from 'react';

const CurrentAngle = () => {
  const { value } = useCircularInputContext();

  return (
    <text
      textAnchor='middle'
      x={105}
      y={110}
      alignmentBaseline={'middle'}
      cx={100}
      cy={100}
    >
      {Math.round(value * 360)}&deg;
    </text>
  );
};

const AngleSelector = (props) => {
  let [value, setValue] = useState(
    props.initialValue ? props.initialValue / 360 : 0.9,
  );

  useEffect(() => {
    if (props.onChange) {
      props.onChange(Math.round(value * 360));
    }
  }, [value]);

  return (
    <CircularInputProvider>
      <CircularInput
        value={value}
        onChange={setValue}
        className='form-angle-input'
      >
        <CircularTrack />
        <CircularProgress />
        <CircularThumb />

        <CurrentAngle />
      </CircularInput>
    </CircularInputProvider>
  );
};

export default AngleSelector;
