import './styles.scss';

const Image = ({ thumbnail, onDelete, index }) => {
  return (
    <div className='image'>
      <div className='cross' onClick={() => onDelete(index)} />
      <div
        className='content'
        style={{
          backgroundImage: `url(${thumbnail})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      />
    </div>
  );
};

export default Image;
