import './styles.scss';

const Heading = (props) => {
  return (
    <div className='heading'>
      <h3>{props.label}</h3>
    </div>
  );
};

export default Heading;
