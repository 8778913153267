import * as yup from 'yup';
import { isMediaObject } from '../../../../utils';

export const localInformationSchema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  thumbnail: yup.mixed()
    .test('is-media-object', 'must be valid media', value => isMediaObject(value))
    .required('Thumbnail is required'),
});
