import { useEffect, useState } from 'react';
import '../../../../styles/_forms.scss';
import { Image } from '..';
import { parseMediaObject } from '../../../../utils';
import { v4 as uuid } from 'uuid';
import { isEqual } from 'lodash';

const MultiImage = ({ name, label, onChange, initialValue = [] }) => {
  const [images, setImages] = useState(initialValue);

  const handleDeleteImage = (id) => {
    setImages((state) => {
      return state.filter((image) => image.id !== id);
    });
  };

  const handleChange = () => {
    if (onChange) onChange({
      name,
      value: images.map((image) => {
        if (image.isBase64) {
          return image.url;
        }
        return image;
      }),
    });
  }

  const handleFile = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImages((state) => {
        return [...state, { id: uuid(), url: reader.result, isBase64: true }];
      });
    };
  };

  useEffect(() => {
    // check to see if the initial value has changed
    // we dont want to trigger the onChange if the initial value is the same
    if (isEqual(initialValue, images)) {
      return;
    }
    handleChange();

  }, [images]);

  // check to see if an initial value has been provided
  // if so apply it to state
  useEffect(() => {
    // check if we have any media objects
    if (
      initialValue &&
      initialValue.length > 0 &&
      initialValue[0].hasOwnProperty('url')
    ) {
      setImages(initialValue);
    }
  }, []);

  return (
    <>
      <div className='form-single-image '>
        <label>
          {label}
          <input type='file' accept='.png,.jpg,.jpeg' onChange={handleFile} />
        </label>
      </div>
      <div className='images'>
        {images.map((image, index) => {
          return (
            <Image
              key={`multi-image-${index}`}
              index={index}
              thumbnail={parseMediaObject(image)}
              onDelete={() => handleDeleteImage(image.id)}
            />
          );
        })}
      </div>
    </>
  );
};

export default MultiImage;
