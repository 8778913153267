import { Box } from '@mui/material';
import { Label } from '../Label';
import { useController } from 'react-hook-form';
import { Editor } from 'react-draft-wysiwyg';

import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ContentState from 'draft-js/lib/ContentState';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { WYSIWYG_COLORS } from './constants';
import './wysiwyg.styles.css';
import { HelperText } from '../HelperText';

export const Wysiwyg = ({ name, control, defaultValue, label, full }) => {
  const { 
    field: { onChange },
    fieldState: { error }
  } = useController({ name, control, defaultValue });
  const { branding } = useSelector(state => state.properties);

  const [editorState, setEditorState] = useState(defaultValue
    ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(defaultValue).contentBlocks))
    : EditorState.createEmpty()
  );
  const handleEditorStateChange = editorState => {
    setEditorState(editorState);
  };

  useEffect(() => {
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }, [editorState]);
  

  return (
    <Box sx={{ width: full ? '100%' : 'auto' }}>
      <Label>{label}</Label>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        toolbar={{
          options: ['inline', 'colorPicker', 'remove', 'history', 'list', 'blockType'],
          colorPicker: {
            colors: [
              `${branding.primary}`,
              `${branding.secondary}`,
              `${branding.tertiary}`,
              `${branding.line}`,
              ...WYSIWYG_COLORS,
            ],
          },
        }}
        editorClassName='wysiwyg-editor'
        wrapperClassName='wysiwyg-editor-wrapper'
      />
      {error && (
        <HelperText message={error?.message} />
      )}
    </Box>
  );
};
