import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children }) => {
  const authentication = useSelector((state) => state.authentication);

  if (!authentication.loggedin) return (
    <Navigate to='/login' />
  );

  return children;
};

export default ProtectedRoute;