import './styles.scss';
import { motion } from 'framer-motion';
import { useState } from 'react';

const Notification = (props) => {
  const animationStates = {
    notification: {
      show: {
        translateY: '0%',
      },
      hide: {
        translateY: '-100%',
      },
    },
    timer: {
      start: {
        width: '0%',
      },
      end: {
        width: '100%',
      },
    },
  };

  const [isStart, setStart] = useState(true);
  const [isTimer] = useState(false);

  const slideLogic = () => {
    if (!isStart) {
      if (props.removeElement) {
        props.removeElement();
      }
    }
  };

  const timerLogic = () => {
    setStart(false);
  };

  return (
    <motion.div
      animate={isStart ? 'show' : 'hide'}
      onAnimationComplete={slideLogic}
      initial={'hide'}
      variants={animationStates.notification}
      className={props.success ? 'success-container' : 'error-container'}
    >
      <p>{props.message ? props.message : 'An unknown error occurred.'}</p>
      <div className='error-pos'>
        <motion.div
          onAnimationComplete={timerLogic}
          transition={{ duration: 5 }}
          animate={isTimer ? 'start' : 'end'}
          initial={'start'}
          variants={animationStates.timer}
          className='error-timer'
        />
      </div>
    </motion.div>
  );
};

export default Notification;
