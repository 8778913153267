import { usePreviewProperties } from '../../contexts/brandingContext';
import { makeCssColor } from '../../../../utils';
import { useSelector } from 'react-redux';
import './styles.scss';
import { useState } from 'react';
import { useEffect } from 'react';

const PreviewContactUs = ({ description, contacts }) => {
  // ask the preview to give us its properties
  const properties = usePreviewProperties();

  const { currentDevelopment } = useSelector((state) => state.general);

  return (
    <>
      <style>
        span.highlight {`{ color: ${properties.branding.tertiary}}`}
      </style>
      <div
        className='preview-contact'
        style={{
          background: makeCssColor(properties.branding.section),
        }}
      >
        <div className='container'>
          <div className='flex-grid'>
            <div className='col-2-3'>
              <h1>Contact Us</h1>
              <hr style={{ borderColor: `${properties.branding.line}` }} />
              <p dangerouslySetInnerHTML={{ __html: description }} />
              {contacts.length > 0 &&
                contacts.map((contact, index) => {
                  if (contact.title.length === 0) return null;
                  return (
                    <div className={'contact'}>
                      <h4>{contact.title}</h4>
                      {contact.email && (
                        <p>
                          Email:{' '}
                          <span className={'highlight'}>{contact.email}</span>
                        </p>
                      )}
                      {contact.phone && (
                        <p>
                          Phone:{' '}
                          <span className={'highlight'}>{contact.phone}</span>
                        </p>
                      )}
                      {index !== contacts.length - 1 && (
                        <hr
                          style={{ borderColor: `${properties.branding.line}` }}
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewContactUs;
