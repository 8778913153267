const initialState = {
  developmentImage: null,
  floors: []
}

const multiFloorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MULTI_FLOOR':
      return action.data;

    case 'SET_MULTI_FLOOR_ORDER':
      return {
        ...state,
        floors: action.data
      }

    case 'ADD_MULTI_FLOOR_FLOOR':
      return {
        ...state,
        floors: [
          ...state.floors,
          action.data
        ]
      }

    default:
      return state;
  }
};

export default multiFloorReducer;
