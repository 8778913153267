import React, { useContext, useState } from 'react';

const FormColorContext = React.createContext();
const FormColorUpdateContext = React.createContext();

export const useFormColor = () => {
  return useContext(FormColorContext);
};

export const useUpdateFormColor = () => {
  return useContext(FormColorUpdateContext);
};

export const FormColorProvider = ({ children }) => {
  // null empty sting state as has been no
  // color input selected yet
  const [state, setState] = useState('');

  // update the id (duh)
  const updateId = (data) => setState(data);

  return (
    <FormColorContext.Provider value={state}>
      <FormColorUpdateContext.Provider value={updateId}>
        {children}
      </FormColorUpdateContext.Provider>
    </FormColorContext.Provider>
  );
};
