import { Form } from '../../../components';

const BasicInfomation = (props) => {
  function handleFormChange(e) {
    if (props.hasOwnProperty('onChange')) {
      props.onChange(e);
    }
  }

  function advanceStage() {
    if (props.hasOwnProperty('nextStage')) {
      props.nextStage(1);
    }
  }

  function prevStage() {
    if (props.hasOwnProperty('nextStage')) {
      props.nextStage(0);
    }
  }

  return (
    <div className='center'>
      <h1>Development Information</h1>
      <Form
        inputs={[
          {
            name: 'name',
            label: 'Display Name',
            type: 'text',
            validation: { length: 1024 },
          },
          { name: 'description', label: 'Description', type: 'textarea' },
          { name: 'price', label: 'Price (£)', type: 'money' },
        ]}
        onChange={handleFormChange}
      />
      <div className='button-group'>
        <button className='button button-gray' onClick={prevStage}>
          Back
        </button>
        <button className='button button-primary' onClick={advanceStage}>
          Next
        </button>
      </div>
    </div>
  );
};

export default BasicInfomation;
