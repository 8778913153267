import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Heading } from '../../components';
import { addError, addNotification, beablooApi } from '../../utils';
import { setPLayerUIFooterButtons } from '../../store/actions/playerUI';
import { useParams } from 'react-router-dom';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';

const PlayerUI = () => {
  const dispatch = useDispatch();
  const { developmentId } = useParams();

  // get the current state from redux
  const playerUI = useSelector((state) => state.playerUI);

  // page state
  const [isSaving, setIsSaving] = useState(false);

  // create some state to store the data
  const [playerConfig, setPlayerConfig] = useState(playerUI);

  const handleFormChange = (data) => {
    // handle buttons
    if (data.leftButton) {
      setPlayerConfig((state) => {
        return {
          ...state,
          footerButtons: [data.leftButton, state.footerButtons[1]],
        };
      });
    }

    if (data.rightButton) {
      setPlayerConfig((state) => {
        return {
          ...state,
          footerButtons: [state.footerButtons[0], data.rightButton],
        };
      });
    }
  };

  const handleFormSubmit = async () => {
    // tell page we are saving and disable form save
    setIsSaving(true);

    // add a pending toast
    const pendingId = addPending('updating Player UI');

    await beablooApi({
      method: 'PUT',
      route: `/developments/${developmentId}/playerUI/update`,
      payload: playerConfig,
    })
      .then((r) => {
        // remove pending toast
        removePending(pendingId);
        // not saving anymore
        setIsSaving(false);

        if (!r.success) {
          throw new Error('unable to save player UI');
        }
        return r.data;
      })
      .then((r) => dispatch(setPLayerUIFooterButtons(r.footerButtons)))
      .then(() => addNotification('updated player UI'))
      .catch((e) => {
        addError(e.message);
      });
  };

  const footerButtonOptions = [
    { value: 'development-overview', label: 'Development Overview' },
    {
      value: 'promotions-and-sales-methods',
      label: 'Promotions and Sales Methods',
    },
    { value: 'property-option-center', label: 'Property Option Center' },
    { value: 'property-type-overview', label: 'Property Type Overview' },
    { value: 'plots', label: 'Plots' },
    { value: 'local-information', label: 'Local Information' },
    { value: 'transport-links', label: 'Transport Links' },
    { value: 'other-developments', label: 'Other Developments' },
    { value: 'design-advice', label: 'Design Advice' },
    { value: 'contact-us', label: 'Contact Us' },
    { value: 'wishlist', label: 'Wishlist' },
    { value: 'register-interest', label: 'Register Interest' },
  ];

  return (
    <div className='page-padding'>
      <div className='heading-with-button'>
        <Heading label='Configure player ui' />
      </div>

      <Form
        inputs={[
          {
            name: 'leftButton',
            label: 'Left Button',
            type: 'select',
            options: footerButtonOptions,
            initialValue: footerButtonOptions.find(
              (option) => option.value === playerUI.footerButtons[0],
            ).value,
          },
          {
            name: 'rightButton',
            label: 'Right Button',
            type: 'select',
            options: footerButtonOptions,
            initialValue: footerButtonOptions.find(
              (option) => option.value === playerUI.footerButtons[1],
            ).value,
          },
        ]}
        onChange={handleFormChange}
        onSubmit={handleFormSubmit}
        canSubmit={!isSaving}
      />
    </div>
  );
};

export default PlayerUI;
