import { Splide, SplideSlide } from '@splidejs/react-splide';
import PlaceholderImage from '../../../../assets/img/placeholder.png';
import './styles.scss';
// import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { useEffect } from 'react';
import { parseMediaObject } from '../../../../utils';

const Slide = ({ image }) => {
  return (
    <SplideSlide>
      <div
        className='preview-carousel-slide'
        style={{
          backgroundImage: `url(${
            image ? parseMediaObject(image) : PlaceholderImage
          })`,
        }}
      />
    </SplideSlide>
  );
};

const Carousel = ({ images }) => {
  useEffect(() => {}, [images]);

  return (
    <Splide
      options={{
        type: 'loop',
        perPage: 1,
        perMove: 1,
        pagination: false,
        autoplay: true,
      }}
      hasAutoplayProgress
    >
      {images &&
        images.map((image, index) => {
          return <Slide key={`staged-image-${index}`} image={image} />;
        })}
    </Splide>
  );
};

export default Carousel;
