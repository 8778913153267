import { useState } from 'react';
import { Form, Heading, Page } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { addError, addNotification } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { addMultiFloorFloor, setFloorOrder } from '../../store/actions/multiFloor';
import { multiFloorService } from '../../lib/signtouch';

export const EditFloorPage = () => {
  const [saving, setSaving] = useState(false);
  const { developmentId, floorId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const multiFloor = useSelector(state => state.multiFloor);

  const floor = multiFloor.floors.find(floor => floor.id === floorId);
  if (!floor) {
    navigate(`/developments/${developmentId}/multi-floor/configuration`);
    addError('Floor not found');
    return null;
  }

  const handleSubmit = async data => {
    console.log(data)
    const updatedFloor = {
      ...floor,
      ...data
    };

    const updatedFloors = multiFloor.floors.map(floor => {
      if (floor.id === floorId) {
        return updatedFloor;
      }
      return floor;
    });

    const payload = {
      ...multiFloor,
      floors: updatedFloors
    };

    const pendingToast = addPending('Updating your floor...');
    setSaving(true);

    try {
      await multiFloorService.updateMultiFloor(payload);
      dispatch(setFloorOrder(updatedFloors))
  
      addNotification('Floor was updated successfully')
      navigate(`/developments/${developmentId}/multi-floor/configuration`);
      
    } catch {
      addError('unable to update floor');
    }

    setSaving(false);
    removePending(pendingToast);
  };

  return (
    <Page>
      <Heading label='create floor' />
      <Form 
        onSubmit={handleSubmit}
        canSubmit={!saving}
        inputs={[
          {
            name: 'name',
            type: 'text',
            label: 'name',
            initialValue: floor.name,
          },
          {
            name: 'image',
            label: 'Image',
            type: 'single-image',
            initialValue: floor.image,
          },
          {
            name: 'backgroundColor',
            label: 'Background Color',
            type: 'colour',
            initialValue: floor.backgroundColor,
          }
        ]}
      />
    </Page>
  )
};