// handles the state of the player UI
// currently only supports footerButtons
// but it built to add more

import defaultState from '../schemas/_playerUI';

const playerUIReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_PLAYER_UI':
      return {
        ...action.data,
      };

    case 'SET_PLAYER_UI_FOOTER_BUTTONS':
      return {
        ...state,
        footerButtons: action.data,
      };

    case 'SET_PLAYER_UI_ERROR':
      return {
        isError: true,
        ...state,
      };

    default:
      return {
        ...state,
      };
  }
};

export default playerUIReducer;
