import { GalleryEditor } from '../../components';
import { addError } from '../../utils';
import { addNotification } from '../../utils';

const CreateGallery = () => {
  return (
    <div className='page-padding'>
      <button
        onClick={(e) =>
          Math.random() > 0.5
            ? addError('A random error message would appear here...')
            : addNotification('A  random notification here... ')
        }
      >
        Button
      </button>
      <GalleryEditor />
    </div>
  );
};

export default CreateGallery;
