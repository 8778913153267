import { Card, Heading } from '../../components';
import { useSelector } from 'react-redux';
import './styles.scss';

import { ReactComponent as HomeIcon } from '../../assets/img/homeIcon.svg';
import { ReactComponent as PlotIcon } from '../../assets/img/plotIcon.svg';
import { ReactComponent as OptionsIcon } from '../../assets/img/icon-options-centre.svg';
import { ReactComponent as MapIcon } from '../../assets/img/map-icon.svg';
import { parseMediaObject } from '../../utils';
import { Link } from 'react-router-dom';

const DevelopmentOverview = (props) => {
  const currentDevelopment = useSelector(
    (state) => state.general.currentDevelopment,
  );
  const { branding } = useSelector((state) => state.properties);
  const houseTypes = useSelector((state) => state.houseTypes);
  const plots = useSelector((state) => state.plots);
  const options = useSelector((state) => state.options);

  const PlotBreakdown = () => {
    return (
      <div className='breakdown-content'>
        <div className='breakdown-section'>
          <div className='breakdown-section-label'>
            <p>Available</p>
          </div>
          <div className='breakdown-section-content'>
            <p>
              {
                plots.filter(
                  (plot) => plot.status.toLowerCase() === 'available',
                ).length
              }
            </p>
          </div>
        </div>
        <div className='breakdown-section'>
          <div className='breakdown-section-label'>
            <p>Reserved</p>
          </div>
          <div className='breakdown-section-content'>
            <p>
              {
                plots.filter((plot) => plot.status.toLowerCase() === 'reserved')
                  .length
              }
            </p>
          </div>
        </div>
        <div className='breakdown-section'>
          <div className='breakdown-section-label'>
            <p>Future Release</p>
          </div>
          <div className='breakdown-section-content'>
            <p>
              {
                plots.filter((plot) => plot.status.toLowerCase() === 'future')
                  .length
              }
            </p>
          </div>
        </div>
        <div className='breakdown-section'>
          <div className='breakdown-section-label'>
            <p>Sold</p>
          </div>
          <div className='breakdown-section-content'>
            <p>
              {
                plots.filter((plot) => plot.status.toLowerCase() === 'sold')
                  .length
              }
            </p>
          </div>
        </div>
      </div>
    );
  };

  const MapAllocation = () => {
    return (
      <div className='breakdown-content'>
        <div className='breakdown-section'>
          <div className='breakdown-section-label'>
            <p>Allocated</p>
          </div>
          <div className='breakdown-section-content'>
            <p>{plots.filter((plot) => plot.coordinates).length}</p>
          </div>
        </div>
        <div className='breakdown-section'>
          <div className='breakdown-section-label'>
            <p>Not Allocated</p>
          </div>
          <div className='breakdown-section-content'>
            <p>{plots.filter((plot) => !plot.coordinates).length}</p>
          </div>
        </div>
      </div>
    );
  };

  const makeWordType = () => {
    if (currentDevelopment.type === 'signtouch-parent') return 'organisation';
    if (currentDevelopment.type === 'signtouch-region') return 'region';
    if (currentDevelopment.type === 'signtouch-site') return 'development';
    return '';
  };

  return (
    <div className='overview'>
      {/* <hr/>
	    <p>Testing</p>
	    <Link to={`/developments/${currentDevelopment.id}/pages/welcome`} >Welcome</Link>
	    <Link to={`/developments/${currentDevelopment.id}/pages/home`} >Home</Link>
	    <Link to={`/developments/${currentDevelopment.id}/pages/contact`} >Contact</Link>
	    <Link to={`/developments/${currentDevelopment.id}/pages/register-interest`} >Register Interest</Link>
	    <Link to={`/developments/${currentDevelopment.id}/metadata`} >Metadata</Link>
	    <hr/> */}

      <div className='overview-heading'>
        <Heading label={`${currentDevelopment.name} overview`} />
      </div>

      <div className='quantities'>
        {currentDevelopment.type !== 'signtouch-site' && (
          <div className={'card-container'}>
            <div className={'card logo-container'}>
              <img src={branding ? parseMediaObject(branding.logo) : ''} />
            </div>
          </div>
        )}
        <Link to={`/developments/${currentDevelopment.id}/houseTypes`}>
          <Card
            heading={'House Types'}
            quantity={houseTypes.length}
            description={`currenly active in ${makeWordType()}`}
            icon={<HomeIcon fill='#121212' />}
          />
        </Link>
        {currentDevelopment.type === 'signtouch-site' && (
          <Card
            heading={'Options'}
            quantity={options.length}
            description={`currenly active in ${makeWordType()}`}
            icon={<OptionsIcon fill='#121212' />}
          />
        )}
        {currentDevelopment.type !== 'signtouch-site' && (
          <Link to={`/developments/${currentDevelopment.id}/optionsOverview`}>
            <Card
              heading={'Options'}
              quantity={options.length}
              description={`currenly active in ${makeWordType()}`}
              icon={<OptionsIcon fill='#121212' />}
            />
          </Link>
        )}
        {currentDevelopment.type === 'signtouch-site' && (
          <Link to={`/developments/${currentDevelopment.id}/plots`}>
            <Card
              heading={'Plots'}
              quantity={plots.length}
              description={`currenly active in ${makeWordType()}`}
              icon={<PlotIcon fill='#121212' />}
            />
          </Link>
        )}
      </div>

      {currentDevelopment.type === 'signtouch-site' && (
        <div className='breakdowns'>
          <Link to={`/developments/${currentDevelopment.id}/map-editor`}>
            <Card
              heading={'Map Allocation'}
              body={<MapAllocation />}
              icon={<MapIcon fill={'#121212'} />}
            />
          </Link>
          <Link to={`/developments/${currentDevelopment.id}/plots`}>
            <Card
              heading={'Plots Breakdown'}
              body={<PlotBreakdown />}
              icon={<PlotIcon fill='#121212' />}
            />
          </Link>
        </div>
      )}
    </div>
  );
};

export default DevelopmentOverview;
