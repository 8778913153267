import { ApiError, beablooApi, findDevelopmentId } from '../../utils';

const setOptionCategories = () => {
  return async (dispatch, getState) => {
    const currentDevelopment = getState().general.currentDevelopment;
    const { developments } = getState();

    // used to inject the ID of the development
    // that a house Type belongs to so we can filter later
    const injectId = (data, id) => {
      return {
        ...data,
        belongsTo: id,
      };
    };

    const requestRequiredOptionCategories = (
      currentDevelopment,
      developments,
    ) => {
      return new Promise(async (resolve, reject) => {
        try {
          let siteCategories = [];
          let regionCategories = [];
          let parentCategories = [];

          // find only parent houses
          if (currentDevelopment.type === 'signtouch-parent') {
            parentCategories = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.id}/optionCategories`,
            })
              .then((r) => r.data)
              .then((r) => r.map((r) => injectId(r, currentDevelopment.id)))
              .catch((e) => {
                throw new ApiError(
                  'failed to get parent option categories',
                  [],
                );
              });
          }

          // find parent && region houses
          if (currentDevelopment.type === 'signtouch-region') {
            parentCategories = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.parentId}/optionCategories`,
            })
              .then((r) => r.data)
              .then((r) =>
                r.map((r) => injectId(r, currentDevelopment.parentId)),
              )
              .catch((e) => {
                throw new ApiError(
                  'failed to get parent option categories',
                  [],
                );
              });
            regionCategories = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.id}/optionCategories`,
            })
              .then((r) => r.data)
              .then((r) => r.map((r) => injectId(r, currentDevelopment.id)))
              .catch((e) => {
                throw new ApiError(
                  'failed to get region option categories',
                  [],
                );
              });
          }

          // find parent && region && site houses
          if (currentDevelopment.type === 'signtouch-site') {
            // find the site's region so we can use it's parent Id to get the last
            // of the options available to us
            const regionDevelopment = developments.find(
              (development) => development.id === currentDevelopment.parentId,
            );
            parentCategories = await beablooApi({
              method: 'GET',
              route: `/developments/${regionDevelopment.parentId}/optionCategories`,
            })
              .then((r) => r.data)
              .then((r) =>
                r.map((r) => injectId(r, regionDevelopment.parentId)),
              )
              .catch((e) => {
                throw new ApiError(
                  'failed to get parent option categories',
                  [],
                );
              });
            regionCategories = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.parentId}/optionCategories`,
            })
              .then((r) => r.data)
              .then((r) =>
                r.map((r) => injectId(r, currentDevelopment.parentId)),
              )
              .catch((e) => {
                throw new ApiError(
                  'failed to get region option categories',
                  [],
                );
              });
            siteCategories = await beablooApi({
              method: 'GET',
              route: `/developments/${currentDevelopment.id}/optionCategories`,
            })
              .then((r) => r.data)
              .then((r) => r.map((r) => injectId(r, currentDevelopment.id)))
              .catch((e) => {
                throw new ApiError('failed to get site option categories', []);
              });
          }

          resolve([
            ...siteCategories,
            ...regionCategories,
            ...parentCategories,
          ]);
        } catch (e) {
          reject(e);
        }
      });
    };

    dispatch({
      type: 'SET_OPTION_CATEGORIES',
      data: await requestRequiredOptionCategories(
        currentDevelopment,
        developments,
      ),
    });
  };
};

const addOptionCategory = (data) => {
  return async (dispatch, getState) => {
    const { optionCategories } = getState();
    const { currentDevelopment } = getState().general;
    const repeated = optionCategories.find((option) => option.id === data.id);
    if (repeated) return;

    dispatch({
      type: 'SET_OPTION_CATEGORIES',
      data: [
        { ...data, belongsTo: currentDevelopment.id },
        ...optionCategories,
      ],
    });
  };
};

const updateOptionCategory = (data) => {
  return async (dispatch, getState) => {
    let { optionCategories } = getState();

    let option = optionCategories.find(
      (option) => String(option.id) === String(data.id),
    );
    const index = optionCategories.indexOf(option);

    data.belongsTo = parseInt(data.belongsTo);

    optionCategories[index] = data;

    dispatch({
      type: 'SET_OPTION_CATEGORIES',
      data: optionCategories,
    });
  };
};

const deleteOptionCategory = (data) => {
  return async (dispatch, getState) => {
    let { optionCategories } = getState();

    let category = optionCategories.find((plot) => plot.id === data.id);
    const index = optionCategories.indexOf(category);
    optionCategories.splice(index, 1);

    dispatch({
      type: 'SET_OPTION_CATEGORIES',
      data: optionCategories,
    });
  };
};

export {
  setOptionCategories,
  addOptionCategory,
  updateOptionCategory,
  deleteOptionCategory,
};
