import './styles.scss';
import { motion } from 'framer-motion';
import SigntouchLogo from '../../assets/signtouch-logo.svg';

const Preloader = ({}) => {
  const loadingVariants = {
    animate: {
      left: ['-2rem', '4rem', '18rem'],
      width: ['2rem', '6rem', '2rem'],
    },
  };

  return (
    <div className='preloader'>
      <img src={SigntouchLogo} alt='Signtouch Logo' />
      <div className='loading-bar'>
        <motion.div
          className='loadingChunk'
          variants={loadingVariants}
          animate='animate'
          transition={{ duration: 1.5, repeat: Infinity }}
        />
      </div>
    </div>
  );
};

export default Preloader;
