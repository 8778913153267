import { Select as MuiSelect, MenuItem, Box } from '@mui/material';
import { useController } from 'react-hook-form';
import { Label } from '../Label';
import { HelperText } from '../HelperText';

export const Select = ({ name, label, control, defaultValue, full, options = [] }) => {
  const { field, fieldState: { error } } = useController({ name, control, defaultValue });

  return (
    <Box 
      sx={{ 
        width: full ? '100%' : 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}
    >
      <Box>
        <Label>{ label }</Label>

        <MuiSelect
          {...field}
          id={`${label?.replace(/ /g, '-')}-select`}
          name={name}
          sx={error
            ? { width: full ? '100%' : 'auto', maxWidth: '100%', border: '#D82C0D 1px solid', borderRadius: '4px', bgcolor: '#FFF4F4' }
            : { width: full ? '100%' : 'auto', maxWidth: '100%' }
          }
        >
          {options.map(option => (
            <MenuItem key={option.value}
              value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </MuiSelect>
      </Box>

      {error && (
        <HelperText message={error?.message} />
      )}
    </Box>

  // <Box 
  //   sx={{ 
  //     width: full ? '100%' : 'auto',
  //     display: 'flex',
  //     flexDirection: 'column',
  //     gap: 1
  //   }}
  //   >
  //   <Box>
  //     <Label>{ label }</Label>
  //     <TextField 
  //       {...field}
  //       sx={error
  //         ? { width: full ? '100%' : 'auto', border: '#D82C0D 1px solid', borderRadius: '4px', bgcolor: '#FFF4F4' }
  //         : { width: full ? '100%' : 'auto' }
  //       }
  //     />
  //   </Box>
  //   {error && (
  //     <HelperText message={error?.message} />
  //   )}
  //   </Box>
  );
};
