import { Form, Heading } from '../../components';
import { useParams, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { beablooApi } from '../../utils';
import { useState } from 'react';
import { addOptionCategory } from '../../store/actions/optionCategories';
import { addError } from '../../utils';
import { addNotification } from '../../utils';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';

const CreateOptionCategory = () => {
  const { typeId, developmentId } = useParams();
  const [error, setError] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [saving, setSaving] = useState(false);

  // const socket = useSocket();
  const dispatch = useDispatch();

  const handleSubmit = async (data) => {
    setSaving(true);
    let pendingId = addPending('Saving...');

    const payload = {
      name: data.name,
      optionType: typeId,
      description: data.description,
      thumbnail: data.thumbnail,
      images: [data.images],
    };

    await beablooApi({
      method: 'POST',
      route: `/developments/${developmentId}/optionCategories/create`,
      payload: payload,
    }).then((r) => {
      removePending(pendingId);
      if (!r.success) {
        setSaving(false);
        setError(r.errors);
        addError(r.message);
      } else {
        // plot saved
        setSaving(false); // stop loading animation
        dispatch(addOptionCategory(r.data)); // add the plot to state
        // socket.emit('optionCategory:create', { room: developmentId, payload: r.data });  // emit the the new plot to anyone looking at currentDevelopment.id
        addNotification('Option Category has been successfully created.');
        setRedirect(true);
      }
    });
  };

  if (redirect)
    return <Navigate to={`/developments/${developmentId}/optionsOverview`} />;

  return (
    <div className='create-option page-padding'>
      <Heading label={`create an a new option category`} />

      <Form
        errors={error}
        canSubmit={!saving}
        inputs={[
          {
            name: 'name',
            type: 'text',
            label: 'name',
            placeholder: 'Category Name',
          },
          { name: 'thumbnail', type: 'single-image', label: 'thumbnail' },
        ]}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default CreateOptionCategory;
