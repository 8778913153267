import initialState from '../schemas/_properties';

const propertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PROPERTIES':
      return {
        ...action.data,
      };

    default:
      return {
        ...state,
      };
  }
};

export default propertiesReducer;
