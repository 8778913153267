import schema from '../schemas/_localInformation';

const localInformationReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_LOCAL':
      return {
        content: action.data,
      };

    default:
      return {
        ...state,
      };
  }
};

export { localInformationReducer };
