import store from '../../store';
import { beablooApi } from '../../utils';

const updateHomePage = async payload => {
  const { general: { currentDevelopment } } = store.getState();

  const response = await beablooApi({
    method: 'PUT',
    route: `/developments/${currentDevelopment.id}/pages/home/update`,
    payload,
  });

  if (!response.success) {
    throw new Error('unable to update home page')
  }

  return response.data;
};

const staticPageService = {
  updateHomePage,
};

export default staticPageService;
