import { ReactComponent as DevelopmentOverviewIcon } from '../icons/menu/icon-development-overview.svg';
import { ReactComponent as IndividualPropertyTypesIcon } from '../icons/menu/icon-individual-property-type-overview.svg';
import { ReactComponent as LocalInfomationIcon } from '../icons/menu/icon-local-information.svg';
import { ReactComponent as TransportLinksIcon } from '../icons/menu/icon-transportation-links.svg';
import { ReactComponent as OtherHousingDevelopmentsIcon } from '../icons/menu/icon-other-housing-developments.svg';
import { ReactComponent as PromotionsAndSalesMethodsIcon } from '../icons/menu/icon-promotions-sales-methods.svg';
import { ReactComponent as DesignAdviceIcon } from '../icons/menu/icon-interior-design-advice.svg';
import { ReactComponent as ContactUsIcon } from '../icons/menu/icon-contact-us.svg';
import { ReactComponent as RegisterInterestIcon } from '../icons/menu/icon-register-interest.svg';
import { ReactComponent as WishlistIcon } from '../icons/menu/icon-bookmark.svg';
import { ReactComponent as PropertyOptionCentreIcon } from '../icons/menu/icon-options-centre.svg';
import { ReactComponent as PlotsIcon } from '../icons/menu/icon-plots.svg';

// take in a all the modules and
// sort them into an array so that
// we can filter them and sort by weight
const handleMenuItems = (modules) => {
  let modulesToFilter = [];
  for (let key in modules) {
    const obj = modules[key];

    switch (key) {
      case 'developmentOverview':
        if (obj.customIcon) {
          obj.icon = <img src={obj.customIcon.url} alt={`${obj.title} icon`} />;
        }
        obj.icon = <DevelopmentOverviewIcon />;
        break;
      case 'propertyOptionCenter':
        if (obj.customIcon) {
          obj.icon = <img src={obj.customIcon.url} alt={`${obj.title} icon`} />;
        }
        obj.icon = <PropertyOptionCentreIcon />;
        break;
      case 'individualPropertyTypeOverview':
        if (obj.customIcon) {
          obj.icon = <img src={obj.customIcon.url} alt={`${obj.title} icon`} />;
        }
        obj.icon = <IndividualPropertyTypesIcon />;
        break;
      case 'plots':
        if (obj.customIcon) {
          obj.icon = <img src={obj.customIcon.url} alt={`${obj.title} icon`} />;
        }
        obj.icon = <PlotsIcon />;
        break;
      case 'localInformation':
        if (obj.customIcon) {
          obj.icon = <img src={obj.customIcon.url} alt={`${obj.title} icon`} />;
        }
        obj.icon = <LocalInfomationIcon />;
        break;
      case 'transportationLinks':
        if (obj.customIcon) {
          obj.icon = <img src={obj.customIcon.url} alt={`${obj.title} icon`} />;
        }
        obj.icon = <TransportLinksIcon />;
        break;
      case 'otherHousingDevelopments':
        if (obj.customIcon) {
          obj.icon = <img src={obj.customIcon.url} alt={`${obj.title} icon`} />;
        }
        obj.icon = <OtherHousingDevelopmentsIcon />;
        break;
      case 'promotionsAndSalesMethods':
        if (obj.customIcon) {
          obj.icon = <img src={obj.customIcon.url} alt={`${obj.title} icon`} />;
        }
        obj.icon = <PromotionsAndSalesMethodsIcon />;
        break;
      case 'designAdvice':
        if (obj.customIcon) {
          obj.icon = <img src={obj.customIcon.url} alt={`${obj.title} icon`} />;
        }
        obj.icon = <DesignAdviceIcon />;
        break;
      case 'registerInterest':
        if (obj.customIcon) {
          obj.icon = <img src={obj.customIcon.url} alt={`${obj.title} icon`} />;
        }
        obj.icon = <RegisterInterestIcon />;
        break;
      case 'contactUs':
        if (obj.customIcon) {
          obj.icon = <img src={obj.customIcon.url} alt={`${obj.title} icon`} />;
        }
        obj.icon = <ContactUsIcon />;
        break;
      case 'wishList':
        if (obj.customIcon) {
          obj.icon = <img src={obj.customIcon.url} alt={`${obj.title} icon`} />;
        }
        obj.icon = <WishlistIcon />;
        break;
    }

    modulesToFilter.push(obj);
  }

  return modulesToFilter;
};

export default handleMenuItems;
