import { Form } from '../../../components';

const BasicInfomation = (props) => {
  function advanceStage() {
    if (props.hasOwnProperty('nextStage')) {
      props.nextStage(1);
    }
  }

  function prevStage() {
    if (props.hasOwnProperty('nextStage')) {
      props.nextStage(0);
    }
  }

  let mapOptions = [
    { value: 'beabloo', label: 'Beabloo' },
    { value: 'eyesite', label: 'Eyesite' },
  ];

  return (
    <div className='center justify-top'>
      <h1>Development Map</h1>
      <div className='form-center small-button'>
        <Form
          inputs={[
            {
              name: 'map-type',
              label: 'Map Type',
              type: 'select',
              options: mapOptions,
            },
          ]}
        />
        <Form
          inputs={[
            { name: 'map-image', label: 'Map Image', type: 'single-image' },
          ]}
        />
        <p>Some more configuration here...</p>
        <div className='button-group'>
          <button className='button button-gray' onClick={prevStage}>
            Back
          </button>
          <button className='button button-primary' onClick={advanceStage}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default BasicInfomation;
