import store from '../store';
import { addToast } from '../store/actions/toasts';

const addError = (message) => {
  store.dispatch(
    addToast({
      id: Math.random().toString(16),
      type: 'notification',
      message: message,
    }),
  );
};

export default addError;
