import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Heading, TreeItem } from '../../../components';
import { DeleteModal } from '../../../components';

const RegionView = () => {
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    id: null,
    type: null,
  });

  const handleDeleteOptionCategory = (id) => {
    setDeleteModal({
      show: true,
      id: id,
      type: 'option category',
    });
  };

  const handleDeleteOptionType = (id) => {
    setDeleteModal({
      show: true,
      id: id,
      type: 'option type',
    });
  };

  const handleDeleteOption = (id) => {
    setDeleteModal({
      show: true,
      id: id,
      type: 'option',
    });
  };

  const handleResetDeleteModal = () => {
    setDeleteModal({
      show: false,
      id: null,
      type: null,
    });
  };

  // get the current loaded development info
  const { currentDevelopment } = useSelector((state) => state.general);
  // pull out only the option categories that belong to this development
  const optionCategories = useSelector(
    (state) => state.optionCategories,
  ).filter((option) => option.belongsTo === currentDevelopment.id);

  const optionTypes = useSelector((state) => state.optionTypes).filter(
    (option) => option.belongsTo === currentDevelopment.id,
  );

  const options = useSelector((state) => state.options).filter(
    (option) => option.belongsTo === currentDevelopment.id,
  );

  // grab parent options
  const parentOptionCategories = useSelector(
    (state) => state.optionCategories,
  ).filter((option) => option.belongsTo === currentDevelopment.parentId);

  const parentOptionTypes = useSelector((state) => state.optionTypes).filter(
    (option) => option.belongsTo === currentDevelopment.parentId,
  );

  const parentOptions = useSelector((state) => state.options).filter(
    (option) => option.belongsTo === currentDevelopment.parentId,
  );

  return (
    <>
      {deleteModal.show && (
        <DeleteModal
          key={`delete-${deleteModal.id}`}
          type={deleteModal.type}
          objectName={deleteModal.type}
          options={{ id: deleteModal.id }}
          closeModal={handleResetDeleteModal}
        />
      )}

      <div className='options-overview'>
        <Heading label={`created by head office (uneditable)`} />
        <div style={{ opacity: 0.6, marginBottom: '1rem' }}>
          {parentOptionCategories.map((optionCategory) => {
            return (
              <TreeItem title={optionCategory.name} openOnStart>
                {parentOptionTypes
                  .filter(
                    (optionType) => optionType.categoryId == optionCategory.id,
                  )
                  .map((optionType) => {
                    return (
                      <TreeItem title={optionType.name}>
                        {parentOptions
                          .filter(
                            (option) => option.optionType == optionType.id,
                          )
                          .map((option) => {
                            return (
                              <TreeItem
                                {...option}
                                title={option.name}
                                lastChild
                              />
                            );
                          })}
                      </TreeItem>
                    );
                  })}
              </TreeItem>
            );
          })}
        </div>

        <Heading label={`created by ${currentDevelopment.name}`} />

        {optionCategories.map((optionCategory) => {
          return (
            <TreeItem
              title={optionCategory.name}
              editIcon
              editLink={`/developments/${currentDevelopment.id}/optionCategory/${optionCategory.id}`}
              addIcon
              addLink={`/developments/${currentDevelopment.id}/optionCategory/${optionCategory.id}/optionTypes/create`}
              deleteIcon
              deleteEvent={() => handleDeleteOptionCategory(optionCategory.id)}
              openOnStart
            >
              {optionTypes
                .filter(
                  (optionType) => optionType.categoryId == optionCategory.id,
                )
                .map((optionType) => {
                  return (
                    <TreeItem
                      title={optionType.name}
                      addIcon
                      addLink={`/developments/${currentDevelopment.id}/optionTypes/${optionType.id}/create`}
                      editIcon
                      editLink={`/developments/${currentDevelopment.id}/optionType/${optionType.id}`}
                      deleteIcon
                      deleteEvent={() => handleDeleteOptionType(optionType.id)}
                      key={`option-type-${optionType.id}`}
                    >
                      {options
                        .filter((option) => option.optionType == optionType.id)
                        .map((option) => {
                          return (
                            <TreeItem
                              {...option}
                              title={option.name}
                              lastChild
                              editIcon
                              editLink={`/developments/${currentDevelopment.id}/options/${option.id}`}
                              deleteIcon
                              deleteEvent={() => handleDeleteOption(option.id)}
                              key={`option-${option.id}`}
                            />
                          );
                        })}
                    </TreeItem>
                  );
                })}
            </TreeItem>
          );
        })}
      </div>
    </>
  );
};

export default RegionView;
