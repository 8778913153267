import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Saving, Heading } from '../../components';
import { SignTouchPreview } from '../../components';
import { PreviewContactUs } from '../../components/SignTouchPreview/pages';
import { setContactUs } from '../../store/actions/contact';
import { addError, addNotification, beablooApi } from '../../utils';

import OtherDetails from './components/otherDetails';
import './index.scss';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';

const EditContactPage = () => {
  const { developmentId } = useParams();
  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  const { description, contacts } = useSelector((state) => state.contact);

  // A copy of the global state for us to edit, keep both, so we can compare and only send changes.
  const [editedDescription, setDescription] = useState(description);
  const [editedContacts, setContacts] = useState(contacts);

  // const socket = useSocket();
  const dispatch = useDispatch();

  const handleSubmit = async (data) => {
    // Start the animation for the user to tell them we're doing something.
    setSaving(true);

    // Created a payload for us to modify
    const payload = {};

    // Checks the description has changed
    if (editedDescription !== description) {
      payload.description = editedDescription;
    }

    // Checks the current contacts are not the same
    if (contacts !== editedContacts) {
      payload.contacts = editedContacts;
    }

    let pendingId = addPending('Saving...');

    // Make a request to the api with the new data.
    await beablooApi({
      method: 'PUT',
      route: `/developments/${developmentId}/pages/contact/update`,
      payload: payload,
    }).then((r) => {
      // If we don't get a success handle the error.
      if (!r.success) {
        // Stop saving as something went wrong
        setSaving(false);
        // Add an error toast for the user to read.
        addError(r.message);
        // Add the errors into state so we can use them to change the view.
        setErrors(r.errors);
        // return so we stop doing anything else.
        return;
      }

      // Stop the animation as we've updated the contact page and the api has confirmed it.
      setSaving(false);
      // Tell the redux store it needs to re-get the contact page as it's updated.
      dispatch(setContactUs());
      // socket.emit('option:create', { room: developmentId, payload: r.data });  // emit the the new plot to anyone looking at currentDevelopment.id
      // Add a toast to tell the user their changed have been saved.
      removePending(pendingId);
      addNotification('Contact page has been updated.');
    });
  };

  return (
    <div className='create-option page-padding'>
      <div className={'form-preview-container'}>
        <Heading label={`Edit Contact Page`} />

        <Form
          errors={errors}
          onChange={(e) => setDescription(e.mainContent)}
          inputs={[
            {
              name: 'mainContent',
              type: 'textarea',
              label: 'Main Content',
              initialValue: description,
              bgType: 'section',
            },
          ]}
        />
        {/* This component is used for the contacts table, it gives the contacts back as a formatted array. */}
        <OtherDetails onChange={(e) => setContacts(e)} initalValue={contacts} />
        {/* This means the submit button is in the correct place for the page flow. */}
        <Form onSubmit={handleSubmit} canSubmit={!saving} />
      </div>
      <div style={{ position: 'fixed', right: '10vw', top: '10%' }}>
        <SignTouchPreview>
          <PreviewContactUs
            description={editedDescription}
            contacts={editedContacts}
          />
        </SignTouchPreview>
      </div>
    </div>
  );
};

export default EditContactPage;
