import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';

// create a context to hold the state of the menu opening
// and closing, default is closed
const SignTouchPreviewMenuContext = React.createContext();
// create a context to pass an update fucntion to
// toggle the state of SignTouchPreviewMenuContext
const UpdateSignTouchPreviewMenuContext = React.createContext();

// export custom hook to get state of menu
export const usePreviewMenu = () => {
  return useContext(SignTouchPreviewMenuContext);
};

// export custom hook to toggle the state of the menu
export const useTogglePreviewMenu = () => {
  return useContext(UpdateSignTouchPreviewMenuContext);
};

// export a provider to allow access the the state
// within the children of the Provider
export const PreviewMenuProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => setIsOpen(!isOpen);

  return (
    <SignTouchPreviewMenuContext.Provider value={isOpen}>
      <UpdateSignTouchPreviewMenuContext.Provider value={toggleIsOpen}>
        {children}
      </UpdateSignTouchPreviewMenuContext.Provider>
    </SignTouchPreviewMenuContext.Provider>
  );
};
