import schema from '../schemas/_optionTypes';

const optionTypesReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_OPTION_TYPES':
      return [...action.data];

    default:
      return [...state];
  }
};

export default optionTypesReducer;
