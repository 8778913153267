/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import store from '../../store';
import './styles.scss';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { beablooApi } from '../../utils';
import { updatePlot as dispatchAddPlot } from '../../store/actions/plots';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useRef } from 'react';
import { addNotification } from '../../utils';
import { addError } from '../../utils';
import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg';
import { ReactComponent as LocateIcon } from '../../assets/icons/locate.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/create.svg';
import styles from '../../styles/_colors.module.scss';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';

const Plot = ({ id, number, selectDevelopment, makeSelectLocation }) => {
  const handleSelectDevelopment = () => {
    if (!selectDevelopment) {
      return;
    }
    selectDevelopment(id);
  };

  const handleMakeSelectLocation = () => {
    selectDevelopment(id);
    makeSelectLocation();
  };

  return (
    <div className='plot' id={id} onClick={handleSelectDevelopment}>
      <HomeIcon fill='#fff' />
      <h4>Plot {number}</h4>
      <button onClick={handleSelectDevelopment}>
        <EditIcon />
      </button>
      <button onClick={handleMakeSelectLocation}>
        <LocateIcon fill={'#fff'} />
      </button>
    </div>
  );
};

// main export
const DevelopmentMapEditor = (props) => {
  let { currentDevelopment } = useSelector((state) => state.general);
  const { branding } = useSelector((state) => state.properties);
  let { map } = branding;
  const params = useParams();

  useEffect(() => {}, []);

  let mapState = useRef();

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? `${styles.primary} !important`
        : error
        ? 'red'
        : '#959595',
      borderWidth: '2px',
      outline: 'none',
      boxShadow: 'none',
      marginBottom: '.3em',
      fontFamily: 'Montserrat',
      fontWeight: 'normal',
      textTransform: 'none',
      '&:focus': {
        borderColor: styles.primary,
      },
      '&:hover': {
        borderColor: '#959595',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? styles.primary : 'white',
      borderWidth: '2px',
      outline: 'none',
      boxShadow: 'none',
      '&:hover': {
        background: '#cdcdcd',
        color: 'black',
      },
    }),
  };

  let statusOptions = [
    { value: 'available', label: 'Available' },
    { value: 'reserved', label: 'Reserved' },
    { value: 'future', label: 'Future Release' },
    { value: 'sold', label: 'Sold' },
  ];

  const state = store.getState().authentication;
  const developmentId = params.developmentId;
  const dispatch = useDispatch();

  const plots = useSelector((state) => state.plots);
  const houseTypes = useSelector((state) => state.houseTypes);
  const [expandIncomplete, setExandIncomplete] = useState(true);
  const [expandComplete, setExandComplete] = useState(true);

  // Browser Detection!
  // Firefox 1.0+
  const isFirefox = typeof InstallTrigger !== 'undefined';
  // Chrome 1 - 79
  // eslint-disable-next-line
  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  const errorVariants = {
    cover: {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 0.8,
      },
    },
    message: {
      from: {
        translateY: '-1000%',
        translateX: '-50%',
      },
      to: {
        translateY: '-50%',
        translateX: '-50%',
      },
    },
  };

  const tweakVarints = {
    show: {
      translateY: '0%',
      translateX: '-50%',
    },
    hide: {
      translateY: '200%',
      translateX: '-50%',
    },
  };

  const heightVariantsList = {
    hide: {
      height: 0,
      opacity: 0,
    },
    show: {
      height: 'auto',
      opacity: 1,
    },
  };

  const popupVariations = {
    show: {
      translateY: '0%',
    },
    hide: {
      translateY: '-200%',
    },
  };

  let headers = {
    'Content-Type': 'application/json',
  };

  // Map Data
  let color = 'red';
  let isActive = false;
  let [isLoaded, setLoaded] = useState(false);
  let [mapLocation, setMapLocation] = useState('');

  // Application Data
  let [active, setActive] = useState('');
  let [completePlots, setCompletePlots] = useState([]);
  let [incompletePlots, setIncompletePlots] = useState([]);
  let [activePlot, setActivePlotState] = useState({});
  let [isMove, setMove] = useState(false);
  let [isTweak, setTweak] = useState(false);
  let [isAddPlot, setAddPlot] = useState(false);
  let [isNoMap, setNoMap] = useState(false);
  let [canSave, setCanSave] = useState(false);

  let [search, setSearch] = useState('');

  // New Plot data
  let [price, setPrice] = useState('');
  let [houseType, setHouseType] = useState('');
  let [status, setStatus] = useState('');

  // Mvoe
  let isKeyPress = '';

  // Temp Data
  let [error, setError] = useState(false);
  let [isLoading, setLoad] = useState('');

  const getPoints = (e) => {
    // Assuming that firefox's attitude is infact a bug as stated on stack overflow
    if (isFirefox) {
      return [e.layerX, e.layerY];
    }

    return [e.offsetX, e.offsetY];
  };

  // eslint-disable-next-line
  const createMap = () => {
    // Firefox - add scroll wheel support
    if (window.addEventListener) {
      document.addEventListener('DOMMouseScroll', function (e) {
        // detect direction logic
      });
    }

    document.addEventListener('keydown', function (e) {
      switch (e.key) {
        case 'ArrowUp':
          isKeyPress = 'UP';
          moveEventUp();
          break;
        case 'ArrowLeft':
          isKeyPress = 'LEFT';
          moveEventLeft();
          break;
        case 'ArrowDown':
          isKeyPress = 'DOWN';
          moveEventDown();
          break;
        case 'ArrowRight':
          isKeyPress = 'RIGHT';
          moveEventRight();
          break;
        default:
          isKeyPress = '';
          break;
      }
    });

    document.addEventListener('keyup', function (e) {
      isKeyPress = '';
    });

    // Create the map
    window.addEventListener('contextmenu', function (e) {
      if (isActive) {
        e.preventDefault();
      }
    });

    clearPoints();

    plots.forEach((plot) => {
      addPin(plot);
    });
  };

  useEffect(() => {
    searchItems(search);
  }, [search, plots]);

  useEffect(() => {
    // No active plot, skip this function
    if (!activePlot.hasOwnProperty('id')) return;
    // find the correct plot in the state
    let plot = plots.find((plot) => `${plot.id}` === `${activePlot.id}`);
    // complre the redux with the set plot and update it.
    if (plot !== activePlot) {
      // Remove a plot - forces a re-render
      setActivePlot('');
      // Remove all the data - mainly for react-select
      setStatus('');
      setPrice('');
      setHouseType('');
      // Set the plot as the plot from the state if not the same
      setActivePlot(plot);
    }
  }, [activePlot]);

  // Loads map and prevents reloads
  useEffect(() => {
    // checks if the map is loaded
    if (!isLoaded) {
      // Sets the active development as the developmentId - used to tell react when we can load the map.
      setActive(developmentId);
      // Tell react we've loaded all the itital data we need.
      setLoaded(true);
    }
  }, [isLoaded, createMap]);

  useEffect(() => {
    if (mapLocation.length > 0) {
      createMap();
    }
  }, [mapLocation]);

  // Cleans up the labels on the map so only 1 active.
  useEffect(() => {
    removeActiveLevel();
  }, [activePlot]);

  useEffect(() => {
    if (!active.length > 0) return;

    getMetadeta();
  }, [active]);

  useEffect(() => {}, [plots]);

  useEffect(() => {
    if (!activePlot.hasOwnProperty('id')) {
      setCanSave(false);
      return;
    }

    if (`${activePlot.status}` !== `${status}`) {
      setCanSave(true);
      return;
    }

    if (`${activePlot.houseType}` !== `${houseType}`) {
      setCanSave(true);
      return;
    }

    if (
      activePlot.hasOwnProperty('price') &&
      `${activePlot.price.amount}` !== `${price}`
    ) {
      setCanSave(true);
      return;
    }

    setCanSave(false);
  }, [activePlot, status, houseType, price]);

  // Getters for API data. Likely to be removed.
  // TODO: Replace with state.
  const getMetadeta = () => {
    if (store.getState().authentication.loggedin)
      headers['authorization'] = `Bearer ${state.token}`;

    if (
      currentDevelopment &&
      currentDevelopment.hasOwnProperty('mapImage') &&
      currentDevelopment.mapImage.hasOwnProperty('url')
    ) {
      setMapLocation(currentDevelopment.mapImage.url);
    } else {
      setNoMap(true);
    }
  };

  const selectDevelopment = (id) => {
    setActivePlot('');
    setStatus('');
    setPrice('');
    setHouseType('');

    setAddPlot(false);

    if (store.getState().authentication.loggedin)
      headers['authorization'] = `Bearer ${state.token}`;

    let plot = plots.find((plot) => `${plot.id}` === `${id}`);

    setActivePlot(plot);

    if (document.getElementById(`p-${id}`)) {
      document.getElementById(`p-${id}`).classList =
        document.getElementById(`p-${id}`).classList + ' active';
    }
  };

  const savePlot = (plotId) => {
    let pending = addPending('Saving...');

    let currentVersion = activePlot;
    let modified = {};

    if (currentVersion.price.amount !== price) {
      modified.price = {
        currency: 'GBP',
      };
      modified.price.amount = parseInt(price);
    }

    if (currentVersion.houseType !== houseType) {
      modified.houseType = houseType;
    }

    if (currentVersion.status !== status) {
      modified.status = status;
    }

    if (store.getState().authentication.loggedin)
      headers['authorization'] = `Bearer ${state.token}`;

    axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_API_URL}/developments/${active}/plots/${plotId}/update`,
      data: modified,
      headers: headers,
    })
      .then((r) => {
        dispatch(dispatchAddPlot(r.data.data));
        setLoad('');
        removePending(pending);

        if (document.getElementById(`p-${r.data.data.id}`)) {
          document.getElementById(`p-${r.data.data.id}`).remove();
        }

        addPin(r.data.data);

        setActivePlot(r.data.data);

        let id = r.data.data.id;

        if (document.getElementById(`p-${id}`)) {
          document.getElementById(`p-${id}`).classList =
            document.getElementById(`p-${id}`).classList + ' active';
        }

        addNotification(`Plot has been updated`);
      })
      .catch((error) => {
        // ... somthing when wrong, handle it here
        addError(error.response.data.message);
        setCanSave(false);
        setLoad('');
      });
  };

  const searchItems = (term) => {
    let completePlots = plots.filter(
      (plot) =>
        plot.hasOwnProperty('coordinates') && plot.coordinates !== false,
    );
    let incompletePlots = plots.filter(
      (plot) =>
        !plot.hasOwnProperty('coordinates') || plot.coordinates === false,
    );

    if (term.length > 0) {
      completePlots = completePlots.filter((plot) =>
        String(plot.number).includes(search),
      );
      incompletePlots = incompletePlots.filter((plot) =>
        String(plot.number).includes(search),
      );
    }

    setIncompletePlots(incompletePlots);
    setCompletePlots(completePlots);
  };

  // Functions use to manage the
  // pins on the map
  const clearPoints = () => {
    let currentChildren = document.getElementsByClassName('point');

    // Loops through to delete existing points. This is only commented because it's a dumb fix.
    while (currentChildren.length > 0) {
      // Removes the item from the DOM and therefore the array for some reason?
      currentChildren[0].remove();
    }
  };

  const addPin = (e) => {
    if (!e.coordinates) return;

    let newMapPin = document.createElement('p');

    newMapPin.style.left = e.coordinates[0] + 'px';
    newMapPin.style.top = e.coordinates[1] + 'px';
    newMapPin.id = String('p-' + e.id);
    newMapPin.className = 'point ' + color + ' ' + e.status;
    newMapPin.style.position = 'absolute';

    newMapPin.addEventListener('click', function (e) {
      // Do Somnething here on point click
      removeActiveLevel();
      selectDevelopment(e.target.id.substr(2, e.target.id.length));
    });

    if (
      document.getElementsByClassName('mapContainer__inner') &&
      document.getElementsByClassName('mapContainer__inner').item(0)
    ) {
      document
        .getElementsByClassName('mapContainer__inner')
        .item(0)
        .append(newMapPin);
    }
  };

  const makeSelectLocation = () => {
    if (activePlot.hasOwnProperty('id')) {
      if (document.getElementById(`p-${activePlot.id}`) !== null) {
        document.getElementById(`p-${activePlot.id}`).remove();
      }
    }

    if (document.getElementById('p-temp') !== null) {
      document.getElementById('p-temp').remove();
    }

    setTweak(false);
    setMove(true);
    isAddPlot = true;

    let currentSelect = document
      .getElementsByClassName('mapContainer__inner')
      .item(0);

    currentSelect.classList.add('addPin');

    let newMapPin = document.createElement('p');
    // Creates a map  pin and positions it, removed half the element size so it's correctly positioned.
    newMapPin.style.left = '0px';
    newMapPin.style.top = '0px';
    newMapPin.id = String('p-temp');
    newMapPin.className =
      'point no-click quick ' +
      color +
      (activePlot && activePlot.status ? activePlot.status : '');
    newMapPin.style.position = 'absolute';
    // newMapPin.innerText = "Plot " + activePlot.number;

    // Add the new pin to the DOM
    currentSelect.append(newMapPin);

    currentSelect.addEventListener('mousemove', (e) => {
      if (isAddPlot) {
        let pos = getPoints(e);

        if (!pos) return;

        newMapPin.style.left = pos[0] - 8 + 'px';
        newMapPin.style.top = pos[1] - 20 + 'px';
      }
    });

    // Add a listener to find when a person clicks on map
    currentSelect.addEventListener('pointerup', function (e) {
      if (isAddPlot && currentSelect.classList.contains('addPin')) {
        setMove(false);
        setTweak(true);

        isAddPlot = false;
      }
    });
  };

  const setActivePlot = (id) => {
    if (!id || id.length < 1) {
      setPrice('');
      setStatus('');
      setHouseType('');
      setActivePlotState({});
      return;
    }

    // Get plot here
    let plot = id;

    setPrice(plot.price && plot.price.amount ? plot.price.amount : '');
    setStatus(plot.status ? plot.status : 'available');
    setHouseType(plot.houseType ? plot.houseType : '');

    setActivePlotState(plot);
  };

  const removeActiveLevel = () => {
    let items = document.getElementsByClassName('active')
      ? document.getElementsByClassName('active')
      : [];

    for (let i = 0; i < items.length; i++) {
      let el = items[0];

      if (el.classList.contains('active') && el.id !== `p-${activePlot.id}`) {
        el.classList.remove('active');
      }
    }
  };

  const backButtonHandler = () => {
    if (document.getElementById(`p-${activePlot.id}`) === null) {
      addPin(activePlot);
    }

    setActivePlot({});
    setCanSave(false);
    isAddPlot = false;
    setTweak(false);
    setMove(false);

    let currentSelect = document
      .getElementsByClassName('mapContainer__inner')
      .item(0);

    currentSelect.classList.remove('addPin');

    if (document.getElementById('p-temp') !== null) {
      document.getElementById('p-temp').remove();
    }
  };

  const movePoint = (direction) => {
    if (document.getElementById('p-temp') !== null) {
      let mapPin = document.getElementById('p-temp');

      switch (direction) {
        case 'UP':
          mapPin.style.top =
            parseInt(mapPin.style.top.substr(0, mapPin.style.top.length - 2)) -
            1 +
            'px';
          break;
        case 'DOWN':
          mapPin.style.top =
            parseInt(mapPin.style.top.substr(0, mapPin.style.top.length - 2)) +
            1 +
            'px';
          break;
        case 'LEFT':
          mapPin.style.left =
            parseInt(
              mapPin.style.left.substr(0, mapPin.style.left.length - 2),
            ) -
            1 +
            'px';
          break;
        case 'RIGHT':
          mapPin.style.left =
            parseInt(
              mapPin.style.left.substr(0, mapPin.style.left.length - 2),
            ) +
            1 +
            'px';
          break;
        default:
          return;
      }
    }
  };

  const moveEventUp = () => {
    if (isKeyPress === 'UP') {
      movePoint('UP');
    }

    if (isKeyPress) {
      setTimeout(() => {
        moveEventUp();
      }, 500);
    }
  };

  const moveEventDown = () => {
    if (isKeyPress === 'DOWN') {
      movePoint('DOWN');
    }

    if (isKeyPress) {
      setTimeout(() => {
        moveEventDown();
      }, 500);
    }
  };

  const moveEventLeft = () => {
    if (isKeyPress === 'LEFT') {
      movePoint('LEFT');
    }

    if (isKeyPress) {
      setTimeout(() => {
        moveEventLeft();
      }, 500);
    }
  };

  const makeSetCoords = () => {
    setLoad('');
    setTweak(false);
    let pendingId = addPending('Saving...');

    if (document.getElementById('p-temp') !== null) {
      let mapPin = document.getElementById('p-temp');
      let newCoords = [
        parseInt(mapPin.style.left.substr(0, mapPin.style.left.length)),
        parseInt(mapPin.style.top.substr(0, mapPin.style.top.length)),
      ];

      if (activePlot.hasOwnProperty('id')) {
        beablooApi({
          method: 'PUT',
          route: `/developments/${developmentId}/plots/${activePlot.id}/update`,
          payload: {
            coordinates: newCoords,
          },
        })
          .then((result) => result.data)
          .then((result) => {
            setTweak(false);
            setLoad('');
            addNotification('Plot location has been saved.');
            dispatch(dispatchAddPlot(result));
            setActivePlot(result);
            addPin(result);
            removePending(pendingId);

            let currentSelect = document
              .getElementsByClassName('mapContainer__inner')
              .item(0);

            currentSelect.classList.remove('addPin');

            if (document.getElementById('p-temp') !== null) {
              document.getElementById('p-temp').remove();
            }

            return result;
          });
      }
    }
  };

  const moveEventRight = () => {
    if (isKeyPress === 'RIGHT') {
      movePoint('RIGHT');
    }

    if (isKeyPress) {
      setTimeout(() => {
        moveEventRight();
      }, 500);
    }
  };

  const getDefaultHouseType = () => {
    let house = houseTypes.find(
      (house) => `${house.id}` === `${activePlot.houseType}`,
    );
    return house
      ? {
          label: house.name,
          value: house.id,
        }
      : { label: 'None Selected', value: '' };
  };

  const getDefaultStatus = () => {
    let tempStatus = status
      ? statusOptions.find((items) => `${items.value}` === `${status}`)
      : statusOptions.find(
          (status) => `${status.value}` === `${activePlot.status}`,
        );
    return tempStatus ? tempStatus : { label: 'None Selected', value: '' };
  };

  const loadHouseTypes = (value) => {
    return new Promise((resolve) => {
      resolve(
        houseTypes.map((house) => {
          return {
            label: house.name,
            value: house.id,
          };
        }),
      );
    });
  };

  const loadImage = (e) => {
    if (mapState.current) {
      mapState.current.centerView(0.5, 0, 'easeOut');
    }
  };

  // Renders the page
  return (
    <AnimatePresence mode='wait'>
      <style>
        .point.future {`{ background:  ${map.future} !important;}`}
        .key-item.future:before {`{ background:  ${map.future} !important;}`}
        .point.sold {`{ background:  ${map.sold} !important;}`}
        .key-item.sold:before {`{ background:  ${map.sold} !important;}`}
        .point.available {`{ background:  ${map.available} !important;}`}
        .key-item.available:before{' '}
        {`{ background:  ${map.available} !important;}`}
        .point.reserved {`{ background:  ${map.reserved} !important;}`}
        .key-item.reserved:before{' '}
        {`{ background:  ${map.reserved} !important;}`}
      </style>
      <div className='simple-development-map map-grid'>
        <div id='map' className='map-container'>
          <TransformWrapper ref={mapState} initialScale={0.5} minScale={0.5}>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <>
                <TransformComponent>
                  <div
                    className='mapContainer__inner'
                    style={{ position: 'relative' }}
                  >
                    <img
                      onLoad={loadImage}
                      src={mapLocation ? mapLocation : ''}
                      alt={'Development Map'}
                    />
                  </div>
                </TransformComponent>
                <div className='key'>
                  <div className='key-item'>
                    <span>Available</span>
                  </div>
                  <div className='key-item sold'>
                    <span>Sold</span>
                  </div>
                  <div className='key-item reserved'>
                    <span>Reserved</span>
                  </div>
                  <div className='key-item future'>
                    <span>Future Release</span>
                  </div>
                </div>
              </>
            )}
          </TransformWrapper>
          <div className='key'>
            <div className='key-item available'>
              <span>Available</span>
            </div>
            <div className='key-item sold'>
              <span>Sold</span>
            </div>
            <div className='key-item reserved'>
              <span>Reserved</span>
            </div>
            <div className='key-item future'>
              <span>Future Release</span>
            </div>
          </div>
          {isNoMap ? (
            <>
              <motion.div
                animate={isNoMap ? 'to' : 'from'}
                initial={'from'}
                variants={errorVariants.cover}
                className='error-cover'
              />
              <motion.div
                animate={isNoMap ? 'to' : 'from'}
                initial={'from'}
                variants={errorVariants.message}
                className='error-popup'
              >
                <p>Please set a map image to use the development map editor.</p>
              </motion.div>
            </>
          ) : null}
          <div className='popup-container'>
            <motion.div
              animate={isMove ? 'show' : 'hide'}
              variants={popupVariations}
              initial={'hide'}
              className='move-popup'
            >
              <p>Click anywhere on the development map to set the plot.</p>
            </motion.div>
          </div>
          <motion.div
            animate={isTweak ? 'show' : 'hide'}
            variants={tweakVarints}
            initial={'hide'}
            className='tweak-popup'
          >
            <div className='split'>
              {isLoading === 'plot-location' ? (
                <div className='plot-saving'>
                  <div className='loading-container'>
                    <div className='sk-chase white'>
                      <div className='sk-chase-dot' />
                      <div className='sk-chase-dot' />
                      <div className='sk-chase-dot' />
                      <div className='sk-chase-dot' />
                      <div className='sk-chase-dot' />
                      <div className='sk-chase-dot' />
                    </div>
                  </div>
                  {<p>Saving...</p>}
                </div>
              ) : (
                <>
                  <p>Do you want to save this plot location?</p>
                  <button onClick={(e) => makeSetCoords()}>Yes</button>
                  <button onClick={(e) => makeSelectLocation()}>
                    No, Try Again
                  </button>
                </>
              )}
            </div>
            {isLoading !== 'plot-location' ? (
              <div className='tweaker'>
                <p>
                  Use the arrows or your arrow keys to fine-tune the plots
                  location.
                </p>
                <div className='tweaker-parent'>
                  <button onClick={(e) => movePoint('UP')}>
                    <svg viewBox='0 0 494.148 494.148'>
                      <path d='M405.284 201.188 130.804 13.28C118.128 4.596 105.356 0 94.74 0 74.216 0 61.52 16.472 61.52 44.044v406.124c0 27.54 12.68 43.98 33.156 43.98 10.632 0 23.2-4.6 35.904-13.308l274.608-187.904c17.66-12.104 27.44-28.392 27.44-45.884.004-17.48-9.664-33.764-27.344-45.864z' />
                    </svg>
                  </button>
                  <button onClick={(e) => movePoint('DOWN')}>
                    <svg viewBox='0 0 494.148 494.148'>
                      <path d='M405.284 201.188 130.804 13.28C118.128 4.596 105.356 0 94.74 0 74.216 0 61.52 16.472 61.52 44.044v406.124c0 27.54 12.68 43.98 33.156 43.98 10.632 0 23.2-4.6 35.904-13.308l274.608-187.904c17.66-12.104 27.44-28.392 27.44-45.884.004-17.48-9.664-33.764-27.344-45.864z' />
                    </svg>
                  </button>
                  <button onClick={(e) => movePoint('LEFT')}>
                    <svg viewBox='0 0 494.148 494.148'>
                      <path d='M405.284 201.188 130.804 13.28C118.128 4.596 105.356 0 94.74 0 74.216 0 61.52 16.472 61.52 44.044v406.124c0 27.54 12.68 43.98 33.156 43.98 10.632 0 23.2-4.6 35.904-13.308l274.608-187.904c17.66-12.104 27.44-28.392 27.44-45.884.004-17.48-9.664-33.764-27.344-45.864z' />
                    </svg>
                  </button>
                  <button onClick={(e) => movePoint('RIGHT')}>
                    <svg viewBox='0 0 494.148 494.148'>
                      <path d='M405.284 201.188 130.804 13.28C118.128 4.596 105.356 0 94.74 0 74.216 0 61.52 16.472 61.52 44.044v406.124c0 27.54 12.68 43.98 33.156 43.98 10.632 0 23.2-4.6 35.904-13.308l274.608-187.904c17.66-12.104 27.44-28.392 27.44-45.884.004-17.48-9.664-33.764-27.344-45.864z' />
                    </svg>
                  </button>
                </div>
              </div>
            ) : null}
          </motion.div>
        </div>
        <div className='controls'>
          {isLoading === 'save' ? (
            <>
              <div className='loading-cover' />
              <div className='loading-container'>
                <div className='sk-chase white'>
                  <div className='sk-chase-dot' />
                  <div className='sk-chase-dot' />
                  <div className='sk-chase-dot' />
                  <div className='sk-chase-dot' />
                  <div className='sk-chase-dot' />
                  <div className='sk-chase-dot' />
                </div>
                <h2>Saving....</h2>
              </div>
            </>
          ) : null}
          {activePlot.hasOwnProperty('id') ? null : (
            <>
              <div className='header'>
                <h1>
                  Plots <span className='plots-total'>({plots.length})</span>
                </h1>
                <Link
                  class={'button'}
                  to={`/developments/${developmentId}/plots/create`}
                >
                  Create Plot
                </Link>
              </div>
              <div className='filter'>
                <input
                  type='text'
                  placeholder={'Search'}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </>
          )}
          {activePlot.hasOwnProperty('id') ? (
            <div className={'plot-info'}>
              <h1>Update Plot {activePlot.number}</h1>
              <h5>House Type</h5>
              <AsyncSelect
                defaultOptions
                cacheOptions
                loadOptions={loadHouseTypes}
                defaultValue={getDefaultHouseType()}
                onChange={(e) => {
                  setHouseType(`${e.value}`);
                }}
                styles={selectStyles}
              />
              <h5>Price </h5>
              <div className='price-input'>
                <input
                  type={'text'}
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <h5>Status</h5>
              <Select
                onChange={(e) => {
                  setStatus(e.value);
                }}
                options={statusOptions}
                value={getDefaultStatus()}
                styles={selectStyles}
              />
              <div className='location-divider'>
                <h5>Location</h5>
                <button
                  className={
                    !activePlot.coordinates ? 'button' : 'button button-gray'
                  }
                  onClick={(e) => makeSelectLocation()}
                >
                  {activePlot.coordinates
                    ? 'Move Plot on Map'
                    : 'Add Plot to Map'}
                </button>
                {!activePlot.coordinates ? (
                  <div className={'alert'}>
                    <p>This plot does not have a location set.</p>
                  </div>
                ) : null}
              </div>
              <div className='button-group'>
                <button className='button' onClick={(e) => backButtonHandler()}>
                  Back
                </button>
                <button
                  className={
                    canSave ? 'button button-active' : 'button button-gray'
                  }
                  onClick={(e) => savePlot(activePlot.id)}
                >
                  Save
                </button>
              </div>
              {/*<button className='button red' onClick={e => deletePlot(activePlot.id)}>Delete</button>*/}
            </div>
          ) : null}
          {!activePlot.hasOwnProperty('id') && (
            <div id='plots-controller'>
              {incompletePlots.length > 0 && (
                <span
                  onClick={(e) => setExandIncomplete(!expandIncomplete)}
                  className='plot-header'
                >
                  <span>Incomplete</span>
                  <span className='plots-total'>
                    ({incompletePlots.length})
                  </span>
                  <span
                    className={expandIncomplete ? 'icon' : 'icon collapse'}
                  />
                </span>
              )}

              <motion.div
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                className={'plots-list-collapse'}
                animate={expandIncomplete ? 'show' : 'hide'}
                variants={heightVariantsList}
              >
                {incompletePlots
                  .sort((a, b) => a.number - b.number)
                  .map((plot) => {
                    return (
                      <Plot
                        key={plot.id}
                        {...plot}
                        selectDevelopment={selectDevelopment}
                        makeSelectLocation={makeSelectLocation}
                      />
                    );
                  })}
              </motion.div>

              {completePlots.length > 0 && (
                <span
                  onClick={(e) => setExandComplete(!expandComplete)}
                  className='plot-header'
                >
                  <span>Complete</span>
                  <span className='plots-total'>({completePlots.length})</span>
                  <span className={expandComplete ? 'icon' : 'icon collapse'} />
                </span>
              )}

              <motion.div
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                className={'plots-list-collapse'}
                animate={expandComplete ? 'show' : 'hide'}
                variants={heightVariantsList}
              >
                {completePlots
                  .sort((a, b) => a.number - b.number)
                  .map((plot) => {
                    return (
                      <Plot
                        key={plot.id}
                        {...plot}
                        selectDevelopment={selectDevelopment}
                        makeSelectLocation={makeSelectLocation}
                      />
                    );
                  })}
              </motion.div>
            </div>
          )}
        </div>
      </div>
    </AnimatePresence>
  );
};

export default DevelopmentMapEditor;
