import { useEffect, useState } from 'react';
import { beablooApi } from '../../utils';
import { Login as ApiLogin } from '../../store/actions/authentication';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import './styles.scss';
import Notification from '../../components/Notification';
import { addError } from '../../utils';

const Login = (props) => {
  const authentication = useSelector((state) => state.authentication);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [apiError, setApiError] = useState([]);
  const [isError, setError] = useState(false);

  const dispatch = useDispatch();

  const handleInput = (e) => {
    const { id, value } = e.target;

    switch (id) {
      case 'username':
        setUsername(value);
        break;

      case 'password':
        setPassword(value);
        break;

      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      username: username,
      password: password,
    };

    const r = await beablooApi({
      method: 'POST',
      route: '/auth/login',
      payload: payload,
    });

    if (!r.success) {
      addError(r.message ? r.message : 'An unknown error occurred');
    }

    if (r && r.hasOwnProperty('data')) {
      dispatch(ApiLogin(r.data));
    }
  };

  const removeError = () => {
    let newErrors = apiError;

    newErrors.splice(0, 1);

    setError(false);
    setApiError(newErrors);
  };

  useEffect(() => {
    if (apiError.length > 0) setError(true);
  }, [apiError]);

  if (authentication.loggedin) return <Navigate to='/developments' />;
  
  return (
    <>
      {isError
        ? apiError.map((item, index) => {
            return (
              <Notification
                message={item.message}
                removeElement={(e) => removeError()}
                key={`error-${index}`}
              />
            );
          })
        : null}
      <div className='login'>
        <div className='form-container'>
          <div className='form-background'>
            <h1>Welcome to Signtouch CMS</h1>
            <form onSubmit={handleSubmit}>
              <label>
                Username:
                <input
                  id='username'
                  type='text'
                  placeholder='username'
                  onChange={handleInput}
                />
              </label>

              <label>
                Password:
                <input
                  id='password'
                  type='password'
                  placeholder='•••••••••••••'
                  onChange={handleInput}
                />
              </label>

              <input type='submit' value='Enter' />
            </form>
          </div>
          <p>
            Powered by <a href='https://beabloo.com'>Beabloo</a> |{' '}
            <a href='mailto:digitalsignage@scan.co.uk'>Contact Support</a>{' '}
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
