import { ApiError, beablooApi } from '../../utils';

export const setMultiFloor = data => ({
  type: 'SET_MULTI_FLOOR',
  data,
});

export const setFloorOrder = data => ({
  type: 'SET_MULTI_FLOOR_ORDER',
  data,
});

export const addMultiFloorFloor = data => ({
  type: 'ADD_MULTI_FLOOR_FLOOR',
  data,
})


export const requestMultiFloor = () => {
  // TODO: update these methods work without thunks and consume a service as this is too messy

  return async (dispatch, getState) => {
    const { general } = getState();

    const response = await beablooApi({
      method: 'GET',
      route: `/developments/${general.currentDevelopment.id}/multiFloor`,
    });

    if (!response.success) {
      throw new ApiError('failed to get floors');
    }

    dispatch({
      type: 'SET_MULTI_FLOOR',
      data: response.data,
    });
  }
};
