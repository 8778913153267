import './styles.scss';
import { ReactComponent as Arrow } from '../../../../assets/img/arrow.svg';
import { usePreviewProperties } from '../../contexts/brandingContext';

const PreviewMenuItem = ({ title, icon }) => {
  const { branding } = usePreviewProperties();

  return (
    <div className='preview-menu-item'>
      {icon}
      <p>{title}</p>
      <div
        className='signtouch-arrow'
        style={{ backgroundColor: branding.tertiary }}
      >
        <Arrow stroke={'#fff'} />
      </div>
    </div>
  );
};

export default PreviewMenuItem;
