import * as yup from 'yup';

export const plotSchema = yup.object({
  number: yup.string().required('number is required'),
  price: yup.object({
    amount: yup.number().required('price is required'),
    currency: yup.string().required('currency is required'),
  }).required('price is required'),
  houseType: yup.string().required('house type is required'),
  status: yup.string().required('status is required'),
});
