import { ReactComponent as CrossIcon } from '../../../../assets/img/cross.svg';
import { ReactComponent as DragIcon } from '../../../../assets/img/drag.svg';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const SortableImage = ({ url, id, onDelete, onClick }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleDelete = () => {
    if (onDelete) onDelete(id);
  };
  const handleClick = () => {
    if (onClick) onClick(id);
  };
  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <div className={'gallery-image-container'}>
        <div className={'gallery-image'} onClick={handleClick}>
          <div className='gallery-image-content'>
            {/* <img src={url} alt='pending image upload' onDragStart={e => e.preventDefault()} /> */}
            <div
              className='gallery-image-content-clip'
              style={{ backgroundImage: `url(${url})` }}
            />
          </div>
          <div className='gallery-image-controls'>
            <div className='delete-image' onClick={handleDelete}>
              <CrossIcon />
            </div>
            <div className='gallery-image-drag' {...listeners}>
              <DragIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SortableImage;
