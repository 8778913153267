import schema from '../schemas/_plots';

const plotsReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_PLOTS':
      // expects and array
      return [...action.data];

    case 'ADD_PLOT':
      return [...state, action.data];

    case 'CLEAR_PLOTS':
      return [];

    default:
      return [...state];
  }
};

export default plotsReducer;
