import { ColorPicker, toColor, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';

import { useEffect, useState } from 'react';

import './style.scss';
import { useRef } from 'react';
import ColorPickerInput from '../ColorPicker';
import { DropDown } from '../index';
import NumberInput from '../NumberInput';
import AngleSelector from './angleSelector';

const ColorAndGradient = (props) => {
  const [color, setColor] = useColor('hex', '#333333');
  const [expand, setExpand] = useState(false);
  const [active, setActive] = useState(false);

  const [color1, setColor1] = useColor('hex', '#333333');
  const [color2, setColor2] = useColor('hex', '#333333');
  const [angle, setAngle] = useState(90);

  const [init, setInit] = useState(false);

  // Use to track the type of colour
  const [type, setType] = useState({});

  const options = [
    { value: 'color', label: 'Single Colour' },
    { value: 'gradient', label: 'Gradient' },
  ];

  useEffect(() => {
    if (
      props.initialValue &&
      props.initialValue.hasOwnProperty('type') &&
      props.initialValue.type === 'gradient'
    ) {
      setColor1(
        toColor(
          'hex',
          props.initialValue.hasOwnProperty('value') &&
            props.initialValue.value.hasOwnProperty('colors') &&
            props.initialValue.value.colors.length === 2
            ? props.initialValue.value.colors[0]
            : '#ffffff',
        ),
      );
      setColor2(
        toColor(
          'hex',
          props.initialValue.hasOwnProperty('value') &&
            props.initialValue.value.hasOwnProperty('colors') &&
            props.initialValue.value.colors.length === 2
            ? props.initialValue.value.colors[1]
            : '#ffffff',
        ),
      );
      setAngle(
        props.initialValue.hasOwnProperty('value') &&
          props.initialValue.value.hasOwnProperty('angle')
          ? props.initialValue.value.angle
          : 90,
      );
      setType('gradient');
      setInit(true);
    } else if (
      props.initialValue &&
      props.initialValue.hasOwnProperty('type') &&
      props.initialValue.type === 'color'
    ) {
      setColor1(
        toColor(
          'hex',
          props.initialValue.hasOwnProperty('value')
            ? props.initialValue.value
            : '#ffffff',
        ),
      );
      setType('color');
      setInit(true);
    }
  }, []);

  useEffect(() => {
    if (props.openedModal !== props.name) {
      setExpand(false);
    }
  }, [props.openedModal]);

  useEffect(() => {
    let payload = {
      type: ['gradient', 'color'].indexOf(type) > -1 ? type : 'color',
      value:
        type === 'gradient'
          ? {
              angle: parseInt(angle),
              colors: [
                color1.hasOwnProperty('hex') ? color1.hex : color1,
                color2.hasOwnProperty('hex') ? color2.hex : color2,
              ],
            }
          : color1.hex,
    };

    if (props.hasOwnProperty('onChange')) {
      props.onChange({ name: props.name, value: payload });
    }
  }, [color1, color2, type, angle]);

  return (
    <>
      {init ? (
        <div className='form-input-select'>
          <label>{props.label}</label>
          <DropDown
            options={options}
            onChange={(e) => setType(e.value)}
            initialValue={type}
          />
          <div className='side-by-side'>
            <div className='left'>
              <ColorPickerInput
                {...props}
                initialValue={color1.hex}
                openedModal={props.openedModal}
                notifyOpen={props.notifyOpen}
                name={`${props.name}-1`}
                onChange={(e) => setColor1(e.value)}
                hideLabel
              />
              {type === 'gradient' && (
                <ColorPickerInput
                  {...props}
                  initialValue={color2.hex}
                  openedModal={props.openedModal}
                  notifyOpen={props.notifyOpen}
                  name={`${props.name}-2`}
                  onChange={(e) => setColor2(e.value)}
                  hideLabel
                />
              )}
            </div>
            <div className='right'>
              {type === 'gradient' && (
                <AngleSelector onChange={setAngle} initialValue={angle} />
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ColorAndGradient;
