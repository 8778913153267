import { beablooApi } from '../../utils';

const setDevelopments = () => {
  return async (dispatch, getState) => {
    let developments = await beablooApi({
      method: 'GET',
      route: '/developments',
    })
      .then((r) => r.data)
      .then((r) =>
        r.filter(
          (development) =>
            development.type === 'signtouch-site' ||
            development.type === 'signtouch-parent' ||
            development.type === 'signtouch-region',
        ),
      );

    dispatch({
      type: 'SET_DEVELOPMENTS',
      data: developments,
    });
  };
};

export { setDevelopments };
