const promotionsAndSalesMethodsReducer = (state = [], action) => {
  switch (action.type) {
    case 'SET_PROMOTIONS':
      return [...action.data];

    case 'ADD_PROMOTION':
      return [...state, action.data];

    case 'UPDATE_PROMOTION':
      return [
        ...state.filter((promo) => promo.id != action.data.id),
        action.data,
      ];

    case 'DELETE_PROMOTION':
      return [...state.filter((promo) => promo.id != action.data.id)];

    default:
      return [...state];
  }
};

export { promotionsAndSalesMethodsReducer };
