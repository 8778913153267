import schema from '../schemas/_contact';

const contactReducer = (state = schema, action) => {
  switch (action.type) {
    case 'SET_CONTACT':
      return {
        ...action.data,
      };

    default:
      return {
        ...state,
      };
  }
};

export { contactReducer };
