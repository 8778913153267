import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Heading, TreeItem } from '../../components';
import './styles.scss';
import DeleteModal from '../../components/DeleteModal';
import { ParentView, RegionView } from './views';

const OptionsOverview = () => {
  const { currentDevelopment } = useSelector((state) => state.general);
  const [sortedOptions, setSortedOptions] = useState([]);
  const { developmentId } = useParams();
  const [deleteModal, setDeleteModal] = useState();

  const optionCategories = useSelector((state) => state.optionCategories);
  const optionTypes = useSelector((state) => state.optionTypes);
  const options = useSelector((state) => state.options);

  const sortOptions = () => {
    return optionCategories.map((optionCatergory) => {
      let applicableOptionTypes = optionTypes.filter(
        (type) => `${type.categoryId}` == `${optionCatergory.id}`,
      );
      applicableOptionTypes = applicableOptionTypes.map((optionType) => {
        return {
          ...optionType,
          options: options.filter(
            (option) => `${option.optionType}` == `${optionType.id}`,
          ),
        };
      });
      return {
        ...optionCatergory,
        optionTypes: applicableOptionTypes,
      };
    });
  };

  useEffect(
    () => setSortedOptions(sortOptions()),
    [optionCategories, optionTypes, options],
  ); // any changes to options should refresh

  return (
    <div className='page-padding'>
      {deleteModal ? (
        <DeleteModal
          type={'options'}
          {...deleteModal}
          closeModal={(e) => setDeleteModal()}
          options={deleteModal}
        />
      ) : null}

      <div className='heading-with-button'>
        <Heading label='options overview' />
        <Link
          to={`/developments/${currentDevelopment.id}/optionCategory/create`}
        >
          <Button label={'create option category'} />
        </Link>
      </div>

      {currentDevelopment.type === 'signtouch-parent' && <ParentView />}

      {currentDevelopment.type === 'signtouch-region' && <RegionView />}

      {/* <div className='options-overview'>
        { sortedOptions.map(optionCategory => {

          return (
            <div className='tree'>
              <TreeItem 
                title={optionCategory.name}
                editIcon
                editLink={`/developments/${currentDevelopment.id}/optionCategory/${optionCategory.id}`}
                addIcon
                addLink={`/developments/${currentDevelopment.id}/optionCategory/${optionCategory.id}/optionTypes/create`}
                deleteIcon
                deleteEvent={e => setDeleteModal({id: optionCategory.id, type: 'option category', customText: `Are you sure you want to delete ${optionCategory.name}?`})}
                openOnStart
              >
                { 
                  optionCategory.optionTypes.map((optionType, index) => {

                    return (
                      <TreeItem 
                        title={optionType.name}
                        addIcon
                        addLink={`/developments/${currentDevelopment.id}/optionTypes/${optionType.id}/create`}
                        editIcon
                        editLink={`/developments/${currentDevelopment.id}/optionType/${optionType.id}`}
                        deleteIcon
                        deleteEvent={e => setDeleteModal({id: optionType.id, type: 'option category', customText: `Are you sure you want to delete ${optionType.name}?`})}
                        key={`option-type-${optionType.id}`}
                      >
                        {
                          optionType.options.map((option, index) => {

                            return (
                              <TreeItem 
                                {...option} 
                                title={option.name} 
                                lastChild 
                                editIcon 
                                editLink={`/developments/${developmentId}/options/${option.id}`}
                                deleteIcon 
                                deleteEvent={e => setDeleteModal({id: option.id})}
                                key={`option-${option.id}`}
                              />
                            )
                          })
                        }
                      </TreeItem>
                    )
                  }) 
                }
              </TreeItem>
            </div>
          )
        }) }
      </div> */}
    </div>
  );
};

export default OptionsOverview;
