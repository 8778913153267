import './styles.scss';
import { useSelector } from 'react-redux';
import { Preloader } from '../';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { ReactComponent as SigntouchLogo } from '../../assets/signtouch-logo.svg';

const Loading = (props) => {
  const { developmentId } = useParams();
  const general = useSelector((state) => state.general);
  const developments = useSelector((state) => state.developments);
  const development = developments.find(
    (development) => `${development.id}` === developmentId,
  );
  const [showLoadingName, setShowLoadingName] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoadingName(false);
    }, 3000);
  }, []);

  if (showLoadingName)
    return (
      <div className='now-loading'>
        <SigntouchLogo />
        <p>Now Loading: {development?.name}</p>
        <Link to='/developments'>
          <div className='button'>
            <p>Go Back</p>
          </div>
        </Link>
      </div>
    );

  return (
    <div className='loading'>
      <Preloader />
      <p>Loading {general.currenltyLoading}...</p>
    </div>
  );
};

export default Loading;
