import { SideBar, SideBarItem } from './../../components';
import { ReactComponent as DevelopmentIcon } from '../../assets/img/development-icon.svg';
import { useDispatch } from 'react-redux';
import { Logout } from '../../store/actions/authentication';
import logoutIcon from '../../assets/img/logout.svg';
import './styles.scss';

const DevelopmentLayout = ({ children }) => {
  const dispatch = useDispatch();

  return (
    <div className='main-layout'>
      <SideBar isAlways={true}>
        <SideBarItem
          label='Developments'
          to='/developments'
          icon={<DevelopmentIcon />}
        />
      </SideBar>

      <div className='page'>
        <div className='development-banner'>
          <div className='logout' onClick={() => dispatch(Logout())}>
            <p>logout</p>
            <img src={logoutIcon} alt='logout' />
          </div>
        </div>

        <div className='content'>{children}</div>
      </div>
    </div>
  );
};

export default DevelopmentLayout;
