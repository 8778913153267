import { useDispatch } from 'react-redux';
import logoutIcon from '../../../../assets/img/logout.svg';
import { Logout as logoutAction } from '../../../../store/actions/authentication';
import './styles.scss';

const Logout = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutAction());
  };

  return (
    <div className='logout' onClick={handleLogout}>
      <p>Logout</p>
      <img src={logoutIcon} alt='logout' />
    </div>
  );
};

export default Logout;
