import './styles.scss';
import { useStagedOptions } from '../../contexts/stagedOptions';

const OptionSelectorOption = ({ id, name }) => {
  // pull out functions to add and remove
  // options from state
  const {
    options,
    stageOption,
    unStageOption,
    optionsOverride,
    overrideOptions,
  } = useStagedOptions();

  // handle click on option
  const handleClick = () => {
    // if optionsOverride is on, turn it off
    if (optionsOverride) {
      overrideOptions(false);
    }

    // check if the option is in the options provider
    const inContext = options.indexOf(id) > -1 ? true : false;

    if (inContext) {
      unStageOption(id);
    }

    if (!inContext) {
      stageOption(id);
    }
  };

  const shouldShowDot = () => {
    if (optionsOverride) {
      return true;
    } else if (options.indexOf(id) > -1) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className='option-selector-option' onClick={handleClick}>
      <span
        style={{
          background: shouldShowDot() ? '#27A1FC' : 'none',
        }}
      />
      <p>{name}</p>
    </div>
  );
};

export default OptionSelectorOption;
